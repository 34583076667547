import React from 'react';
import { Helmet } from "react-helmet";
import blog from "../../img/blog.webp";
import BlogPost from './BlogPost';


let Blog = () =>{
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Blog | Webxinfinity IT Solution, Bengaluru</title>
            <meta
            name="description"
            content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
            />
            <meta name="format-detection" content="telephone=no" />
            <meta
            name="author"
            content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
            />
            <meta
            name="keywords"
            content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
            />
            <link rel="canonical" href="http://webxinfinity.com/" />
        </Helmet>


        <section>
            <figure>
                <img src={blog} alt="Blog" />
            </figure>
        </section>

        <BlogPost/>
        </>
    )
}
export default Blog;