import React from 'react';
import cross from "../../../img/Projects/cross.webp";
import cross1 from "../../../img/Projects/cross1.webp";


let Crossweave = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={cross} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            The Crossweave
                        </div>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>The Crossweave</h2>

                        <p className='paraPort'>At THE CROSSWEAVE, we endeavor to solve the biggest problem in The Fashion industry, that is wastage and Fast fashion. We keep no inventory; every shirt is made to order only. We believe in simple, elegant and classic design. Our collection reflects the same, so if you’re looking for the same then you’ve come to the right place!</p>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Why We’re Different ?</h2>
                        <div className="row">
                            <div className="col-lg-7 my-3">
                                <p className='paraPort'>The idea came up when either of us couldn’t find a single formal shirt that would fit properly, a design we liked and quality we wanted at a reasonable price, that’s where solution came from that why don’t we ourselves start making these shirts! And after mulling over it for quite some time and a lot of research we decided to go ahead with the idea and now you’re here today!</p>

                                <p className='paraPort'>We offer customizing options for your formal shirt at absolutely no additional costs. The customizing attributes include everything that makes a shirt “your perfect shirt”. For instance, attributes like – Collar, Sleeves, Cuffs, Yoke, Placket, Pockets, Monogram.</p>
                            </div>
                            <div className="col-lg-5 mt-5">
                                <img src={cross1} style={{ width: "100%" }} alt="" />
                            </div>
                        </div>
                        

                        <p className='paraPort'>And for your sizing worries we have got you covered. We offer multiple ways through which you can ensure that you get a perfect fit!</p>

                        <li className="paraPort">You can choose from our standard sizes i.e., 38, 39, 40, 41, 42, 43, 44!</li>
                        <li className="paraPort">You measure your own shirt that fits you perfectly. We recommend this procedure as it’ll only takes two minutes of your time &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;but ensures you a great fit!</li>
                        <li className="paraPort">You can also measure you own body!</li>
                        <li className="paraPort">You can also mail us your perfect fitting shirt and we’ll return it to you with your new made shirt.</li>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Crossweave;