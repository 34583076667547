import React, { Component } from "react";

import logo from "../../img/logot.png";
import $ from "jquery";
window.jQuery = $;

export default class Header extends Component {
  componentDidMount() {
    class ShapeOverlays {
      constructor(elm) {
        this.elm = elm;
        this.path = elm.querySelectorAll("path");
        this.numPoints = 2;
        this.duration = 100;
        this.delayPointsArray = [];
        this.delayPointsMax = 0;
        this.delayPerPath = 100;
        this.timeStart = Date.now();
        this.isOpened = false;
        this.isAnimating = false;
      }
      toggle() {
        this.isAnimating = true;
        for (var i = 0; i < this.numPoints; i++) {
          this.delayPointsArray[i] = 0;
        }
        if (this.isOpened === false) {
          this.open();
        } else {
          this.close();
        }
      }
      open() {
        this.isOpened = true;
        this.elm.classList.add("is-opened");
        $("body").addClass("overflow-hidden");
        this.timeStart = Date.now();
        this.renderLoop();
      }
      close() {
        this.isOpened = false;
        this.elm.classList.remove("is-opened");
        $("body").removeClass("overflow-hidden");
        this.timeStart = Date.now();
        this.renderLoop();
      }
      updatePath(time) {
        const points = [];
        for (var i = 0; i < this.numPoints; i++) {}

        let str = "";
        str += this.isOpened ? `M 0 0 V ${points[0]} ` : `M 0 ${points[0]} `;
        for (i = 0; i < this.numPoints - 1; i++) {
          const p = ((i + 1) / (this.numPoints - 1)) * 100;
          const cp = p - ((1 / (this.numPoints - 1)) * 100) / 2;
          str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${
            points[i + 1]
          } `;
        }
        str += this.isOpened ? `V 0 H 0` : `V 100 H 0`;
        return str;
      }
      render() {
        if (this.isOpened) {
          for (var i = 0; i < this.path.length; i++) {
            this.path[i].setAttribute(
              "d",
              this.updatePath(
                Date.now() - (this.timeStart + this.delayPerPath * i)
              )
            );
          }
        } else {
          for (i = 0; i < this.path.length; i++) {
            this.path[i].setAttribute(
              "d",
              this.updatePath(
                Date.now() -
                  (this.timeStart +
                    this.delayPerPath * (this.path.length - i - 1))
              )
            );
          }
        }
      }

      renderLoop() {
        this.render();
        if (
          Date.now() - this.timeStart <
          this.duration +
            this.delayPerPath * (this.path.length - 1) +
            this.delayPointsMax
        ) {
          requestAnimationFrame(() => {
            this.renderLoop();
          });
        } else {
          this.isAnimating = false;
        }
      }
    }

    var n = document.querySelector(".hamburger");
    if (n == null) {
    } else {
      (function () {
        const elmHamburger = document.querySelector(".hamburger");
        const gNavItems = document.querySelectorAll(".hamburger-navigation");
        const elmOverlay = document.querySelector(".shape-overlays");
        const overlay = new ShapeOverlays(elmOverlay);

        elmHamburger.addEventListener("click", () => {
          if (overlay.isAnimating) {
            return false;
          }
          overlay.toggle();
          if (overlay.isOpened === true) {
            elmHamburger.classList.add("is-opened-navi");
            for (var i = 0; i < gNavItems.length; i++) {
              gNavItems[i].classList.add("is-opened");
            }
          } else {
            elmHamburger.classList.remove("is-opened-navi");
            for (i = 0; i < gNavItems.length; i++) {
              gNavItems[i].classList.remove("is-opened");
            }
          }
        });
      })();
    }
  }

  render() {
    return (
      <div>
        <main>
          <ul className="hamburger-navigation">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/services">Our Services</a>
              <ul className="dropdown">
                {/* <li>
                  <a href="/branding">BRANDING</a>
                </li> */}
                <li>
                  <a href="/website_development">WEB DEVELOPMENT</a>
                </li>
                <li>
                  <a href="/mobile_app">MOBILE APP</a>
                </li>
                <li>
                  <a href="/digital_marketing">DIGITAL MARKETING</a>
                </li>
                {/* <li>
                  <a href="/advertise">ADVERTISING</a>
                </li> */}
                {/* <li>
                  <a href="/production">CORPORATE VIDEO MAKING</a>
                </li> */}
                <li>
                  <a href="/custom">Custom Development</a>
                </li>
              </ul>
            </li>
            {/* <li>
              <a href="/offer">Offers</a>
              <ul className="dropdown">
                <li>
                  <a href="/web_dev">Website Development</a>
                </li>
                <li>
                  <a href="/mobile_dev">Mobile App Development</a>
                </li>
                <li>
                  <a href="/custom_dev">Custom Development</a>
                </li>
                <li>
                  <a href="/digital_marketing">DIGITAL MARKETING</a>
                </li>
              </ul>
    </li> */}
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/portfolio">Our Work</a>
            </li>
            <li>
              <a href="/about_us">About Us</a>
            </li>
            {/* <li>
              <a href="/career">
                Career<span>HIRING</span>
              </a>{" "}
            </li> */}
            <li>
              <a href="/say_hello">Say Hello</a>{" "}
            </li>
          </ul>

          <svg
            className="shape-overlays"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <path className="shape-overlays__path" d=""></path>
            <path className="shape-overlays__path" d=""></path>
            <path className="shape-overlays__path" d=""></path>
          </svg>

          <header className="header">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>

            <span className="phone">
              <span>Say</span>{" "}
              <a href="mailto:laxmi@webxinfinity.com">laxmi@webxinfinity.com</a>
            </span>
            <div className="hamburger" id="hamburger">
              <div className="hamburger__line hamburger__line--01">
                <div className="hamburger__line-in hamburger__line-in--01"></div>
              </div>
              <div className="hamburger__line hamburger__line--02">
                <div className="hamburger__line-in hamburger__line-in--02"></div>
              </div>
              <div className="hamburger__line hamburger__line--03">
                <div className="hamburger__line-in hamburger__line-in--03"></div>
              </div>
              <div className="hamburger__line hamburger__line--cross01">
                <div className="hamburger__line-in hamburger__line-in--cross01"></div>
              </div>
              <div className="hamburger__line hamburger__line--cross02">
                <div className="hamburger__line-in hamburger__line-in--cross02"></div>
              </div>
            </div>

            {/* <div className="equalizer"> <span></span> <span></span> <span></span> <span></span> </div> */}
          </header>

          <ul className="social-bar">
            <li>
              <a
                href="https://www.facebook.com/Webxinfinityofficial/"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-facebook-f fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/search?q=Webxinfinity"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-google fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC4B1LbPsduzBwm-OpkmmUkQ"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-youtube fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/webxinfinityofficial/"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-instagram fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/webxinfinity/"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fab fa-linkedin-in fa-2x"></i>
              </a>
            </li>
          </ul>
        </main>
      </div>
    );
  }
}