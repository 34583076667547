import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Landing1 from "../../img/Packages/Landingimg.png";
import Support from "../../img/Packages/provide-support.png";
import Customers from "../../img/Packages/retain-customers.png"
import Delivery from "../../img/Packages/delivery-frame.png"
import Dashboard from "../../img/Packages/dashboard-reporting.png"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 5, textAlign: "center" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [valueVertical, setValueVertical] = React.useState(0);

  const handleChangeVertical = (event, newValue) => {
    setValueVertical(newValue);
  };

  return (
    <Box sx={{
        display:{
            xs: "none", md: "block"},
        }
    }>
    <section
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: "50px",
        backgroundColor: "",
      }}
    >
      <Box 
        sx={{
          flexGrow: 1,
          display: "flex",
          height: 600,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "1px #ffffff",
          backgroundColor: "#000000",
          margin: 5,
          borderRadius: "10px",
        }}
      >
          
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={valueVertical}
          onChange={handleChangeVertical}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider", position: "relative" }}
        >
          <Tab
            label="Flexible Delivery Options"
            {...a11yProps(0)}
            sx={{
              fontFamily: "Josefin Sans",
              textTransform: "none", // Preserve case
              fontWeight: "bold",
              fontSize: "16px", // Adjust the font size
              // lineHeight: "48px", // Match the height of your buttons
              minWidth: "unset", // Remove the default minimum width
              padding: "10px 16px", // Adjust padding as needed
              color: "#ffffff",
              border: "1px solid #3361ae",
              borderRadius: "100px",
              marginBottom: 5,

              "&:hover": {
                backgroundColor: "#yourHoverColor", // Add hover color
              },
              "&.Mui-selected": {
                backgroundColor: "#8ed9d5",
                color: "#3361ae",
                borderRadius: "0px",
                border: "2px solid #8ed9d5",
              },
            }}
          />
          <Tab
            label="Amplify Sales with Strategic Promotions"
            {...a11yProps(1)}
            sx={{
              fontFamily: "Josefin Sans",
              textTransform: "none", // Preserve case
              fontWeight: "bold",
              fontSize: "16px", // Adjust the font size
              // lineHeight: "48px", // Match the height of your buttons
              minWidth: "unset", // Remove the default minimum width
              padding: "10px 16px", // Adjust padding as needed
              color: "#ffffff",
              border: "1px solid #3361ae",
              borderRadius: "100px",
              marginBottom: 5,

              "&:hover": {
                backgroundColor: "#yourHoverColor", // Add hover color
              },
              "&.Mui-selected": {
                backgroundColor: "#8ed9d5",
                color: "#3361ae",
                borderRadius: "0px",
                border: "2px solid #8ed9d5",
              },
            }}
          />
          <Tab
            label="Nurture Customer Loyalty"
            {...a11yProps(2)}
            sx={{
              fontFamily: "Josefin Sans",
              textTransform: "none", // Preserve case
              fontWeight: "bold",
              fontSize: "16px", // Adjust the font size
              // lineHeight: "48px", // Match the height of your buttons
              minWidth: "unset", // Remove the default minimum width
              padding: "10px 16px", // Adjust padding as needed
              color: "#ffffff",
              border: "1px solid #3361ae",
              borderRadius: "100px",
              marginBottom: 5,

              "&:hover": {
                backgroundColor: "#yourHoverColor", // Add hover color
              },
              "&.Mui-selected": {
                backgroundColor: "#8ed9d5",
                color: "#3361ae",
                borderRadius: "0px",
                border: "2px solid #8ed9d5",
              },
            }}
          />
          <Tab
            label="Exceptional Support"
            {...a11yProps(3)}
            sx={{
              fontFamily: "Josefin Sans",
              textTransform: "none", // Preserve case
              fontWeight: "bold",
              fontSize: "16px", // Adjust the font size
              // lineHeight: "48px", // Match the height of your buttons
              minWidth: "unset", // Remove the default minimum width
              padding: "10px 16px", // Adjust padding as needed
              color: "#ffffff",
              border: "1px solid #3361ae",
              borderRadius: "100px",
              marginBottom: 5,

              "&:hover": {
                backgroundColor: "#yourHoverColor", // Add hover color
              },
              "&.Mui-selected": {
                backgroundColor: "#8ed9d5",
                color: "#3361ae",
                borderRadius: "0px",
                border: "2px solid #8ed9d5",
              },
            }}
          />
          <Tab
            label="Dashboard & Reporting"
            {...a11yProps(4)}
            sx={{
              fontFamily: "Josefin Sans",
              textTransform: "none", // Preserve case
              fontWeight: "bold",
              fontSize: "16px", // Adjust the font size
              // lineHeight: "48px", // Match the height of your buttons
              minWidth: "unset", // Remove the default minimum width
              padding: "10px 16px", // Adjust padding as needed
              color: "#ffffff",
              border: "1px solid #3361ae",
              borderRadius: "100px",
              marginBottom: 2,

              "&:hover": {
                backgroundColor: "#yourHoverColor", // Add hover color
              },
              "&.Mui-selected": {
                backgroundColor: "#8ed9d5",
                color: "#3361ae",
                borderRadius: "0px",
                border: "2px solid #8ed9d5",
              },
            }}
          />
        </Tabs>
        <TabPanel value={valueVertical} index={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                textAlign: "center",
                paddingTop: "50px",
                fontSize: "38px",
                fontWeight: "bold",
                fontFamily: "Josefin Sans",
              }}
            >
              Provide{" "}
              <span style={{ color: "#3361ae", fontFamily: "Josefin Sans" }}>
                Flexible Delivery<br></br>
              </span>{" "}
              Options <br />
            </h1>
            <p
              style={{
                color: "#FFFFFFB3",
                fontSize: "18px",
                textAlign: "center",
                fontFamily: "Josefin Sans",
              }}
            >
              Let your customers choose their preferred <br></br>delivery options at a
              convenient time and place.
            </p>
          </div>
          <br></br>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Points */}
            <div
              style={{
                marginLeft: "20px", // Adjust as needed for spacing
                maxWidth: "600px", // Adjust as needed for width
              }}
            >
              <br></br>
              <br></br>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Takeaway
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Home Delivery
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Scheduled Delivery
              </p>
              <br></br>
              <br></br>

              {/* Add more points as needed */}
            </div>
            {/* Image */}
            <img src={Delivery} width={500} />
          </div>
        </TabPanel>
        <TabPanel value={valueVertical} index={1}>
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                textAlign: "center",
                paddingTop: "50px",
                fontSize: "38px",
                fontWeight: "bold",
                fontFamily: "Josefin Sans",
              }}
            >
              Boost Sales with {" "}
              <span style={{ color: "#3361ae", fontFamily: "Josefin Sans" }}>
              Promotional <br></br>
              </span>{" "}
              Activities<br />
            </h1>
            <p
              style={{
                color: "#FFFFFFB3",
                fontSize: "18px",
                textAlign: "center",
                fontFamily: "Josefin Sans",
              }}
            >
              Let your customers choose their preferred delivery options at a
              convenient time and place.
            </p>
          </div>
          <br></br>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Points */}
            <div
              style={{
                marginLeft: "20px", // Adjust as needed for spacing
                maxWidth: "600px", // Adjust as needed for width
              }}
            >
              
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Campaigns
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Promotional Banners
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Coupons
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Push Notifications
              </p>
              <br></br>
              

              {/* Add more points as needed */}
            </div>
            {/* Image */}
            <img src={Landing1} width={500} />
          </div>
        </TabPanel>
        <TabPanel value={valueVertical} index={2}>
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                textAlign: "center",
                paddingTop: "50px",
                fontSize: "38px",
                fontWeight: "bold",
                fontFamily: "Josefin Sans",
              }}
            >
              Nurture  {" "}
              <span style={{ color: "#3361ae", fontFamily: "Josefin Sans" }}>
              Long-Term Customer<br></br>
              </span>{" "}
              Retention<br />
            </h1>
            <p
              style={{
                color: "#FFFFFFB3",
                fontSize: "18px",
                textAlign: "center",
                fontFamily: "Josefin Sans",
              }}
            >
              Allow your customers to avail wallet facility through your business. <br></br>
              Sustain customers & encourage future purchases.
            </p>
          </div>
          <br></br>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Points */}
            <div
              style={{
                marginLeft: "20px", // Adjust as needed for spacing
                maxWidth: "600px", // Adjust as needed for width
              }}
            >
              <br></br>
              <br></br>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Loyalty Points
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Referral Bonus
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Refund Request
              </p>
              <br></br>
              <br></br>

              {/* Add more points as needed */}
            </div>
            {/* Image */}
            <img src={Customers} width={550} />
          </div>
        </TabPanel>
        <TabPanel value={valueVertical} index={3}>
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
              fontFamily: "Josefin Sans",
            }}
          >
            Maintain a Real-Time{" "}
            <span style={{ color: "#3361ae", fontFamily: "Josefin Sans" }}>
              & Transparent <br></br> Communication
            </span>{" "}
            <br></br>
          </h1>
          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              fontFamily: "Josefin Sans",
            }}
          >
            Stay connected with your customers through live-chat & emails.
          </p>
          </div>
          <br></br>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Points */}
            <div
              style={{
                marginLeft: "20px", // Adjust as needed for spacing
                maxWidth: "600px", // Adjust as needed for width
              }}
            >
              <br></br>
              <br></br>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- 24/5 Live Chat
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Ticket Support
              </p>
              <br></br>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontFamily: "Josefin Sans",
                }}
              >
                -- Whatsapp Chat Support
              </p>
              <br></br>
              <br></br>

              {/* Add more points as needed */}
            </div>
            {/* Image */}
            <img src={Support} width={550} />
          </div>
        </TabPanel>
        <TabPanel value={valueVertical} index={5}>
          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
              fontFamily: "Josefin Sans",
            }}
          >
            Maintain a Real-Time{" "}
            <span style={{ color: "#3361ae", fontFamily: "Josefin Sans" }}>
              & Transparent <br></br> Communication
            </span>{" "}
            <br></br>
          </h1>
          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              fontFamily: "Josefin Sans",
            }}
          >
            Stay connected with your customers through live-chat & emails.
          </p>
          <br></br>

          <img src={Support} width={600} />
        </TabPanel>
        <TabPanel value={valueVertical} index={4}>
          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
              fontFamily: "Josefin Sans",
            }}
          >
            Manage, Monitor, and Control Your <br></br>
            <span style={{ color: "#3361ae", fontFamily: "Josefin Sans" }}>
              Business
            </span>{" "}
            <br></br>
          </h1>
          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              fontFamily: "Josefin Sans",
            }}
          >
            Let your customers choose their preferred delivery options at a
            convenient time and place.
          </p>
          <br></br>

          <img src={Dashboard} width={700} />
        </TabPanel>
      </Box>
    </section>
    </Box>
  );
}
