import React from 'react';
import mamacell from "../../../img/Projects/mamacell.webp";
import mamaearth from "../../../img/Projects/mamaearth.webp";
import mamadesk from "../../../img/Projects/mamadesk.webp";
import mamadesc from "../../../img/Projects/mamadesc.webp";

let Mamaearth = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={mamaearth} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            mama earth
                        </div>
                        <h5>“EasyEcom has been acting as the backbone of our business. We have complete control over our inventory, forecasting, compliance, and day to day operations. Now I am able to focus on growth activities and deliver results to our investors.”</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'><b>Mamaearth</b>, registered under Honasa Consumer Pvt Ltd, is the first company in Asia that has been certified by Made Safe – a US-based non-profit organisation that provides a comprehensive human health-focussed certification for making non-toxic products.</p>

                        <p className='paraPort'>The brand is an AFMCG and deals in baby-care products including apparel, toys, cosmetics, food, accessories and diapers, skin care products, hair care products, pregnancy care products both for prenatal and postnatal women as well as skin and hair care products for men in India as well as across various countries. Early 2018, Shilpa Shetty (actor and entrepreneur) joined Mamearth as a brand ambassador and an investor which gave the company the required boost to grow. The start-up since its inception has sold over one million products to over 300,000 consumers across 150 cities, clocking gross revenues of Rs 25 crore in 2018/19. It plans to grow four times in 2019/20 to Rs 100 crore.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={mamacell} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={mamadesc} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <h2 className='textHead2'>How Mamaearth is slowly pacing to become a Category Champion:</h2>

                        <p className='paraPort'>Mamaearth is a company by the moms, for the moms, and through the moms. They have over 200 young moms on board who help in conceptualizing, trying, testing, and formulating the products. Only once the products are fully approved, do they go into manufacturing. Mamaearth is sticking to its roots in delivering toxin-free products for mothers and their babies. They are the first company in Asia to have been certified by Made-Safe – a US-based non-profit organisation that provides a comprehensive human health-focused certification for making non-toxic products. The company’s core team is focussed on growing the brand while its manufacturing is via third-party manufacturing unlike bigger players in the market. This dramatically brings down the cost of operations. Mamaearth has already achieved a revenue of Rs 25 crores in FY 2018-19 and aims to clock in a revenue of Rs 100 crores in the FY 2019-20, truly making it an AFMCG (atomically fast moving consumer goods). According to 6Wresearch, India Baby Care Products Market size is projected to grow at a CAGR (compound annual growth rate) of 10.1% during the period 2018-24.</p>

                        <p className="paraPort">Within 3 years since inception, they already have a presence in 150 major cities of the country and at various eCommerce platforms like Amazon, Nykaa, Firstcry, apart from selling through their Magento powered eCommerce website.</p>

                        <br />
                        <h2 className='textHead2'>Critical eCommerce Challenges:</h2>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="paraPort">Mamaearth eCommerce business started with fewer product categories and fetched around 20-30 orders per day. The brand grew rather rapidly in terms of product categories & presence and as a result, the order volume jumped 20x to the range of 500-600 per day within a few months. As the business was scaling, the team at Mamaearth was struggling with huge operational challenges ranging from lack of inventory tracking, order fulfilment delays, wrong items being shipped, high cancellation rates, etc. Just the sheer volume of orders that needed to be packed p and dispatched with precision on the same day had become a challenging activity. Major issues that the team faced are:</p>

                            </div>
                            <div className="col-lg-6 mt-4">
                                <img src={mamadesk} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <li className="paraPort"><b>Lack of inventory tracking and visibility</b>: Keeping track of inventory for various SKUs with defined shelflife across the &nbsp;&nbsp;&nbsp;&nbsp;warehouse is not an easy task. Without a proper system in place, Mamaearth’s team continued to struggle to report accurate &nbsp;&nbsp;&nbsp;&nbsp;inventory levels. While this is a major compliance issue, it also hinders the growth due to not having enough stock in place.</li>

                        <li className="paraPort"><b>Haphazard workflow to manage large volume of orders</b>: High volume requires relatively a bigger team  to manage the &nbsp;&nbsp;&nbsp;&nbsp;operations. Without a proper system in place, managing such high volume is not possible. That’s where Mamaearth team saw &nbsp;&nbsp;&nbsp;&nbsp;the biggest challenge.</li>

                        <li className="paraPort"><b>High order cancellations & shipping costs</b>: eCommerce is synonymous with Amazon now, hence customers expect the same &nbsp;&nbsp;&nbsp;&nbsp;day or max next day delivery at any cost. Any deviation results in a poor perception of the brand. In the case of Mamaearth, &nbsp;&nbsp;&nbsp;&nbsp;the orders were processed from a single warehouse leading to delayed shipments reaching the customer which led to order &nbsp;&nbsp;&nbsp;&nbsp;cancellations. This also led the brand to incur huge shipping costs.</li>

                        <li className="paraPort"><b>Erroneous inventory forecasting</b>: With an unpredictable surge in the order volume for a consumer brand, inventory &nbsp;&nbsp;&nbsp;&nbsp;forecasting is the mainstay to keep issues at bay. Without systematic inventory visibility and planning, the brand was &nbsp;&nbsp;&nbsp;&nbsp;experiencing a lot of out of stock situations and inventory wastage considering the products have a defined shelf life.</li>

                        <li className="paraPort"><b>Unable to track returns and payments</b>: 15-20% returns are common for any eCommerce platform. If you are not able to track &nbsp;&nbsp;&nbsp;&nbsp;returns timely and comprehensively, you might be losing tons in terms of profits. Similarly, without payment tracking and cash &nbsp;&nbsp;&nbsp;&nbsp;on delivery (COD) reconciliation processes in place, profits were taking a further hit.</li>

                        <li className="paraPort"><b>Disconnected B2B operations from eCommerce</b>: With the growing popularity, Mamaearth brand was garnering high demand &nbsp;&nbsp;&nbsp;&nbsp;from other large retailers. In order to manage the operations, the team had to separate the inventory and operations for B2B &nbsp;&nbsp;&nbsp;&nbsp;orders. That translated to additional inventory costs and manpower to handle the B2B business.</li>

                        <li className="paraPort"><b>Laborious manual Invoice creation and record keeping</b>: The brand was dealing with companies like Cloudtail, Medilife, etc on &nbsp;&nbsp;&nbsp;&nbsp;a bulk order basis for which the purchase/sale bills were created manually. It becomes difficult to let your dispatch process &nbsp;&nbsp;&nbsp;&nbsp;depend on manual data entries into any ERP for such a rapidly moving company. Not to mention the daily involvement of a lot &nbsp;&nbsp;&nbsp;&nbsp;of trained personnel in operations leading to errors and fulfilment delays. Low fill ratio and wrong shipments were commonly &nbsp;&nbsp;&nbsp;&nbsp;impacting the business growth.</li>

                        <br />
                        <h2 className='textHead2'>How EasyEcom planned the rescue:</h2>
                        <p className="paraPort">Mamaearth was processing around 600 orders on a daily basis when they zeroed in on EasyEcom as the partner of choice to manage their eCommerce channel. To begin with, EasyEcom suggested the brand to launch regional warehouses for order processing to reduce fulfilment delays and save on shipping costs. Highlights of the plan executed for Mamaearth brand are as follows:</p>

                        <li className="paraPort">Centralized inventory management solution with advanced state of the art WMS platform which has built-in provisions to &nbsp;&nbsp;&nbsp;&nbsp;handle bulk orders and accounts reconciliation tracking. WMS Solution seamlessly integrated with major sales channels such &nbsp;&nbsp;&nbsp;&nbsp;as Magento powered brand website, Amazon, Flipkart, Nykaa, and Firstcry.</li>

                        <li className="paraPort">Order routing & fulfilment from regional warehouses: Key features such as order routing based on inventory availability in &nbsp;&nbsp;&nbsp;&nbsp;regional warehouses and assignment priority for efficient and timely fulfilment was implemented. This solution completely &nbsp;&nbsp;&nbsp;&nbsp;removed the possibility of sales loss due to inventory stock out in one particular warehouse as the orders will automatically get &nbsp;&nbsp;&nbsp;&nbsp;routed to the next warehouse.</li>

                        <li className="paraPort">Bulk order Processing: High volume of orders for the brand required processing the orders in bulk. EasyEcom platform enabled &nbsp;&nbsp;&nbsp;&nbsp;end to end order processing of different marketplaces and brand eCommerce store. Complete invoicing, taxation handling, &nbsp;&nbsp;&nbsp;&nbsp;shipment generation, and order tracking continues being handled from within the system.</li>

                        <li className="paraPort">AI driven Inventory Forecasting: Data science-based Inventory forecasting was deployed for Mamaearth to forecast inventory &nbsp;&nbsp;&nbsp;&nbsp;purchase and stock transfer requirements between warehouses. This not only allowed a close to 100% fill ratio for orders but &nbsp;&nbsp;&nbsp;&nbsp;also reduced cash stuck due to extra inventory procured. That helped in managing high volume sales periods with rather an &nbsp;&nbsp;&nbsp;&nbsp;ease and accuracy in fulfilment. Further, automated systems were deployed to auto-create purchase and transfer orders &nbsp;&nbsp;&nbsp;&nbsp;based on artificial intelligence-driven forecasting algorithms. Admin just needs to approve these orders hence further &nbsp;&nbsp;&nbsp;&nbsp;automating the business processes.</li>

                        <li className="paraPort">Elaborate user roles for information security: Growing organizations like Mamaearth require multiple layers of security and &nbsp;&nbsp;&nbsp;&nbsp;restriction in order to maintain information hygiene and restrict free information flow. EasyEcom was instrumental in helping &nbsp;&nbsp;&nbsp;&nbsp;the brand with enterprise level credentials management for their various key team members.</li>

                        <li className="paraPort">Shipping carrier automation based on priorities and payment modes, shipping carrier management made the fulfilment &nbsp;&nbsp;&nbsp;&nbsp;process a breeze for Mamaearth’s team. This eased the operational challenge of manually assigning tracking numbers from &nbsp;&nbsp;&nbsp;&nbsp;different carriers. Real-time shipment tracking provides customers status updates of their shipments hence driving customer &nbsp;&nbsp;&nbsp;&nbsp;satisfaction and brand loyalty.</li>

                        <li className="paraPort">Integrated B2B price lists and wholesale order management: EasyEcom facilitates price lists management functionality for B2B &nbsp;&nbsp;&nbsp;&nbsp;orders. Tiered pricing, volume-based discounting, and other advanced features provided the much-required platform to grow &nbsp;&nbsp;&nbsp;&nbsp;the B2B business segment for the brand. Ever Since EasyEcom joined hands with Mamaearth, volume in the B2B segment has &nbsp;&nbsp;&nbsp;&nbsp;seen almost 100x growth. Additionally end to end order life cycle management including approval, inventory assignment, pick &nbsp;&nbsp;&nbsp;&nbsp;pack and invoice generation, shipment booking, and tracking were instrumental in driving systematic growth from across the &nbsp;&nbsp;&nbsp;&nbsp;country. Also, in order to generate interest, EasyEcom software provided a sample invoice feature where goods can be &nbsp;&nbsp;&nbsp;&nbsp;dispatched and tracked without an accounting impact.</li>

                        <li className="paraPort">Automated tax engine integrated with Tally ERP provided a much-required compliance related automation for order &nbsp;&nbsp;&nbsp;&nbsp;fulfillment.</li>

                        <br />
                        <h2 className='textHead2'>Final impact on Mamearth:</h2>
                        <p className="paraPort">Post-implementation of our solution, Mamearth achieved.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Mamaearth;