import React from 'react';
import oui from "../../../img/Projects/oui.webp";
import oui1 from "../../../img/Projects/oui-1.webp";
import oui2 from "../../../img/Projects/oui-2.webp";

let Oui = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={oui} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>
        
        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            oui oui
                        </div>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>A brand, A dream of 3 mompreneurs. Viinii, Poonam and Raina’s childhood dream of doing business together came true after having their children. Between choosing a full-time job and giving time to their children, they chose the latter and that’s when they thought of starting a kids apparels brand called ‘Oui Oui’. Raina says, she was asked to give up her work from home job by her boss, as he could not trust or see the work she was doing from home.</p>

                        <p className='paraPort'>A lot of women face this problem once they deliver as some companies still have the old school thought and are not flexible to give liberty to working mothers. She quotes, ‘The advantage of 3 of us being business partners is that we have been friends since childhood and know each other’s temperaments. As we have children, we step into each other’s seats whenever either one of us is occupied’.</p>

                        <p className='paraPort'>‘Started in the year 2018, the idea was to cater through direct, word of mouth and Instagram orders. They got their own children to Photoshoot for them which resulted in friends and families buying clothes from us’, says Poonam. ‘When we got positive feedback from clients we worked harder on our Instagram account and concentrated on increasing organic followers and orders’, says Raina.</p>

                        <p className='paraPort'>‘We would cater to every order even if we were not making money, because it was the happiness we got upon receiving an order and going out to source material, getting them stitched and designing was super fun for us. The idea to dress up little kids itself gave us a lot of happiness’, says Viinii.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-5">
                            <img style={{marginTop:'34px'}} src={oui1} alt="" />
                        </div>
                        <div className="col-lg-7">
                            <p className='paraPort'>Following the success, the brand soon started getting celebrity orders through Instagram; and today they boast names such as Inaya Khemu, Taimur Ali Khan, Aradhaya Bachan, Mehr Bedi Dhupia as their ‘little clients’.</p>

                            <p className='paraPort'>Most exciting collaboration for Oui Oui is with The Label Life where they got a chance to launch the kids line. Shobha De’s grand daughters and Lisa Rays daughters were a part of the launch campaign.</p>
                        </div>
                    </div>

                    <div className="oui">
                        <p className='paraPort'>There is a major demand in the kids segment which was understood by the 3 mompreneurs. With the intention of catering this demand, they thought of growing Oui Oui as a smart casual brand at relatively affordable prices.</p>

                        <p className='paraPort'>At Oui Oui, parents can shop a wide range of collection from peppy prints, to palazzo sets all under one roof. Going forward, the idea is to expand the online business with a few domestic stores mostly via shop in shops.</p>

                        <p className='paraPort'>The successful launch of Oui Oui with Hopscotch at the Bombay Times Fashion Week helped the brand in collaborating with Little Muffet, Firstcry & Myntra.</p>

                        <p className='paraPort'>Moreover, Nykaa Fashion will soon be added to their list of alliances.</p>
                    </div>


                    <div className="row">
                        <div className="col-lg-7">
                            <p className='paraPort'>The USP of two times award winner- Kidsstoppress awards and India Kids Brand awards is comfort and smart clothing the goal is to become one of the most recognised brands in the kids segment; catering to kids between the age of 1-9 years. With this strategy Oui Oui founders aim at getting 10,000 orders per month by 2021.</p>

                            <p className='paraPort'>With the impact of COVID-19, the brand operations were affected however the moment the lockdown was lifted they saw the highest sales ever. As mompreneurs, Oui Oui recognizes how kids have been a great source of joy for parents and it is so beautiful seeing them blossom in front of our eyes; so no matter what the obstacle, Oui Oui teams journey will continue with these little stars outgrow their outfits and this will definitely help in increasing the demand.</p>
                        </div>
                        <div className="col-lg-5">
                            <img style={{marginTop:'34px'}} src={oui2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Oui;