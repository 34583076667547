import React from 'react';
import unilet from "../../../img/Projects/unilet.webp";

let Unilet = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={unilet} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            unilet store
                        </div>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Unilet Store</h2>

                        <p className='paraPort'>Instituted in 2005, Unilet Appliances Pvt Ltd is a multi-brand consumer electronic retail chain in Karnataka. Headquartered in Bangalore, the retail chain is a one-stop shop focusing on lifestyle technology products with a continuous and innovative multi-brand product range. We aim to offer a unique retailing experience to help customers “Live Product Experience”. With over 15 years since its inception, Unilet has established its reputation on precise, dependable and consistent customer experience.</p>

                        <p className='paraPort'>Unilet Appliances Pvt Ltd operates 8 exclusive LG premium brand Shoppes across Bangalore. All stores consist of various lifestyle electronic products that LG has to offer including a wide range of OLEDs, UHDs, LEDs, Washing Machines, Refrigerators, Audio Systems, along with new products like water purifiers and Ceiling fans, making it a one stop shop for all LG products.</p>

                        <p className='paraPort'>LG’s approach towards offering its customers quality products and services, along with exciting offers, is in line with that of Unilet’s, thereby creating a strong partnership.</p>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Vision</h2>

                        <p className='paraPort'>To create a value and become a leader in the consumer durable industry whilst focusing on customer satisfaction by offering a wide variety of products from the best brands.</p>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Mission</h2>

                        <p className='paraPort'>Strive to make UNILET a one stop shop with the best brands through efficient, effective and responsive customer experience that goes beyond satisfaction.</p>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Unilet;