import React from 'react';
import dream11 from "../../../img/Projects/dream11.webp";
import dream from "../../../img/Projects/dream.webp";
import drea from "../../../img/Projects/drea.webp";
import dreaa from "../../../img/Projects/dreaa.webp";

let Dream11 = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={dream} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            dream 11
                        </div>
                        <h5>We work in an integral manner and address projects in the following dimensions.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>The Dream11 Story</h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>Founded in 2012 by two avid sports fans, Dream11 is India’s first single-match fantasy sports platform. It offers fantasy cricket, football, kabaddi and NBA basketball. Dream11 has more than doubled its user base year-over-year since its inception, quickly growing to 30 million+ users and over 90% market share in the fantasy sports market in India.</p>

                        <p className='paraPort'>The platform features online games where players use their knowledge of sports to create a virtual fantasy team of real-life players. They then earn points based on how those players perform in real matches. India currently accounts for nearly 13% of the world’s online mobile gaming population. And with the industry expected to reach $1 billion by 2021, Dream11’s growth potential is limitless.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={dream11} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={drea} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <h2 className='textHead2'>Challenges:</h2>
                        <li className="paraPort">User Activation & Retention</li>
                        <li className="paraPort">Brand Recall</li>
                        <li className="paraPort">Marketing Automation</li>

                        <p className='paraPort'>The two-month Indian Premier League season is a critical period of acquisition and engagement for Dream11. The team must act fast to capitalize on the fever-pitch of excitement among the Indian sports community, expand brand awareness, and drive customer acquisition.</p>

                        <p className='paraPort'>Dream11 then faces the added challenge of keeping those users engaged well beyond the IPL season. Effective mobile marketing is pivotal in expanding their user base, fueling greater app adoption, and ensuring that users experience value on the platform to keep coming back, match after match.</p>

                        <p className='paraPort'>On top of this, the sheer scale at which Dream11 operates is a challenge in itself. As the largest fantasy sports platform in India and the sixth largest in the world, the Dream11 team has to engage its massive 30 million+ user base with timely, personalized messaging campaigns that reflect the fast-paced action of live sports. That means sending millions of messages in a matter of minutes to capture users’ attention and prompt them to launch the app.</p>

                        <br />
                        <h2 className='textHead2'>Solution:</h2>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="paraPort">Dream11 to get a deeper understanding of how users engage with the app. By identifying user trends and analyzing retention rates, they can anticipate churn and run effective engagement campaigns that keep delivering value to users over the long term.</p>

                                <p className="paraPort">Dream11 discovered that users who had participated in the previous match displayed a higher probability of coming back to the platform for the next one. To drive continued engagement, they needed to prompt users to create teams and participate in every match.</p>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <img src={dreaa} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <p className="paraPort">The team performed A/B tests on different notification send times for these  found the highest engagement rates for afternoon campaigns. In these cases, users only had a few hours before the 7 pm match to build their teams, creating a powerful sense of urgency and anticipation. They added their tagline, “Dimaag Se Dhoni,” as the notification sound to make messages stand out and reinforce brand recall.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Dream11;