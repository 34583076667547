import React from "react";
import { Helmet } from "react-helmet";
import about from "../../img/AboutUs.webp";

let About = () => {
  return (
    <>  
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={about} alt="About Us" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="title">
                  <h2>"Why Follow Trends When You Can Set Your Own"</h2>
                </div>

                <hr />
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    At Webxinfinity, we believe in nurturing thoughts and
                    ctualizing them in front of your eyes. We stay by your side
                    and work with you right from the stage of conception until
                    completion. We inspire brands to be better and break free
                    from the norm of traditional marketing and branding
                    strategies. We don’t think out of the box, we toss the box
                    aside and create new norms and trends that work in favour of
                    our clients.
                  </p>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    About Webxinfinity
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      The world today runs on the web, there are infinite
                      possible ways to get things done from the information on
                      the web. As we rightly name ourselves as webxinfinity
                      solutions, we believe its very important for a business to
                      have a digital representation of itself on the web. The
                      internet has changed the way we see life. Every person
                      owns a phone today with a social media profile to put out
                      his/her opinion about everything under the Sun. The last
                      decade has given a lot of people breakthroughs in life
                      through the internet.
                    </p>

                    <p>
                      The Business world is much more than just a mere trade.
                      It’s the brand value you present to the society. It’s that
                      satisfying customer service that your brand provides.
                    </p>

                    <p>
                      We at webxinfinity nurture thoughts, marketing and
                      branding strategies. We inspire brands not only to think
                      out of the box but to toss the box and create new norms
                      and set trends.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Our Mission
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      “Making the web work for your service in infinite possible
                      ways”. <br /> We provide the best digital marketing and
                      branding services which includes SEO, social media, web
                      content strategies and to support independent small
                      businesses growth by diligently executing the entire
                      process with real-time measured results.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Our Vision
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      To make every small commercial service in our society
                      present itself on the digital platform and be awarded with
                      righteous value every service truly deserves.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Our Values
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      Branding, Visibility, Access, Community and Respect.Having
                      these core values makes us give best service to our
                      clients and create a good eco-system of valued service
                      providers to the society.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
