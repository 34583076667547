import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import advertise from "../../img/advertise.gif";
import mobile from "../../img/mobile.gif";
import website from "../../img/website.gif";
import video from "../../img/video.gif";
import branding from "../../img/Branding1.gif";
import digital from "../../img/digital.gif";

let Service = () => {
  return (
    <>
      <Router></Router>
      <section className="servicesWebx">
        <div className="container">
          <div className="title">
            <h6 className="glitch">GROW DIGITAL SERVICES</h6>
          </div>

          <div className="row" id="servicesHome">
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
              id="service1"
            >
              <Link to="/branding">
                <div className="cardServices">
                  <img className="imgServices" src={branding} alt="branding" />
                  <div className="text-center">
                    <span className="primary-text">Branding</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
              id="service1"
            >
              <Link to="/website_development">
                <div className="cardServices">
                  <img className="imgServices" src={website} alt="website" />
                  <div className="text-center">
                    <span className="primary-text">Website Developement</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
              id="service1"
            >
              <Link to="/mobile_app">
                <div className="cardServices">
                  <img className="imgServices" src={mobile} alt="Mobile" />
                  <div className="text-center">
                    <span className="primary-text">Mobile App Development</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
              id="service1"
            >
              <Link to="/digital_marketing">
                <div className="cardServices">
                  <img className="imgServices" src={digital} alt="digital" />
                  <div className="text-center">
                    <span className="primary-text">Digital Marketing</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
              id="service1"
            >
              <Link to="/advertise">
                <div className="cardServices">
                  <img
                    className="imgServices"
                    src={advertise}
                    alt="advertise"
                  />
                  <div className="text-center">
                    <span className="primary-text">Advertising</span>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
              id="service1"
            >
              <Link to="/production">
                <div className="cardServices">
                  <img className="imgServices" src={video} alt="video" />
                  <div className="text-center">
                    <span className="primary-text">
                      Video Marketing
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Service;
