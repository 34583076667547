import React from 'react';
import fennec from "../../../img/Projects/fennec.webp";
import fennec2 from "../../../img/Projects/fennec2.webp";
import fennec1 from "../../../img/Projects/fennec1.webp";
import fennecj from "../../../img/Projects/fennec.webp";

let Fennec = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={fennec2} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            fennec collective
                        </div>
                        <h5>Online Shopping for Men: Buy T-shirts, Polo T-shirts, Round Neck T-shirts & more. Free Shipping & Cash on Delivery Best offers Best Prices.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>About Cilent </h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>With the new era, and with evolving needs arrives the necessity for contemporary brands that are different than their predecessors. Fennec is an improved solution for clothing; we are a premium t-shirt and polo shirts provider that understand the needs of the newer generations. Much like the fennec fox, we have started small with a core team that we stand by, but are making great leaps towards success with existing and upcoming lines of t-shirts and polo shirts for casual and smart-casual wear. Our range of t-shirts is ideal for both men and women who are keen on acquiring clothing that is made from high-quality fabrics, stitched to perfect and suitable for both summer and winters. fennec is a brand you can trust as we believe in learning continuously and only delivering the best to you always. We are new, we are eager to be the best and are making great strides in sporting, casual, and polo shirts wear.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={fennec} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={fennec1} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <br />
                        <h2 className='textHead2'>About Products:</h2>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="paraPort">A classic style made with diligent attention to detail and perfection. Handcrafted from the finest cotton fabric, this t-shirt is perfect for casual wear. Your hunt for the perfect and comfortable T-shirt ends here. We promise you’ll be wearing this one again, and again, and again.</p>

                                <p className="paraPort">A Modern style made with diligent attention to detail and perfection. Handcrafted from the finest cotton fabric, this t-shirt is perfect for casual wear. Your hunt for the perfect and comfortable T-shirt ends here. We promise you’ll be wearing this one again, and again, and again.</p>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <img src={fennecj} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Fennec;