import React from "react";
import Service from "../Content/Service";
import service from "../../img/service.webp";


let Services = () => {
  return (
    <>
    <section>
      <figure>
        <img src={service} style={{width:"100%"}} alt="advertisement" />
      </figure>
    </section>
    <div className="mt-5">
      <Service/>
    </div>
    </>
  );
};

export default Services;
