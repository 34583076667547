import React from "react";

let DigitalServ = () => {
  return (
    <>
      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <br />
                    <h6 className="pFirst">SEO</h6>
                    <br />
                    <h6 className="MFirst">Starting From</h6>

                    <h6 className="RFirst">
                      <b>9,999/-</b>
                      <span style={{ color: "#fff", fontSize: "8px" }}>
                        (Month)
                      </span>
                    </h6>

                    <div className="wrap-collabsible">
                      <input
                        id="collapsible"
                        className="toggle"
                        type="checkbox"
                      />
                      <label htmlFor="collapsible" className="lbl-toggle">
                        View More
                      </label>
                      <div className="collapsible-content">
                        <div className="content-inner">
                          <b>Search Engine Optimisation (SEO)</b>
                          <br />
                          <br />
                          <li>
                            Researching relevant keywords that have strong
                            search volume.
                          </li>
                          <li>
                            Ensuring the on-page content satisfies the user’s
                            search query & intent.
                          </li>
                          <li>
                            Building internal links from page to page to create
                            strong connections between content.
                          </li>
                          <li>
                            Balancing internal links with relevant and reputable
                            outbound links.
                          </li>
                          <li>
                            Ensuring content on a page is organized well &
                            structured correctly for Search engines.
                          </li>
                          <li>
                            Researching valuable opportunities for link building
                            outreach.
                          </li>
                          <li>
                            Prioritizing opportunities based on time, effort &
                            expected return value Strategizing outreach (such as
                            email, submissions, forum discussion).
                          </li>
                          <li>Tracking new inbound links generated.</li>
                          <li>On Page SEO.</li>
                          <li>Off Page SEO.</li>
                          <li>Local SEO.</li>
                          <li>
                            Ultimately bringing the website in first page of
                            google search.
                          </li>
                          <hr style={{ background: "#fff" }} />
                          <h6>Terms & Conditions Apply</h6>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <br />
                    <h6 className="pFirst">SEM</h6>
                    <br />
                    <h6 className="MFirst">Starting From</h6>
                    <h6 className="RFirst">
                      <b>11,999/-</b>
                      <span style={{ color: "#fff", fontSize: "8px" }}>
                        (Month)
                      </span>
                    </h6>

                    <div className="wrap-collabsible1">
                      <input
                        id="collapsible1"
                        className="toggle"
                        type="checkbox"
                      />
                      <label htmlFor="collapsible1" className="lbl-toggle1">
                        View More
                      </label>
                      <div className="collapsible-content1">
                        <div className="content-inner1">
                          <b>Search Engine Marketing (SEM / PPC)</b>
                          <br />
                          <br />
                          <li>Keyword Analysis</li>
                          <li>Keyword Research</li>
                          <li>Competitor Analysis</li>
                          <li>
                            Google Search, Display, Shopping & etc Ads Setup
                          </li>
                          <li>Lead Generation Campaign Setup</li>
                          <li>Campaign Optimisation</li>
                          <li>Domain Authority Improvements</li>

                          <hr style={{ background: "#fff" }} />
                          <h6>Terms & Conditions Apply</h6>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <br />
                    <h6 className="pFirst">SMM</h6>
                    <br />
                    <h6 className="MFirst">Starting From</h6>
                    <h6 className="RFirst">
                      <b>14,999/-</b>
                      <span style={{ color: "#fff", fontSize: "8px" }}>
                        (Month)
                      </span>
                    </h6>
                    <div className="wrap-collabsible2">
                      <input
                        id="collapsible2"
                        className="toggle"
                        type="checkbox"
                      />
                      <label htmlFor="collapsible2" className="lbl-toggle2">
                        View More
                      </label>
                      <div className="collapsible-content2">
                        <div className="content-inner2">
                          <b>Social Media Marketing (SMM):</b>
                          <br />
                          <br />
                          <li>Keyword Analysis</li>
                          <li>
                            Visually attractive post creation for all Social
                            media.
                          </li>
                          <li>15 Social Media Posts and Stories.</li>
                          <li>Planning, Strategy, Integration.</li>
                          <li>
                            Management, Execution. Analytics of Social Media
                            Handle.
                          </li>
                          <li>Brand Awareness Campaign.</li>
                          <li>Content calendar Creation.</li>
                          <li>Building genuine followers in Social Media.</li>
                          <li>
                            Building genuine engagement such as likes, comments
                            & shares etc.
                          </li>
                          <li>Making viral audience base.</li>
                          <li>
                            Promoting the daily posts to our targeted audience.
                          </li>
                          <li>
                            Bringing huge digital presence with great numbers.
                          </li>
                          <li>
                            With active followers, audience to your social
                            platforms.
                          </li>
                          <li>Lead generation campaigns & Sales campaigns.</li>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              
                <div
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                  id="service1"
                >
                  <div className="cardPrice">
                    <div className="text-center" style={{ marginTop: "22px" }}>
                      <br />
                      <h6 className="pFirst">Online / Mobile Marketing</h6>
                      <br />
                      <h6 className="MFirst">Starting From</h6>
                      <h6 className="RFirst">
                        <b>14,999/-</b>
                        <span style={{ color: "#fff", fontSize: "8px" }}>
                          (Month)
                        </span>
                      </h6>
                      <div className="wrap-collabsible3">
                        <input
                          id="collapsible3"
                          className="toggle"
                          type="checkbox"
                        />
                        <label htmlFor="collapsible3" className="lbl-toggle3">
                          View More
                        </label>
                        <div className="collapsible-content3">
                          <div className="content-inner3">
                            <b>Online / Mobile Marketing:</b>
                            <br />
                            <br />
                            <li>
                              Researching relevant keywords that have strong
                              search volume.
                            </li>
                            <li>
                              Ensuring the on-page content satisfies the user’s
                              search query & intent.
                            </li>
                            <li>
                              Building internal links from page to page to
                              create strong connections between content.
                            </li>
                            <li>
                              Balancing internal links with relevant and
                              reputable outbound links.
                            </li>
                            <li>
                              Ensuring content on a page is organized well &
                              structured correctly for Search engines.
                            </li>
                            <li>
                              Researching valuable opportunities for link
                              building outreach.
                            </li>
                            <li>
                              Prioritizing opportunities based on time, effort &
                              expected return value Strategizing outreach (such
                              as email, submissions, forum discussion).
                            </li>
                            <li>Tracking new inbound links generated.</li>
                            <li>On Page SEO.</li>
                            <li>Off Page SEO.</li>
                            <li>Local SEO.</li>
                            <li>
                              Ultimately bringing the website in first page of
                              google search.
                            </li>
                            <hr style={{ background: "#fff" }} />

                            <h6>Terms & Conditions Apply</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                  id="service1"
                >
                  <div className="cardPrice">
                    <div className="text-center" style={{ marginTop: "22px" }}>
                      <br />
                      <h6 className="pFirst">DP / ORM / BA Activity</h6>
                      <br />
                      <h6 className="MFirst">Starting From</h6>
                      <h6 className="RFirst">
                        <b>9,999/-</b>
                        <span style={{ color: "#fff", fontSize: "8px" }}>
                          (Month)
                        </span>
                      </h6>
                      <div className="wrap-collabsible4">
                        <input
                          id="collapsible4"
                          className="toggle"
                          type="checkbox"
                        />
                        <label htmlFor="collapsible4" className="lbl-toggle4">
                          View More
                        </label>
                        <div className="collapsible-content4">
                          <div className="content-inner4">
                            <b>DP / ORM / BA:</b>
                            <br />
                            <br />
                            <li>
                              Create A Presence On Any And All Relevant Web
                              Properties.
                            </li>
                            <li>
                              Managing Social media and web engagement. Brand
                              Monitoring.
                            </li>
                            <li>Negative comments management.</li>
                            <li>
                              Strong Brand Presence. Reputation Building
                              Building Quantity of positive responses.
                            </li>

                            <hr style={{ background: "#fff" }} />

                            <h6>Terms & Conditions Apply</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                  id="service1"
                >
                  <div className="cardPrice">
                    <div className="text-center" style={{ marginTop: "22px" }}>
                      <br />
                      <h6 className="pFirst">Sales & Lead Campaigns</h6>
                      <br />
                      <h6 className="MFirst">Starting From</h6>
                      <h6 className="RFirst">
                        <b>14,999/-</b>
                        <span style={{ color: "#fff", fontSize: "8px" }}>
                          (Month)
                        </span>
                      </h6>
                      <div className="wrap-collabsible5">
                        <input
                          id="collapsible5"
                          className="toggle"
                          type="checkbox"
                        />
                        <label htmlFor="collapsible5" className="lbl-toggle5">
                          View More
                        </label>
                        <div className="collapsible-content5">
                          <div className="content-inner5">
                            <b>Sales & Lead Campaigns:</b>
                            <br />
                            <br />

                            <li>
                              Generating B2B & B2C Leads for every business
                              through the suitable way of Promotion / Marketing
                              Approach Either by SMM, SEM or Both.{" "}
                            </li>
                            <hr style={{ background: "#fff" }} />
                            <h6>Terms & Conditions Apply</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                >
                  <div className="cardPrice">
                    <div className="text-center" style={{ marginTop: "22px" }}>
                      <br />
                      <h6 className="pFirst">Complete Digital Marketing</h6>
                      <br />
                      <h6 className="MFirst">Starting From</h6>
                      <h6 className="RFirst">
                        <b>29,999/-</b>
                        <span style={{ color: "#fff", fontSize: "8px" }}>
                          (Month)
                        </span>
                      </h6>
                      <div className="wrap-collabsible6">
                        <input
                          id="collapsible6"
                          className="toggle"
                          type="checkbox"
                        />
                        <label htmlFor="collapsible6" className="lbl-toggle6">
                          View More
                        </label>
                        <div className="collapsible-content6">
                          <div className="content-inner6">
                            <b>Complete Digital Marketing:</b>
                            <br />
                            <br />

                            <li>SEO</li>
                            <li>SEM</li>
                            <li>SMM</li>
                            <li>Online/ Mobile Marketing</li>
                            <li>DP/ORM/BA</li>
                            <li>Sales/ Lead Campaign</li>
                            <li>Content Marketing</li>
                            <li>Graphic Designing</li>

                            <hr style={{ background: "#fff" }} />

                            <h6>Terms & Conditions Apply</h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%2FApp%2FSoftware%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote1">Get Quote</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default DigitalServ;
