import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

let EcomPackagePrice = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);

  const onOpenModal4 = () => setOpen4(true);
  const onCloseModal4 = () => setOpen4(false);

  const onOpenModal5 = () => setOpen5(true);
  const onCloseModal5 = () => setOpen5(false);

  const onOpenModal6 = () => setOpen6(true);
  const onCloseModal6 = () => setOpen6(false);

  const onOpenModal7 = () => setOpen7(true);
  const onCloseModal7 = () => setOpen7(false);

  const onOpenModal8 = () => setOpen8(true);
  const onCloseModal8 = () => setOpen8(false);

  return (
    <>
      <div className="project-detail">
        <div className="container">
          <h2
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "30px",
            }}
          >
            Ecommerce Website + Advance Gads Setup + 5 Business Mail Ids +<br />
            Premium Hosting Package + 24 Lacs Worth Tech Tools (Free)
          </h2>

          <h4
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "20px",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            Regular Price - <s style={{ color: "red", fontWeight:'bold' }}>₹2,40,000 </s>
            <br></br>Offer Price - ₹55,000
          </h4>

          <div className="d-flex">
            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20 a Ecommerce Website Development%20Services%20%2CGet%20back%20to%20us%20Asap." target="_blank">
              <div className="btnWAPP">
                <i className="fa-brands fa-whatsapp"></i>
              </div>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="mailto:sales@webxinfinity.com">
              <div className="btnMAIL">
                <i className="fa-solid fa-envelope"></i>
              </div>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="tel:+917975207595">
              <div className="btnCALL">
                <i className="fa-solid fa-phone" id="fa-phone"></i>
              </div>
            </a>
            &nbsp;&nbsp;&nbsp;
            <a href="#aa">
              <button className="btnQuote">Get Quote</button>
            </a>
          </div>
          <div className="row " style={{ paddingBottom: "50px" }}>
            <div
              className="col-lg-4 col-md-12 col-sm-12 col-xs-12 my-3"
              id="servicenew"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ paddingTop: "32px" }}>
                  <h6 className="pFirst">Ecommerce Website Package</h6>
                  <div className="wrap-collabsible" style={{ color: "white" }}>
                    <hr style={{ background: "#fff" }} />
                    <p>- 1 Year Domain Free (Value 500rs)</p>
                    <p>
                      - Professional and International Standard Landing Home
                      page (Marketing Enabled)
                    </p>
                    <p>- Mobile Responsive and Retina Clear to all devices</p>
                    <p>- Unlimited Categories, Products, Brands </p>
                    <p>- Unlimited Inbuilt SEO for all Products,</p>
                    <p>
                      - Unlimited Attributes, Digital, Customer Products,
                      Sliders & Banners, Coupon Code{" "}
                    </p>
                    <p>- Unlimited Product Display Offer </p>
                    <p>
                      - Multi Currency Payment Gateway Discounted Special Price{" "}
                    </p>
                    <p>- Login Flow for Customer, Guest and Admin. </p>

                    <div className="wrap-collabsible">
                      <input
                        id="collapsible"
                        className="toggle"
                        type="checkbox"
                      />
                      <label htmlFor="collapsible" className="lbl-toggle">
                        View More
                      </label>
                      <div className="collapsible-content">
                        <div className="content-inner">
                          <p>
                            - Related Products, Product Options and Variations
                            Product, Wishlist Product, Reviews, Product Search
                            Bar, Product basic Filter, Product Bulk Import &
                            Export{" "}
                          </p>{" "}
                          <br />
                          <p>- Today’s deals Flash Sales </p>
                          <br />
                          <p>- Featured Products Product Compare </p>
                          <br />
                          <p>- 2 level Tax Rates Shipping rates</p>
                          <br />
                          <p>- Backup & Restore Automatic System</p>
                          <br />
                          <p>- Generated Order Invoice</p>
                          <br />
                          <p>- Email System for order booking confirmation</p>
                          <br />
                          <p>- Shiping Setup Sales Report</p>
                          <br />
                          <p>- Pincode Check </p>
                          <br />
                          <p>- Download Customer Data</p>
                          <br />
                          <p>- Booking Confirmation Email Setup</p>
                          <br />
                          <p>- Custom Designs for Internal Pages</p>
                          <br />
                          <p>- Dynamic Admin Panel</p>
                          <br />
                          <p>- User Panel</p>
                          <br />
                          <p>- Header & Footer</p>
                          <br />
                          <p>- SEO Friendly Design</p>
                          <br />
                          <p>- Dynamic Blog Integration</p>
                          <br />
                          <p>- Responsive Design (Recommended)</p>
                          <br />
                          <p>- Content Management Panel</p>
                          <br />
                          <p>- Inquiry Form</p>
                          <br />
                          <p>- Free Training for CMS</p>
                          <br />
                          <p>- Responsive For All Devices</p>
                          <br />
                          <p>- Project Delivery 7 Working Days</p>
                          <br />
                          <p>- Full Source Code</p>
                          <br />
                          <p>- 12 Month Free Support*</p>
                          <br />
                          <p>- Social Media Integration</p>
                          <br />
                          <p>- Google Map Integration</p>
                          <br />
                          <p>- Google Review integration</p>
                          <br />
                          <p>- LiveChat Integration</p>
                          <br />
                          <p>- WhatsApp Chat Integration</p>
                          <br />
                        </div>
                      </div>
                    </div>
                    {/* <hr style={{ background: "#fff" }} />

                    <h6>Terms & Conditions Apply</h6>
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20 a 5 Page Dynamic Website Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="mailto:sales@webxinfinity.com">
                        <div className="btnMAIL">
                          <i className="fa-solid fa-envelope"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="tel:+917975207595">
                        <div className="btnCALL">
                          <i className="fa-solid fa-phone" id="fa-phone"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="servicenew"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ paddingTop: "32px" }}>
                  <h6 className="pFirst">Hosting & Emails Package</h6>
                  <hr style={{ background: "#fff" }} />
                  <div className="wrap-collabsible" style={{ color: "white" }}>
                    <p>- 1 Website</p>
                    <p>- Storage - Unlimited & Unmetric*</p>
                    <p>- NVMe Storage (7X Faster SSD)</p>
                    <p>- 6 GB RAM</p>
                    <p>- 4 CPU Cores </p>
                    <p>- 250 GB SSD Storage (Shared)</p>
                    <p>- 300000 Visits Monthly (Shared)</p>
                    <p>- Dedicated IP Address (Shared)</p>
                    <p>- Daily Backups (Value 2000₹)</p>
                    <p>- 1 FTP Accounts </p>

                    <p>- 1 Free SSL (First Year) (Value 4000₹)</p>

                    <div className="wrap-collabsible1">
                      <input
                        id="collapsible1"
                        className="toggle"
                        type="checkbox"
                      />
                      <label htmlFor="collapsible1" className="lbl-toggle1">
                        View More
                      </label>
                      <div className="collapsible-content1">
                        <div className="content-inner1">
                          <p>- Wordpress Website builder</p>
                          <br />
                          <p>- Managed WordPress </p> <br />
                          <p>- Free CDN</p>
                          <br />
                          <p>- Optimized for Wordpress</p>
                          <br />
                          <p>- WordPress Staging Tool </p>
                          <br />
                          <p>- Object Cache for WordPress</p>
                          <br />
                          <p>- Shared Package </p>
                          <br />
                          <h6 className="pFirst">Emails Package</h6>
                          <hr style={{ background: "#fff" }} />
                          <br />
                          <p>- Forever Free Plan (Zoho Partner)</p>
                          <br />
                          <p>- Up to five user</p> <br />
                          <p>- 5GB/User </p>
                          <br />
                          <p>- 25MB attachment limit</p>
                          <br />
                          <p>- Web access and free mobile apps* </p>
                          <br />
                          <p>- Email hosting for a single domain</p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wrap-collabsible">
                    {/* <button id="modalBTN" onClick={onOpenModal1}>
                      VIEW MORE
                    </button> */}
                    <Modal open={open1} onClose={onCloseModal1} center>
                      <h4>
                        <strong>Standard </strong>
                        <h6 className="pFirst">Security Package</h6>
                        <br />
                        <p>- Enhanced DDoS Protection</p>
                        <p>- Web Application Firewall</p>
                        <p>- Cloudflare Protected Nameservers </p>
                        <p>- Malware Scanner</p>
                        <p>- Secure Access Manager </p>
                        <p>- Free Domain WHOIS Privacy Protection </p>
                      </h4>
                      <hr />
                      <p>- Free Domain</p>
                      <p>- Free SSL Certificate</p>
                      <p>- Delivery: 24-48 Days</p>
                      <p>- Dynamic Website - CMS</p>
                      <p>
                        - <b>Admin Panel</b>
                      </p>
                      <p>- Home Page</p>
                      <p>- Profile Page</p>
                      <p>
                        - <b>15 Sub Pages</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Listing</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Detail Page</b>
                      </p>
                      <p>- Testimonials or Events Page</p>
                      <p>- Dynamic Gallery/Portfolio</p>
                      <p>- Enquiry or Contact Form</p>
                      <p>- Video Page</p>
                      <p>- Free Business Email Accounts</p>
                      <p>
                        - <b>Unlimited FREE Hosting</b>
                      </p>
                      <p>
                        - <b>Readymade Themes</b>
                      </p>
                      <p>
                        - <b>SEO Friendly Website</b>
                      </p>
                      <p>- Live Chat or Whatsapp Button</p>
                      <p>- Call to Action Button</p>
                      <p>- Social Media Integration</p>
                      <p>- Facebook, Instagram, Linkedin</p>
                      <p>- Free-Hosting*</p>

                      {/* <hr style={{ background: "#fff" }} />

                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 10 Page Dynamic Website Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div> */}
                    </Modal>
                    {/* <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 10 Page Dynamic Website Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="servicenew"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ paddingTop: "10px" }}>
                  <div className="wrap-collabsible" style={{ color: "white" }}>
                    <h6 className="pFirst">Payment Gateway </h6>
                    <hr style={{ background: "#fff" }} />
                    <p>- Razorpay Payment Gateway</p>
                    <p>- Instamojo Payment Gateway</p>
                    <p>- Billdesk Payment Gateway </p>
                    <p>- Paytm Payment Gateway</p>
                    <p>- Payu Payment Gateway </p>
                    <p>- CCAvenue Payment Gateway </p>

                    <h6 className="pFirst">Security & Support 24x5 (Email)</h6>
                    <hr style={{ background: "#fff" }} />

                    <p>- Enhanced DDoS Protection</p>
                    <p>- Web Application Firewall</p>
                    <p>- Cloudflare Protected Nameservers </p>
                    <p>- Malware Scanner</p>

                    {/* <h6 className="pFirst">3rd Party Integration</h6>
                  <hr style={{ background: "#fff" }} />
                    <br />
                    <p>- Google Mx Records</p>
                    <p>- HubStop Integration</p>
                    <p>- Smtp Connection </p>
                    <p>- 3rd Party Images</p>
                    <p>- Auto 3rd Party Content (Demo)</p> */}
                    <div className="wrap-collabsible2">
                      <input
                        id="collapsible2"
                        className="toggle"
                        type="checkbox"
                      />
                      <label htmlFor="collapsible2" className="lbl-toggle2">
                        View More
                      </label>
                      <div className="collapsible-content2">
                        <div className="content-inner2">
                          <p>- Secure Access Manager </p> <br />
                          <p>- Free Domain WHOIS Privacy </p> <br />
                          <h6 className="pFirst">Support 24x5 (Email)</h6>
                          <hr style={{ background: "#fff" }} />
                          <br />
                          <p>- CMS Training 1 time</p> <br />
                          <p>- 12 Months Support</p> <br />
                          <p>- Resolve Issues in 24-48 Hrs (Existing) </p>{" "}
                          <br />
                          <p>- Knowledge Base </p> <br />
                          <p>- Dedicated Project Manager (Email)</p> <br />
                          <p>- Project Whatsapp Group</p>
                          <br />
                          <p>- 99.9% Uptime Guarantee</p>
                          <br />
                          <p>- Global Data Centres</p>
                          <br />
                          <p>- 24/7 Customer Support (Server)</p>
                          <br />
                          <p>- Priority Support (Email)</p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wrap-collabsible">
                    {/* <button id="modalBTN" onClick={onOpenModal2}>
                      VIEW MORE
                    </button> */}
                    <Modal open={open2} onClose={onCloseModal2} center>
                      <h4>
                        <strong>Advanced</strong>
                      </h4>
                      <hr />
                      <p>- Free Domain</p>
                      <p>- Free SSL Certificate</p>
                      <p>- Delivery: 24-48 Days</p>
                      <p>- Dynamic Website - CMS</p>
                      <p>
                        - <b>Admin Panel</b>
                      </p>
                      <p>- Home Page</p>
                      <p>- Profile Page</p>
                      <p>
                        - <b>Unlimited Pages</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Listing</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Detail Page</b>
                      </p>
                      <p>- Testimonials or Events Page</p>
                      <p>- Dynamic Gallery/Portfolio</p>
                      <p>- Enquiry or Contact Form</p>
                      <p>- Video Page</p>
                      <p>- Contact Form</p>
                      <p>- Free Business Email Accounts</p>
                      <p>
                        - <b>Unlimited FREE Hosting</b>
                      </p>
                      <p>
                        - <b>Readymade Themes</b>
                      </p>
                      <p>
                        - <b>SEO Friendly Website</b>
                      </p>
                      <p>- Live Chat or Whatsapp Button</p>
                      <p>- Call to Action Button</p>
                      <p>- Social Media Integration</p>
                      <p>- Facebook, Instagram, Linkedin</p>
                      <p>- Free-Hosting*</p>
                      <p>
                        <b>- HOME PAGE CUSTOM DESIGN</b>
                      </p>
                      <p>
                        <b>- BLOG</b>
                      </p>
                      <p>- Project Manager Assigned</p>

                      <hr style={{ background: "#fff" }} />

                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 20 Page Dynamic Website Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>

                    {/* <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 20 Page Dynamic Website Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 my-12"
              id="servicenew"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">Google Ads One Time Setup </h6>
                  <hr style={{ background: "#fff" }} />
                  <br />
                  <Accordion style={{ textAlign: "center" }}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Google Ads Setup
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Thorough consultation to understand your business
                          goals and target audience.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Creation of strategic paid media campaigns tailored
                          to your objectives.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Keyword research and selection for precise
                          targeting.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Compelling ad copy and creative development to
                          maximize engagement.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Conversion Tracking Implementation
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Precise setup of conversion tracking to monitor key
                          actions (e.g., purchases, sign-ups).{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Tracking code integration for comprehensive insights
                          into user behavior.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Regular analysis of conversion data to fine-tune
                          campaign strategies.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Ongoing Optimization (1 Months)
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Continuous A/B testing of ad elements to identify
                          high-performing variations.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Strategic bidding adjustments for optimal budget
                          allocation.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Landing page optimization to improve user experience
                          and conversions.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Regular review of campaign performance to refine
                          strategies.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Google Analytics Integration and Monitoring
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Integration of Google Analytics for detailed
                          insights into user interactions.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Analysis of traffic sources, user behavior, and
                          engagement metrics.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Utilization of analytics data to enhance campaign
                          Targeting and Strategy.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Performance Reporting
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Detailed monthly reports showcasing key metrics and
                          conversion rates.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - In-depth analysis of campaign performance,
                          highlighting successes and areas for improvement.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Transparent communication to keep you informed of
                          progress.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6 col-sm-12 col-xs-12 my-12"
              id="servicenew"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">Other Optimisation </h6>
                  <hr style={{ background: "#fff" }} />
                  <br />
                  <Accordion style={{ textAlign: "center" }}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Keyword Search Review
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <h4>Search Term Audit (add extra negative KWs)</h4>
                        <p style={{ textAlign: "center" }}>
                          - Add extra negative KWs to stop unrelated searches
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Add extra long tail search terms
                        </p>
                        <h4>Identify keywords that are not performing well</h4>
                        <p style={{ textAlign: "center" }}>
                          - Keywords with a high CPC compared to the account
                          average.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Keywords which have a high cost per conversion
                          compared to the account average.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Keywords with a status warning (low search volume,
                          below first page bid, rarley shown due to low quality
                          score).
                        </p>
                        <h4>Review match types</h4>
                        <p style={{ textAlign: "center" }}>
                          - Are there keywords which can be added as phrase or
                          exact match.
                        </p>
                        <h4>Auction Insights Report</h4>
                        <p style={{ textAlign: "center" }}>
                          - Are there new competitors or have competitors
                          increased their spend.
                        </p>
                        <h4>Keyword status</h4>
                        <p style={{ textAlign: "center" }}>
                          - Do any keywords have a 'below first page bid
                          estimate' warning.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Do any keywords have a 'low search volume' warning.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Do any keywords have a 'rarely shown due to low
                          quality score' warning.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Ad Review
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <h4>Review Ads by Ad Group</h4>
                        <p style={{ textAlign: "center" }}>
                          - Identify Ads with poor performance by conversion
                          rate.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Identify Ads with poor performance by low quality
                          score.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Identify Ads with poor performance by low CTR.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Review Ad Strength: Add or update until we get an
                          excellent score.
                        </p>

                        <h4>Landing Page Review</h4>

                        <p style={{ textAlign: "center" }}>
                          - Are different landing pages performing better in
                          terms of conversion rate.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - check all landing pages to make sure that are URLs
                          are working and that Google Ads is not disapproving.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          many ads due to 404s, slow page load times etc
                        </p>

                        <h4>Ad Copy Warnings</h4>

                        <p style={{ textAlign: "center" }}>
                          - check all ads to make sure that google ads has not
                          applied any limited approvals, which could be
                          affecting the visibility of the ad.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - have any ads been disapproved If so, make changes
                          and re-submit ads.
                        </p>

                        <h4>Ad Extensions Check & Recommendations</h4>

                        <p style={{ textAlign: "center" }}>
                          - Sitelink extensions, Callout extensions,
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Call extension, Lead form extension.
                        </p>

                        <p style={{ textAlign: "center" }}>
                          - Location extensions (GMB linking), Affiliate
                          location extensions
                        </p>

                        <p style={{ textAlign: "center" }}>
                          - Price extension, Promotion extension, image
                          extensions
                        </p>
                        <p style={{ textAlign: "center" }}>-</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Bid Optimisation - Audience & Demographic Targeting
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <h4>
                          Search for possible bid optimisations to improve
                          performance, under dimensions
                        </h4>

                        <p style={{ textAlign: "center" }}>
                          - Hour of the day / Day of the week,
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Demographics by: age, gender, income, location.
                        </p>

                        <p style={{ textAlign: "center" }}>- Audiences</p>

                        <p style={{ textAlign: "center" }}>
                          - Review new potential new audiences to add
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Quality Score (keywords & ads) & Conversion Action
                          Checks
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Identify Keywords and Ads that have a low Quality
                          score.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Changes to ad copy to help with KW targeting and
                          quality score.
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Landing page updates to help with KW targeting.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Google Analytics checks to review bounce rate and
                          average session time.
                        </p>

                        <p style={{ textAlign: "center" }}>
                          - Are all conversion actions working.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - If tracking phone calls, review the call extension
                          report.{" "}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Location Targeting, Quality Control & Recommendations
                          Tab
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <p style={{ textAlign: "center" }}>
                          - Identity searches from non targeted location (and
                          exclude locations).{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Review location performance and add bid
                          optimisations (if required).{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Budget pacing checks.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Payment & Account budgets.{" "}
                        </p>
                        <p style={{ textAlign: "center" }}>
                          - Review system generated recommendations and
                          implement or reject.{" "}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton style={{ textAlign: "center" }}>
                          Campaign Goal Settings
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel style={{ textAlign: "center" }}>
                        <h4>Is current campaign goal still the best option?</h4>
                        <p style={{ textAlign: "center" }}>
                          - For CPA Goal: is this higher than the current
                          performance. (If actual CPA is lower than CPA goal the
                          CPA goal should be reduced ).{" "}
                        </p>

                        <p style={{ textAlign: "center" }}>
                          - Has your number of conversions stalled. (if no other
                          reason can be found test another campaign goal).{" "}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcomPackagePrice;
