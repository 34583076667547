import React from 'react';
import malnad from "../../../img/Projects/malnad.webp";

let Malnad = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={malnad} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            malnad controls
                        </div>
                        <h5>Established in 1996, MC&E Pvt. Ltd. Has gained a reputation in the the market as a noteworthy manufacturer due to our rich experience in providing safe, innovative, and reliable Electrical Controls and Distribution Solutions.</h5>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Who we are?</h2>

                        <p className='paraPort'>MC&E Pvt. Ltd. an establishment since 1996 has gained a huge reputation in the market as a noteworthy manufacturer by extending its rich experience of providing safe, innovative & reliable <b>Electrical Controls and Distribution Solutions</b> in Karnataka with over 25 years of industry experience. We’ve been engaged in serving our comprehensive and customized range to customers all across India. Our complete product range is designed and manufactured in-house enabling us to have full control over quality, time and efficiency.</p>

                        <p className='paraPort'>The company is headed by Annaiah PK (CEO), having vast experience over 40 years in Electrical industry. The dedicated team of qualified Engineers, professional management, quality and commitment are the core strength of Malnad.</p>

                        <p className='paraPort'>Our certified state of art manufacturing unit is located at Peenya Industrial Estate Bangalore. Being one of the best <b>LT Distribution Panel Manufacturers</b>, we use superior quality raw materials and components to secure our product quality and performance. All our products pass through highly quality assurance cell to ensure a flawless final product.</p>

                        <p className='paraPort'>The cutting edge design and engineered solutions have been highly appreciated by our Clients, Consultant and Contractors, thus enabling to win confidence and increase the business opportunities.</p>

                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Manufacturing Standards & Capabilities</h2>
                        <li className="paraPort">Our facility is spread over 4300sqft space, equipped with international standard machineries and tools for complete &nbsp;&nbsp;&nbsp;&nbsp;fabrication, assembly and testing of panels</li>
                        <li className="paraPort">We rely on latest version of design softwares, and our highly competent design team is trained to produce efficient engineered &nbsp;&nbsp;&nbsp;&nbsp;solutions</li>
                        <li className="paraPort">Our products are CPRI type tested as per IEC-61439-1: 2020</li>
                        <li className="paraPort">We have executed single large projects sizing INR 35 million</li>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Malnad;