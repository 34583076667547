import React from "react";
import { Helmet } from "react-helmet";
import Awards from "./Awards";
import Form from "./Form";
// import Offer from "./Offer";
import Portfolio from "./Portfolio";
import Reviews from "./Reviews";
import Service from "./Service";
import Trend from "./Trend";
import Video from "./Video";
import VideoTestimonials from "./VideoTestimonials";
import Whywebx from "./Whywebx";

let Content = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Web, Mobile App Development Company in Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <Video />
      <Service />
      {/* <Offer /> */}
      <Portfolio />
      <Whywebx />
      <Trend />
      <Awards />
      <Reviews />
      <VideoTestimonials />
      <Form />
    </>
  );
};

export default Content;
