import React from "react";
import { Helmet } from "react-helmet";

import FormServ from "./FormServ";
import video from "../../img/video.webp";

let Production = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Corporate Video Marketing | Webxinfinity IT Solution, Bengaluru
        </title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>
      <section>
        <figure>
          <img src={video} alt="video marketing" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">VIDEO MARKETING</div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    At webexinfinity we offer corporate video making services
                    which is of high quality and minimal costs. We make content
                    that drives your business with ease and represents the best
                    of your company. No corporate event is too small or too big
                    for us. We offer established connection through out to offer
                    your company the best experience. Our team offers best
                    quality videos with higher visibility of your brand and
                    value. We keep in mind the timelines and company factors to
                    offer the best service.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="row" id="servicesHome">
                  <div className="col-lg-4 my-2">
                    <div className="flip-card">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets4.lottiefiles.com/packages/lf20_ttqu5axh.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light my-3">
                            Ad Film Shot
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets2.lottiefiles.com/packages/lf20_rsgxuwx0.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light webH5 my-3">
                            Corporate Filming
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets3.lottiefiles.com/packages/lf20_dupmdzj3.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light webH5 my-3">
                            Business Video
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <FormServ />
          </div>
        </div>
      </section>
    </>
  );
};

export default Production;
