import React from 'react';

import jls from "../../../img/Projects/jls.webp";
import jls1 from "../../../img/Projects/jls1.webp";
import jls2 from "../../../img/Projects/jls2.webp";


let JLS = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={jls} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            jls construction
                        </div>
                        <h5>JLS Construction India are committed to giving a second to none service to all of our clients. It is our policy to provide a reliable, cost effective and trouble free experience as we deliver your project while remaining true to our commitments on health and safety and the environment.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>About Cilent </h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>Greetings and welcome to JLS Construction India. We are proud of the many projects we have built in our 10 plus years. Our experience includes projects in Bangalore, Mangalore, Hyderabad, Chennai and surrounding states. We have extensive expertise with all types of road works and residential projects, commercial and industrial projects and an impressive resume of work in mission critical and retail construction.</p>

                        <p className='paraPort'>Please enjoy your trip around the world of JLS Construction India. We look forward to an opportunity to serve your building needs in the near future.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={jls1} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={jls2} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <br />
                        <h2 className='textHead2'>Mission:</h2>
                        <p className="paraPort">To safety and profitably serve our client’s construction needs while maintaining a reputation for star class quality, integrity and employee satisfaction.</p>

                        <br />
                        <h2 className='textHead2'>Vision:</h2>
                        <p className="paraPort">JLS Construction India is committed to being a class builder that is known externally for quality, Integrity and resourcefulness, and internally for profitability and employee satisfaction.</p>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default JLS;