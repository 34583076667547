import React from "react";
import { Helmet } from "react-helmet";
import website from "../../img/website.webp";
import Portfort from "../Offer/Portfol";
import Prize from "../Offer/Prize";
import Webform from "../Content/Webform";
import BusinessPrice from "./BusinessPrice";
import PackageForm from "./PackageForm";

let BusinessWebsite = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Business Website Package | Webxinfinity IT Solution, Bengaluru
        </title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={website} alt="custom development" />
        </figure>
      </section>
      <BusinessPrice />

      {/* Form Below */}

      {/* Form End */}
      <section>
        <div className="project-detail2">
          <div className="container">
            {/* <div className="title">
              <div className="glitch">WEB DEVELOPMENT</div>
            </div> */}

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  {/* <p>
                    We at webxinfinity build websites that emphasizes the value
                    of service that our clients provide in a user-friendly
                    manner to reach its targeted audience. Our designing experts
                    are known to create visually pleasing and meaningful design
                    which keeps the customer engaged. We make sure our web
                    content covers all the information needed so that website
                    stands as a welcome door for targeted customers. We create
                    responsive websites that rearranges itself in the tablet and
                    mobile generation.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>

            <div className="title">
              <h6 className="glitch">Tech Tools</h6>
            </div>

            <div className="row mt-6">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="row" id="servicesHome">
                  <div className="col-lg-4 my-2">
                    <div
                      className="flip-card"
                      style={{ padding: "22px 0px", borderRadius: "10px" }}
                    >
                      <div className="contWebx">
                        <center>
                          {/* <lottie-player
                            src="https://assets5.lottiefiles.com/packages/lf20_M4t9nS.json"
                            background="transparent"
                            speed="1"
                            style={{ width: "254px", height: "140px" }}
                            loop
                            autoplay
                          ></lottie-player> */}
                          {/* <h4 className="text-center text-light my-3">
                            <b>Coding</b>
                          </h4>
                          <hr style={{ background: "#fff" }} /> */}

                          <h6 className="text-light">Semrush</h6>
                          <h6 className="text-light">Uber Suggest</h6>
                          <h6 className="text-light">Mez Pro</h6>
                          <h6 className="text-light">Serpstat</h6>
                          <h6 className="text-light">Majestic</h6>
                          <h6 className="text-light">SpyFu</h6>
                          <h6 className="text-light">SpamZilla</h6>
                          <h6 className="text-light">Keywordtool.io</h6>
                          <h6 className="text-light">Longtail Pro</h6>
                          <h6 className="text-light">Hashtagsforlikes</h6>
                          <h6 className="text-light">Kwfinder</h6>
                          <h6 className="text-light">SEO Site Checkup</h6>
                          <h6 className="text-light">
                            Creaitor.ai - AI Writer
                          </h6>
                          <h6 className="text-light">Flaticon</h6>
                          <h6 className="text-light">Word.ai</h6>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div
                      className="flip-card"
                      style={{ padding: "22px 0px", borderRadius: "10px" }}
                    >
                      <div className="contWebx">
                        <center>
                          {/* <lottie-player
                            src="https://assets5.lottiefiles.com/datafiles/fab7172a9302d416bcdb8ac7e1c71123/data.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player> */}
                          {/* <h4 className="text-center webH4 text-light my-3">
                            <b>Advance Tech</b>
                          </h4>
                          <hr style={{ background: "#fff" }} /> */}
                          <h6 className="text-light webH6">Word Tracker</h6>
                          <h6 className="text-light webH6">Seobility</h6>
                          <h6 className="text-light webH6">Seoscout</h6>
                          <h6 className="text-light webH6">Story Base</h6>
                          <h6 className="text-light webH6">Unbounce</h6>
                          <h6 className="text-light webH6">
                            {" "}
                            Instaried (Plagarism)
                          </h6>
                          <h6 className="text-light webH6">QuillBot</h6>
                          <h6 className="text-light webH6">Linguix</h6>
                          <h6 className="text-light webH6">Spin Rewriter</h6>
                          <h6 className="text-light webH6">ProWritingAid</h6>
                          <h6 className="text-light webH6">LovPik</h6>
                          <h6 className="text-light webH6">Story Blocks</h6>
                          <h6 className="text-light webH6">Vecteezy</h6>
                          <h6 className="text-light webH6">
                            AI Text to Speech
                          </h6>
                          <h6 className="text-light webH6">
                            Smart copy - AI Writer
                          </h6>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div
                      className="flip-card"
                      style={{ padding: "22px 0px", borderRadius: "10px" }}
                    >
                      <div className="contWebx">
                        <center>
                          {/* <lottie-player
                            src="https://assets7.lottiefiles.com/packages/lf20_hi95bvmx/WebdesignBg.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player> */}
                          {/* <h4 className="text-center webH4 text-light my-3">
                            <b>Builders</b>
                          </h4>
                          <hr style={{ background: "#fff" }} /> */}
                          <h6 className="text-light webH6">Stock Unilimited</h6>
                          <h6 className="text-light webH6">Epidmic Sound</h6>
                          <h6 className="text-light webH6">Smodin (testing)</h6>
                          <h6 className="text-light webH6">VidIQ (testing)</h6>
                          <h6 className="text-light webH6">
                            {" "}
                            Wordtune (testing)
                          </h6>
                          <h6 className="text-light webH6">
                            Netflix (Desktop)
                          </h6>
                          <h6 className="text-light webH6">Prime Video</h6>
                          <h6 className="text-light webH6">Vimeo (Business)</h6>
                          <h6 className="text-light webH6">snapied</h6>
                          <h6 className="text-light webH6">Canva Pro</h6>
                          <h6 className="text-light webH6">Envato Elements</h6>
                          <h6 className="text-light webH6">Pics Art</h6>
                          <h6 className="text-light webH6">SlideBean</h6>
                          <h6 className="text-light webH6">Design AI</h6>
                          <h6 className="text-light webH6">
                            GPL WP Themes & Plugins{" "}
                          </h6>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <PackageForm />
            {/* <Portfort /> */}
            <center>
              <h2
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingTop: "30px",
                  fontSize: "25px",
                }}
              >
                Special Offer Only for Above Privileged Customer (Till Your With
                Us)<br></br>
                <span style={{ color: "greenyellow", fontWeight: "bold" }}>
                  FREE - FREE - FREE
                </span>{" "}
                Worth{" "}
                <span style={{ color: "red" }}>
                  <b>₹2,00,000</b>
                </span>
                /Month -{" "}
                <span style={{ color: "red" }}>
                  <b>₹24,00,000</b>
                </span>
                /Year <br></br>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  ₹20,000{" "}
                </span>
                One time Google ads Credit Cashback +{" "}
                <span style={{ color: "greenyellow", fontWeight: "bold" }}>
                  Many More...
                </span>
              </h2>
              <br />
              <div className="title">
                <h6 className="glitch">OUR OFFER PRICE - ₹35,000 /- Only</h6>
              </div>
              <div className="Trend form-group col-12">
                <a
                  href="https://team.webxinfinity.com/products/client"
                  target="_blank"
                >
                  <button className="btn-contact" name="Pay Now">
                    <span data-hover="PAY-NOW">PAY NOW</span>
                  </button>
                </a>
              </div>
            </center>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessWebsite;
