import React from "react";
import { Helmet } from "react-helmet";
import website from "../../img/website.webp";
import Portfort from "./Portfol";
import Prize from "./Prize";
import Webform from "../Content/Webform";

let WebDevelop = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Website Development | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>
      
      <section>
        <figure>
          <img src={website} alt="custom development" />
        </figure>
      </section>
      <Prize />
       {/* Form Below */}
       <Webform />
            {/* Form End */}
      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">WEB DEVELOPMENT</div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    We at webxinfinity build websites that emphasizes the value
                    of service that our clients provide in a user-friendly
                    manner to reach its targeted audience. Our designing experts
                    are known to create visually pleasing and meaningful design
                    which keeps the customer engaged. We make sure our web
                    content covers all the information needed so that website
                    stands as a welcome door for targeted customers. We create
                    responsive websites that rearranges itself in the tablet and
                    mobile generation.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="row" id="servicesHome">
                  <div className="col-lg-4 my-2">
                    <div className="flip-card" style={{ padding: "22px 0px" }}>
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets5.lottiefiles.com/packages/lf20_M4t9nS.json"
                            background="transparent"
                            speed="1"
                            style={{ width: "254px", height: "140px" }}
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light my-3">
                            <b>Coding</b>
                          </h4>
                          <hr style={{ background: "#fff" }} />

                          <h6 className="text-light">HTML 5</h6>
                          <h6 className="text-light">javaScript</h6>
                          <h6 className="text-light">Angular</h6>
                          <h6 className="text-light">Ionic</h6>
                          <h6 className="text-light">Android Studio</h6>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card" style={{ padding: "22px 0px" }}>
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets5.lottiefiles.com/datafiles/fab7172a9302d416bcdb8ac7e1c71123/data.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center webH4 text-light my-3">
                            <b>Advance Tech</b>
                          </h4>
                          <hr style={{ background: "#fff" }} />
                          <h6 className="text-light webH6">React Js</h6>
                          <h6 className="text-light webH6">Next Js</h6>
                          <h6 className="text-light webH6">React Native</h6>
                          <h6 className="text-light webH6">Expo</h6>
                          <h6 className="text-light webH6">Redux</h6>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card" style={{ padding: "22px 0px" }}>
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets7.lottiefiles.com/packages/lf20_hi95bvmx/WebdesignBg.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center webH4 text-light my-3">
                            <b>Builders</b>
                          </h4>
                          <hr style={{ background: "#fff" }} />
                          <h6 className="text-light webH6">Wordpress</h6>
                          <h6 className="text-light webH6">Shopify</h6>
                          <h6 className="text-light webH6">Megneto</h6>
                          <h6 className="text-light webH6">Opencart</h6>
                          <h6 className="text-light webH6">Strapi</h6>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <Portfort />

           
          </div>
        </div>
      </section>
    </>
  );
};

export default WebDevelop;
