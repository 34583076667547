import React from "react";

let MobileAPPF = () => {
  return (
    <>
      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Native Mobile App</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Native Mobile App Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Hybrid Mobile App</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Hybrid Mobile App Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Web Progressive App</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Web Progressive App Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Android / IOS App</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Android / IOS App App Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAPPF;
