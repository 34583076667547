import React from "react";

import cab from "../../img/cab.webp";
import logistic from "../../img/logistics.webp";
import lab from "../../img/lab.webp";
import job from "../../img/job.webp";
import monk from "../../img/monk.webp";
import traders from "../../img/traders.webp";

let MobilePort = () => {
  return (
    <>
      <div className="container my-5">
        <div className="title">
          <div className="glitch">Recent Projects</div>
        </div>

        <div className="row">
          <div className="col-lg-4 my-3" id="service1">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.an2cabs.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={cab} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">AN2 Cabs</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.an2logistics.app"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logistic} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">AN2 Logistics</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.an2labnew.app"
                target="_blank"
                rel="noreferrer"
              >
                <img src={lab} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">An2 Labs</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.hion.v2.hion6"
                target="_blank"
                rel="noreferrer"
              >
                <img src={job} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">HION Jobs</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.hionmonk.app"
                target="_blank"
                rel="noreferrer"
              >
                <img src={monk} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">HION Monk</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.PrashantRiceTraders.user.app"
                target="_blank"
                rel="noreferrer"
              >
                <img src={traders} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Prashant Rice Traders</h6>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobilePort;
