import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import artboarda from "../../img/a.webp";
import artboardb from "../../img/b.webp";
import artboardc from "../../img/c.webp";
import artboardd from "../../img/d.webp";
import artboarde from "../../img/e.webp";
import artboardf from "../../img/f.webp";
import logot from "../../img/logot.png";

let Portfolio = () => {
  return (
    <>
      <Router></Router>
      <section className="portFolio my-5">
        <div className="container">
          <div className="title">
            <h6 className="glitch">WEBXINFINITY PORTFOLIO</h6>
          </div>
          <div className="row" id="servicesHome">
            <div
              className="col-lg-4 col-md-4 col-sm 4 col-xs-4 my-3"
              id="service1"
            >
              <div className="overlayPort">
                <img src={artboarda} alt="Avatar" className="imagePort" />
                <div className="middlePort">
                  <div className="textPort">
                    <center>
                      <img src={logot} alt="" className="logoPort" />
                    </center>
                    <div className="porthead">Unilet Stores</div>
                  
                    <small className="portSmall">WEB, UI/UX/ CODING</small>
                    <center>
                      <Link to="/unilet_store" className="portLink">
                        MORE INFO
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="overlayPort">
                <img src={artboardb} alt="Avatar" className="imagePort" />
                <div className="middlePort">
                  <div className="textPort">
                    <center>
                      <img src={logot} alt="" className="logoPort" />
                    </center>
                    <div className="porthead">A One Steels</div>
                  
                    <small className="portSmall">WEB, UI/UX/ CODING</small>
                    <center>
                      <Link to="/aone_steel" className="portLink">
                        MORE INFO
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="overlayPort">
                <img src={artboardc} alt="Avatar" className="imagePort" />
                <div className="middlePort">
                  <div className="textPort">
                    <center>
                      <img src={logot} alt="" className="logoPort" />
                    </center>
                    <div className="porthead">AN2 Cabs</div>
                  
                    <small className="portSmall">WEB, UI/UX/ CODING</small>
                    <center>
                      <Link to="/an2_logistic" className="portLink">
                        MORE INFO
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="overlayPort">
                <img src={artboardd} alt="Avatar" className="imagePort" />
                <div className="middlePort">
                  <div className="textPort">
                    <center>
                      <img src={logot} alt="" className="logoPort" />
                    </center>
                    <div className="porthead">Rapido</div>
                  
                    <small className="portSmall">WEB, UI/UX/ CODING</small>
                    <center>
                      <Link to="/rapido" className="portLink">
                        MORE INFO
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="overlayPort">
                <img src={artboarde} alt="Avatar" className="imagePort" />
                <div className="middlePort">
                  <div className="textPort">
                    <center>
                      <img src={logot} alt="" className="logoPort" />
                    </center>
                    <div className="porthead">Dream 11</div>
                  
                    <small className="portSmall">WEB, UI/UX/ CODING</small>
                    <center>
                      <Link to="/dream11" className="portLink">
                        MORE INFO
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="overlayPort">
                <img src={artboardf} alt="Avatar" className="imagePort" />
                <div className="middlePort">
                  <div className="textPort">
                    <center>
                      <img src={logot} alt="" className="logoPort" />
                    </center>
                    <div className="porthead">Mama Earth</div>
                  
                    <small className="portSmall">WEB, UI/UX/ CODING</small>
                    <center className="">
                      <Link to="/mamaearth" className="portLink">
                        MORE INFO
                      </Link>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Portfolio;
