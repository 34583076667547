import React from 'react';
import aone1 from "../../../img/Projects/aone1.webp";
import aone from "../../../img/Projects/aone.webp";
import aone2 from "../../../img/Projects/aone2.webp";



let Aone= () =>{
    return(
        <>
        <section>
            <figure>
                <img src={aone} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            Aone Steel
                        </div>
                        <h5>A-one steels brand cherished after signing with Yash the Rock star.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>Overview:</h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>A-one gold steel promises to participate and contribute to the development of new India- Rashtra Nirman Mein Samarpit. Our motto is not only to flourish but also to expand and dominate the steel market. Continuous upgradations, an uncompromising attitude towards quality, and merchandising brilliance are the characteristics for which A-one gold steel is best recognized.</p>

                        <p className='paraPort'>After having made remarkable investments in different regions, we are now trying to reach out to customers in every corner of the world. A-one gold steel has impacted the lives of people across time and space, having an employee strength of more than 500,000 employees, which represent the trustworthy and authentic organization that we are. Trust and transparency form the basis of our business.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={aone1} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={aone2} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <p className='paraPort'>The sphere of A-one gold steel is devoid of any limitations- it is a world that is continually evolving, dynamic, and challenging, a world that welcomes and accepts continuous improvements, innovations, skills, sustainability, and a better standard of living. From the automobile you drive to your dwelling place; from the flyover, you traverse to the tools that you use in your daily life, we endeavor to manufacture and deliver products of unbeaten quality through our user-friendly, value-added solutions to make your life a better one. Constant enhancement of our services and products doubled with triumph in value-creating inventiveness for our customers has enabled us to serve global steel markets. All this has been possible because of our commitment and dedication to continuous improvements, through which we drive operational caliber in products, processes, and customers.</p>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Aone;