// import React from "react";
import { Helmet } from "react-helmet";
import PackageForm from "./PackageForm";
// import EcomPackagePrice from "./EcomPackagePrice";
import Landing1 from "../../img/Packages/Landingimg.png";
import { useTypewriter } from "react-simple-typewriter";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as React from "react";
import Earn from "../../img/Packages/Earn.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Deliver from "../../img/Packages/Deliver.png";
import Commission from "../../img/Packages/comminsion.png";
import Revenue from "../../img/Packages/Revenue.png"
import Store from "../../img/Packages/Store1.png"
import Order from "../../img/Packages/Order1.png"
import VerticalTabs from "./VerticalTab";
import ModuleStore from "../../img/Packages/Modulezone.png"
import Delivery from "../../img/Packages/Deliverablestransparent .png"
import Module from "../../img/Packages/Modulestransparent.png"
import CrossPlatform from "../../img/Packages/Crossplatform.png"

let MultiVendorPackage = () => {
  const headingStyle = {
    color: "white",
    fontSize: "38px",
    fontWeight: "bold",
    opacity: 0, // Initially make it invisible
    animation: "fadeIn 2s forwards", // Use fadeIn animation for 2 seconds
    '@media (max-width: 768px)': {
      fontSize: '22px', // Adjust font size for smaller screens
    },
  };

  const SubheadingStyle = {
    color: "white", 
    fontSize: "28px", 
    fontWeight: 600,
    opacity: 0, // Initially make it invisible
    animation: "fadeIn 2s forwards", // Use fadeIn animation for 2 seconds
    '@media (max-width: 768px)': {
      fontSize: '18px', // Adjust font size for smaller screens
    },
  };



  const [text] = useTypewriter({
    words: ["Webxinfinity"],
    loop: {},
    typeSpeed: 70,
    deleteSpeed: 50,
  });
  const [text1] = useTypewriter({
    words: ["Business Modules"],
    loop: {},
    typeSpeed: 70,
    deleteSpeed: 50,
  });

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Multi Vendor Package | Webxinfinity IT Solution, Bengaluru
        </title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section
        style={{
          backgroundColor: "black",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h1 style={headingStyle}>
            Welcome to <span style={{ color: "#8ed9d5" }}>Webxinfinity</span>{" "}
            <br /> Empowering Your Ecommerce Ventures!
          </h1>
          <br></br>

          <h1 style={SubheadingStyle}>
            Multi-Vendor Ecommerce Solution with
            <br></br> Diverse
            <span style={{ color: "#3361ae" }}> Business Modules </span>
          </h1>
          <br></br>
          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Explore the finest ecommerce solution that empowers you to
            efficiently<br></br>
            manage multiple businesses all in one place with Webxinfinity.
          </p>
          <br></br>
          <div
            className="Trend form-group col-12"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <a
              href="tel:7975207595">
              <button className="btn-contact" name="Pay Now">
                <span data-hover="Call Now" style={{ fontSize: "20px" }}>
                  Call Now
                </span>
              </button>
            </a>
            <a href="#aa">
              <button
                className="btn-contact"
                name="Pay Now"
                style={{ backgroundColor: "transparent" }}
              >
                <span data-hover="Demo" style={{ fontSize: "20px" }}>
                  Demo
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "black", marginTop: "-150px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img src={Landing1} width={1200} />
        </div>
      </section>

      <section
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "50px",
        }}
      >
        <div>
          <button
            style={{
              padding: "6px 14px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              fontSize: "18px",
              border: "1px solid #8ed9d5",
              color: "#8ed9d5",
            }}
          >
            What You'll Receive
          </button>

          <br></br>

          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
            }}
          >
            Unlock a Complete Suite for{" "}
            <span style={{ color: "#3361ae" }}>Streamlining</span> <br></br>Your{" "}
            <span style={{ color: "#3361ae" }}>Ecommerce Endeavors</span>
          </h1>

          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            With Webxinfinity, you'll gain access to critical panels, websites,
            and mobile applications <br></br>that are essential for running your
            ecommerce business seamlessly.
          </p>
          <div
            className="Trend form-group col-12"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              paddingTop: "20px",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    centered
                    sx={{
                      // Add margin between tabs
                      "& > .MuiTabs-scroller": {
                        "& > .MuiTabs-flexContainer": {
                          margin: "0px 16px", // Adjust the margin as needed
                        },
                      },
                    }}
                  >
                    <Tab
                      label="Deliverables"
                      value="1"
                      sx={{
                        fontFamily: "Josefin Sans",
                        textTransform: "none", // Preserve case
                        fontWeight: "bold",
                        fontSize: "16px", // Adjust the font size
                        // lineHeight: "18px", // Match the height of your buttons
                        minWidth: "unset", // Remove the default minimum width
                        padding: "10px 20px", // Adjust padding as needed
                        color: "#ffffff",
                        border: "1px solid #3361ae",
                        borderRadius: "100px",
                        marginRight: 2,
                        "&:hover": {},
                        "&.Mui-selected": {
                          backgroundColor: "#8ed9d5",
                          color: "#3361ae",
                          borderRadius: "0px",
                          border: "2px solid #8ed9d5",
                        },
                      }}
                    />
                    <Tab
                      label="Tailored Modules"
                      value="2"
                      sx={{
                        fontFamily: "Josefin Sans",
                        textTransform: "none", // Preserve case
                        fontWeight: "bold",
                        fontSize: "16px", // Adjust the font size
                        // lineHeight: "48px", // Match the height of your buttons
                        minWidth: "unset", // Remove the default minimum width
                        padding: "10px 16px", // Adjust padding as needed
                        color: "#ffffff",
                        border: "1px solid #3361ae",
                        borderRadius:'100px',
                        marginLeft: 2,

                        "&:hover": {
                          backgroundColor: "#yourHoverColor", // Add hover color
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#8ed9d5",
                          color: "#3361ae",
                          borderRadius: "0px",
                          border: "2px solid #8ed9d5",
                        },
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ fontFamily: "Josefin Sans" }}>
                  <img src={Delivery} width={1200} />
                </TabPanel>
                <TabPanel value="2" sx={{ fontFamily: "Josefin Sans" }}>
                  <img src={Module} width={1200} />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </section>

      <section
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "50px",
          backgroundColor: "black",
        }}
      >
        <div>
          <button
            style={{
              padding: "6px 14px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              fontSize: "18px",
              border: "1px solid #8ed9d5",
              color: "#8ed9d5",
            }}
          >
            Module + Zone + Store
          </button>

          <br></br>

          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
            }}
          >
            Establishing an Extensive{" "}
            <span style={{ color: "#3361ae" }}>Store Network</span> <br></br>
          </h1>

          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            We've strategically built a network of stores across various zones,
            making it effortless<br></br> for customers to locate their
            preferred stores within their vicinity.
          </p>
          <div
            className="Trend form-group col-12"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              paddingTop: "20px",
            }}
          >
            <img src={ModuleStore} width={1200} />
          </div>
        </div>
      </section>

      <section
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "80px",
        }}
      >
        <div>
          <button
            style={{
              padding: "6px 14px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              fontSize: "18px",
              border: "1px solid #8ed9d5",
              color: "#8ed9d5",
            }}
          >
            Monetization Strategies
          </button>

          <br></br>

          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
            }}
          >
            Discover How Webxinfinity{" "}
            <span style={{ color: "#3361ae" }}>Boosts Your Earnings</span>{" "}
            <br></br>
          </h1>

          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            As a business owner, you'll tap into multiple revenue streams from
            your ecommerce venture, including
          </p>
        </div>
      </section>
      <section className="Trend" style={{ alignItems: "center", paddingBottom:'50px' }}>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
              <div className="contenty">
                {/* <h5 style={{ color: "#00fffc" }}>NEXT GENERATION</h5>
                <br />
                <h1 className="trendHead">
                  Why Follow Trends When You Can Set Your Own
                </h1> */}
                <br />

                <List sx={{ width: "100%", maxWidth: 600 }}>
                  <ListItem alignItems="flex-start" sx={{fontFamily:'Josefin Sans'}}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={Commission}
                      />
                    </ListItemAvatar>
                    
                    <ListItemText
                      primary=""
                      sx={{ color: "white", fontFamily: "Josefin Sans" }}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              fontFamily: "Josefin Sans",
                              fontSize:'25px'
                            }}
                            component="h1"
                            variant="body1"
                            color="#FFFFFF"
                          >
                            Order-wise Commissions
                          </Typography><br></br>
                          <Typography
                            sx={{
                              
                              fontFamily: "Josefin Sans",
                              fontSize:'18px'
                            }}
                            component="span"
                            variant="body2"
                            color="#FFFFFFB0"
                          >
                            From all Store’s Sales
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start" sx={{fontFamily:'Josefin Sans'}}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={Deliver}
                      />
                    </ListItemAvatar>
                    
                    <ListItemText
                      primary=""
                      sx={{ color: "white", fontFamily: "Josefin Sans" }}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              fontFamily: "Josefin Sans",
                              fontSize:'25px',
                            }}
                            component="h1"
                            variant="body1"
                            color="#FFFFFF"
                          >
                            Delivery Commissions
                          </Typography><br></br>
                          <Typography
                            sx={{
                              
                              fontFamily: "Josefin Sans",fontSize:'18px'
                            }}
                            component="span"
                            variant="body2"
                            color="#FFFFFFB0"
                          >
                            From Freelance Deliverymen
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start" sx={{fontFamily:'Josefin Sans'}}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={Revenue}
                      />
                    </ListItemAvatar>
                    
                    <ListItemText
                      primary=""
                      sx={{ color: "white", fontFamily: "Josefin Sans" }}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{
                              display: "inline",
                              fontFamily: "Josefin Sans",
                              fontSize:'25px',
                            }}
                            component="h1"
                            variant="body1"
                            color="#FFFFFF"
                          >
                            Direct Revenue
                          </Typography><br></br>
                          <Typography
                            sx={{
                              
                              fontFamily: "Josefin Sans",
                              fontSize:'18px',
                            }}
                            component="span"
                            variant="body2"
                            color="#FFFFFFB0"
                          >
                            From the Parcel Module
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5" id="col5">
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                {/* <lottie-player
                  id="lottieF"
                  src={Landing1}
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player> */}
                <img src={Earn} />
              </figure>
            </div>
          </div>
          <center>
            {/* <Link to="/about_us" className="btn-contact mt-5">
              <span data-hover="EXPLORE WEBXINFINITY">
                EXPLORE WEBXINFINITY
              </span>
            </Link> */}
          </center>
        </div>
      </section>


      <section
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "50px",
          backgroundColor:'black'
          
        }}
      >
        <div>
          <button
            style={{
              padding: "6px 14px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              fontSize: "18px",
              border: "1px solid #8ed9d5",
              color: "#8ed9d5",
            }}
          >
            Order Confirmation Model
          </button>

          <br></br>

          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
            }}
          >
            Leave No Orders to {" "}
            <span style={{ color: "#3361ae" }}> get Missed</span> <br></br>
          </h1>

          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Webxinfinity has two proven order confirmation & processing models, so there’s no chance for<br></br> an order to get missed. 
            But if nobody responds, the admin can forcefully assign a deliveryman to confirm delivery.
          </p>
          <div
            className="Trend form-group col-12"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              paddingTop: "20px",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    centered
                    sx={{
                      // Add margin between tabs
                      "& > .MuiTabs-scroller": {
                        "& > .MuiTabs-flexContainer": {
                          margin: "0px 16px", // Adjust the margin as needed
                        },
                      },
                    }}
                  >
                    <Tab
                      label="Store Confirmation"
                      value="1"
                      sx={{
                        fontFamily: "Josefin Sans",
                        textTransform: "none", // Preserve case
                        fontWeight: "bold",
                        fontSize: "16px", // Adjust the font size
                        // lineHeight: "18px", // Match the height of your buttons
                        minWidth: "unset", // Remove the default minimum width
                        padding: "10px 20px", // Adjust padding as needed
                        color: "#ffffff",
                        border: "1px solid #3361ae",
                        borderRadius: "100px",
                        marginRight: 2,
                        "&:hover": {},
                        "&.Mui-selected": {
                          backgroundColor: "#8ed9d5",
                          color: "#3361ae",
                          borderRadius: "0px",
                          border: "2px solid #8ed9d5",
                        },
                      }}
                    />
                    <Tab
                      label="Deliveryman Confirmation"
                      value="2"
                      sx={{
                        fontFamily: "Josefin Sans",
                        textTransform: "none", // Preserve case
                        fontWeight: "bold",
                        fontSize: "16px", // Adjust the font size
                        // lineHeight: "48px", // Match the height of your buttons
                        minWidth: "unset", // Remove the default minimum width
                        padding: "10px 16px", // Adjust padding as needed
                        color: "#ffffff",
                        border: "1px solid #3361ae",
                        borderRadius:'100px',
                        marginLeft: 2,

                        "&:hover": {
                          backgroundColor: "#yourHoverColor", // Add hover color
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#8ed9d5",
                          color: "#3361ae",
                          borderRadius: "0px",
                          border: "2px solid #8ed9d5",
                        },
                      }}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ fontFamily: "Josefin Sans" }}>
                  <img src={Store} width={800} />
                </TabPanel>
                <TabPanel value="2" sx={{ fontFamily: "Josefin Sans" }}>
                  <img src={Order} width={700} />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </section>

      {/* <EcomPackagePrice /> */}
      <VerticalTabs />
      <section>
        
      </section>

      {/* Form Below */}
      

      {/* Form End */}
      <section>
        <div className="project-detail2">
          <div className="container">
           

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <div className="title">
            <button
            style={{
              padding: "6px 14px",
              backgroundColor: "transparent",
              borderRadius: "5px",
              fontSize: "18px",
              border: "1px solid #8ed9d5",
              color: "#8ed9d5",
            }}
          >
            Technologies & Platforms
          </button>

          <br></br>

          <h1
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: "50px",
              fontSize: "38px",
              fontWeight: "bold",
            }}
          >
            eCommerce Scripts For  {" "}
            <span style={{ color: "#3361ae" }}> Cross-Platform Excellence</span> <br></br>
          </h1>

          <p
            style={{
              color: "#FFFFFFB3",
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            Get verified eCommerce scripts with multiple payment and SMS gateway integration facilities.
          </p>
            </div>
            <section style={{ backgroundColor: "black",  }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img src={CrossPlatform} width={1200} />
        </div>
      </section>
            
            <PackageForm />
            {/* <Portfort /> */}

            {/* <center>
              <h2
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingTop: "30px",
                  fontSize: "25px",
                }}
              >
                Special Offer Only for Above Privileged Customer (Till Your With
                Us)<br></br>
                <span style={{ color: "greenyellow", fontWeight: "bold" }}>
                  FREE - FREE - FREE
                </span>{" "}
                Worth{" "}
                <span style={{ color: "red" }}>
                  <b>₹2,00,000</b>
                </span>
                /Month -{" "}
                <span style={{ color: "red" }}>
                  <b>₹24,00,000</b>
                </span>
                /Year <br></br>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  ₹20,000{" "}
                </span>
                One time Google ads Credit Cashback +{" "}
                <span style={{ color: "greenyellow", fontWeight: "bold" }}>
                  Many More...
                </span>
              </h2>
              <br />

              <div className="title">
                <h6 className="glitch">OUR OFFER PRICE - ₹55,000 /- Only</h6>
              </div>
              <div className="Trend form-group col-12">
                <a
                  href="https://team.webxinfinity.com/products/client"
                  target="_blank"
                >
                  <button className="btn-contact" name="Pay Now">
                    <span data-hover="PAY-NOW">PAY NOW</span>
                  </button>
                </a>
              </div>
            </center> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default MultiVendorPackage;
