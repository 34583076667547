import React from "react";
import { Helmet } from "react-helmet";
import Form from "../Content/Form";
import contact from "../../img/ContactUs.webp";

let SayHello = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Say Hello | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>
      
      <section>
        <figure>
          <img src={contact} style={{ width: "100%" }} alt="fiver" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <h1 className="HeadHello">
              We’d love to hear from you. <br /> Let’s talk about it!
            </h1>
            <div className="row">
              <div className="col-lg-4 my-2">
                <div className="cardHello">
                  <center>
                    <h6>
                      <i className="fa-solid fa-phone fa-2x text-light"></i>
                    </h6>
                    <hr style={{ background: "#fff" }} />
                    <br />
                    <a href="tel:+917975456795" id="sayHello">
                      <h6>+91 79754 56795</h6>
                    </a>
                    <a href="tel:+917975207595" id="sayHello">
                      <h6>+91 79752 07595</h6>
                    </a>
                  </center>
                </div>
              </div>
              <div className="col-lg-4 my-2">
                <div className="cardHello">
                  <center>
                    <h6>
                      <i className="fa-solid fa-envelope fa-2x text-light"></i>
                    </h6>
                    <hr style={{ background: "#fff" }} />
                    <br />
                    <a href="mailto:sales@webxinfinity.com" id="sayHello">
                      <h6>sales@webxinfinity.com</h6>
                    </a>
                    <a href="mailto:saleshead@webxinfinity.com" id="sayHello">
                      <h6>saleshead@webxinfinity.com</h6>
                    </a>
                  </center>
                </div>
              </div>
              <div className="col-lg-4 my-2">
                <div className="cardHello">
                  <center>
                    <h6>
                      <i className="fa-solid fa-map-location-dot fa-2x text-light"></i>
                    </h6>
                    <hr style={{ background: "#fff" }} />
                    <br />
                    <a
                      href="https://goo.gl/maps/oaynJ53XxdgAYdan9"
                      rel="noreferrer"
                      target="_blank"
                      id="sayHello"
                    >
                      <h6>
                        25/3, 3rd Main, Gokula,
                        <br />
                        HMT Main Rd, 1st Stage,
                        <br />
                        Divanarapalya, Yeswanthpur,
                        <br />
                        Bengaluru, Karnataka 560021
                      </h6>
                    </a>
                  </center>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <Form />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SayHello;
