import React from "react";

let Whywebx = () => {
  return (
    <>
      <section className="whyWebx my-5">
        <div className="container">
          <div className="title">
            <h6 className="glitch">WHY WEBXINFINITY ?</h6>
          </div>
          <div className="row" id="servicesHome">
            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
              id="service1"
            >
              <div className="flip-card cardTeam-1" id="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <lottie-player
                      src="https://assets10.lottiefiles.com/private_files/lf30_u4mgmpw4.json"
                      background="transparent"
                      speed="1"
                      width={200}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                  <div className="flip-card-back">
                    <h3 className="textWhy">
                      <strong>QUALITY ASSURED</strong>
                    </h3>
                    <hr style={{ background: "#fff" }} />
                    <p className="textWhyP">
                      We know the importance of a good quality product for our
                      customers. We make sure the quality of service we provide
                      is up to date with market trends and is of the best
                      quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
              id="service1"
            >
              <div className="flip-card cardTeam-1" id="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <lottie-player
                      src="https://assets9.lottiefiles.com/packages/lf20_fjv8qxqn.json"
                      background="transparent"
                      speed="1"
                      width={200}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                  <div className="flip-card-back">
                    <h3 className="textWhy">
                      <strong>One-One Relation</strong>
                    </h3>
                    <hr style={{ background: "#fff" }} />
                    <p className="textWhyP">
                      Relationships with our clients are of the utmost value for
                      us and we are open to get in touch throughout the process
                      and have one to one interactions regularly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
              id="service1"
            >
              <div className="flip-card cardTeam-1" id="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <lottie-player
                      src="https://assets10.lottiefiles.com/packages/lf20_f6jlrng0.json"
                      background="transparent"
                      speed="1"
                      width={200}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                  <div className="flip-card-back">
                    <h3 className="textWhy">
                      <strong>We think out of the box</strong>
                    </h3>
                    <hr style={{ background: "#fff" }} />
                    <p className="textWhyP">
                      Being innovative with our service is always what we aim
                      for. Innovation is what excites us for taking up every new
                      project. We make the best unique solutions possible with
                      us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
              id="service1"
            >
              <div className="flip-card cardTeam-1" id="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <lottie-player
                      src="https://assets6.lottiefiles.com/packages/lf20_ncztkceu.json"
                      background="transparent"
                      speed="1"
                      width={200}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                  <div className="flip-card-back">
                    <h3 className="textWhy">
                      <strong>Best Help Line</strong>
                    </h3>
                    <hr style={{ background: "#fff" }} />
                    <p className="textWhyP">
                      We are constantly available 24*7 for any kind of support.
                      We are open at any instant and have quick queries always.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Whywebx;
