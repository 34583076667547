import React from "react";

import malnad from "../../img/offerPage/Malnad.webp";
import structowell from "../../img/offerPage/structowell.webp";
import aerolex from "../../img/offerPage/aerolex.webp";
import itech from "../../img/offerPage/itech.webp";
import asty from "../../img/offerPage/asty.webp";
import hedgekart from "../../img/offerPage/hedgekart.webp";
import knotsandkrafts from "../../img/offerPage/knotsandkrafts.webp";
import Unilet from "../../img/offerPage/Unilet.webp";
import Crossweave from "../../img/offerPage/Crossweave.webp";
import radixcap from "../../img/offerPage/radixcap.webp";
import geeta from "../../img/offerPage/geeta.webp";
import tatoo from "../../img/offerPage/tatoo.webp";
import crypto from "../../img/offerPage/crypto.webp";
import trayag from "../../img/offerPage/trayag.webp";
import schoolspe from "../../img/offerPage/schoolspe.webp";

let Portfort = () => {
  return (
    <>
      <div className="container my-5">
        <div className="title">
          <div className="glitch">Recent Projects</div>
        </div>

        <div className="row">
          <div className="col-lg-12 mt-5">
            <center>
              <small style={{ color: "#fff" }}>E-Commerce Projects</small>
            </center>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://uniletstores.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Unilet} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Unilet Stores</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://www.knotsandkrafts.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={knotsandkrafts} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Knots & Krafts</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div className="cardPrice">
              <a
                href="https://thecrossweave.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Crossweave} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3" id="service1">The Crossweave</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-12 mt-5">
            <center>
              <small style={{ color: "#fff" }}>Dynamic Projects</small>
            </center>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://radixcap.in/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={radixcap} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Radixcap</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://inkkingtattooz.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tatoo} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Ink King Tattoo</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://trayag.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={trayag} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Trayag Studio</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://geetagroup.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={geeta} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Geeta Group</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://cryptosquare.in/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={crypto} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Crypto Square</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://schoolspe.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={schoolspe} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Schoolspe</h6>
              </center>
            </div>
          </div>

          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://malnadcontrols.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={malnad} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Malnad Controls</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://structowell.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={structowell} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Structowell</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="http://www.aerolexcables.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={aerolex} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Aerolex Cables</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://researchitech.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={itech} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Research iTech</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://astyrepalace.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={asty} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Astyre Palace</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="http://hedgekart.in/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={hedgekart} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Hedgekart</h6>
              </center>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfort;
