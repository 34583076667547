import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

let Trend = () => {
  return (
    <>
      <Router></Router>
      <section className="Trend">
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
              <div className="contenty">
                <h5 style={{ color: "#00fffc" }}>NEXT GENERATION</h5>
                <br />
                <h1 className="trendHead">
                  Why Follow Trends When You Can Set Your Own
                </h1>
                <br />
                <p
                  style={{
                    color: "#fff",
                    letterSpacing: "1px",
                    lineHeight: "26px",
                    fontSize: "17px",
                  }}
                >
                  At Webxinfinity, we believe in nurturing thoughts and
                  actualizing them in front of your eyes. We stay by your side
                  and work with you right from the stage of conception until
                  completion. We inspire brands to be better and break free from
                  the norm of traditional marketing and branding strategies. We
                  don’t think out of the box, we toss the box aside and create
                  new norms and trends that work in favour of our clients
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5" id="col5">
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <lottie-player
                  id="lottieF"
                  src="https://assets4.lottiefiles.com/packages/lf20_ca8zbrdk.json"
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </figure>
            </div>
          </div>
          <center>
            <Link to="/about_us" className="btn-contact mt-5">
              <span data-hover="EXPLORE WEBXINFINITY">
                EXPLORE WEBXINFINITY
              </span>
            </Link>
          </center>
        </div>
      </section>
    </>
  );
};

export default Trend;
