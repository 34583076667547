import React from "react";

let MobilePrize = () => {
  return (
    <>
      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="marquee">
                  <big>
                    {" "}
                    <b>
                      Our Offer Price for Mobile App Development starts From
                      9,999 /-*
                    </b>
                  </big>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2" id="service1">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Native Mobile App</h6>
                    <br />

                    <br />
                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Native%20Mobile%20App%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2" id="service1">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Hybrid Mobile App</h6>
                    <br />

                    <br />
                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Hybrid%20Mobile%20App%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2" id="service1">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Web Progressive App</h6>
                    <br />

                    <br />
                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Web%20Progressive%20App%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2" id="service1">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Android / IOS App</h6>
                    <br />
                    <br />

                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20a%20Android / IOS App%20Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobilePrize;
