import React from "react";
import { Helmet } from "react-helmet";

import adver from "../../img/advertise.webp";

import FormServ from "./FormServ";

let Advertise = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Advertising | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={adver} alt="advertisement" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">ADVERTISING</div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    We offer to attract the right audience to your business in
                    the competitive market. We have best solutions for your
                    business which increases your digital footprint with pay per
                    click campaigns, AdWords etc. We plan a strategy which uses
                    keywords and other suitable methods of advertising for your
                    business. We make it easy for you to reach your customers
                    without any hazzle which helps you run your business to the
                    best.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="row" id="servicesHome">
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets7.lottiefiles.com/packages/lf20_pms7gseb.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light webH5 my-3">
                            Physical Promotions
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets2.lottiefiles.com/packages/lf20_cx6nva8c.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light webH5 my-3">
                            Digital Promotions
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2">
                    <div className="flip-card">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets6.lottiefiles.com/packages/lf20_jmnIqC.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light my-3">
                            Influencer Market
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <FormServ />
          </div>
        </div>
      </section>
    </>
  );
};

export default Advertise;
