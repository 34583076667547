import React from "react";

let CustPrize = () => {
  return (
    <>
      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3 my-2" id="service1">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Custom Website Development</h6>
                    <br />
                    <h6 className="MFirst">Starting From</h6>
                    <br />
                    <h6 className="RFirst">
                      <b>29,999/-</b>
                    </h6>
                    <br />
                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Custom Website Development  Services ,Get back to us Asap..">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3 my-2" id="service1">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Custom Mobile App Development</h6>
                    <br />
                    <h6 className="MFirst">Starting From</h6>
                    <br />
                    <h6 className="RFirst">
                      <b>39,999/-</b>
                    </h6>
                    <br />
                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Custom Mobile App Development  Services ,Get back to us Asap..">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3 my-2">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">
                     Enterprise Software Development
                    </h6>
                    <br />
                    <h6 className="MFirst">Starting From</h6>
                    <br />
                    <h6 className="RFirst">
                      <b>39,999/-</b>
                    </h6>
                    <br />
                    <div className="d-flex">
                            <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Enterprise Software Development  Services ,Get back to us Asap..">
                              <div className="btnWAPP"><i className="fa-brands fa-whatsapp"></i></div> 
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="#aa">
                              <button className="btnQuote">Get Quote</button>
                            </a>
                          </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CustPrize;
