import React from 'react';
import iksha from "../../../img/Projects/iksha.webp";
import iksha1 from "../../../img/Projects/iksha1.webp";
import iksha2 from "../../../img/Projects/iksha2.webp";
import iksha3 from "../../../img/Projects/iksha3.webp";

let Iksha = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={iksha} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            iksha silks
                        </div>
                        <h5>Iksha Silks is influenced by the power of lavish creations. Located in the bustling hub of Coimbatore, Tamil Nadu, India; Iksha is identified in the market for its unmatched selection of apparel and price. Iksha Silks is an incarnation of richness, consistency and purity.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>About Cilent </h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>Iksha Silks is influenced by the power of lavish creations. Located in the bustling hub of Coimbatore, Tamil Nadu, India; Iksha is identified in the market for its unmatched selection of apparel and price. Iksha Silks is an incarnation of richness, consistency and purity. It’s the brainchild of Kalpesh Rathod & Karan Rathod. They’ve been following their ancestor’s footsteps for over 10 decades, and are now expanding with a lot more varieties for collections just for you!</p>

                        <p className='paraPort'>Our forte lies in handloom silk sarees. These sarees are made using the finest quality silk and are weaved to perfection. Our collection boasts of exquisite patterns, solid colors, pastels, embellishments, motifs, prints and styles.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={iksha1} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={iksha2} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <br />
                        <h2 className='textHead2'>Service</h2>
                        <p className="paraPort">At Iksha, every product is inspired by you and created for you. We don’t leave any stone unturned to please your quotient style. With emerging ideas and design trends, our team of designers is making the best designs for modern Indian women.</p>

                        <div className="row">
                            <div className="col-12 my-3">
                                <img src={iksha3} style={{width:"100%"}} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Iksha;