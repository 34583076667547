import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

let Prize = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onOpenModal1 = () => setOpen1(true);
  const onCloseModal1 = () => setOpen1(false);

  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const onOpenModal3 = () => setOpen3(true);
  const onCloseModal3 = () => setOpen3(false);

  const onOpenModal4 = () => setOpen4(true);
  const onCloseModal4 = () => setOpen4(false);

  const onOpenModal5 = () => setOpen5(true);
  const onCloseModal5 = () => setOpen5(false);

  const onOpenModal6 = () => setOpen6(true);
  const onCloseModal6 = () => setOpen6(false);

  const onOpenModal7 = () => setOpen7(true);
  const onCloseModal7 = () => setOpen7(false);

  const onOpenModal8 = () => setOpen8(true);
  const onCloseModal8 = () => setOpen8(false);

  return (
    <>
      <div className="project-detail">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ paddingTop: "32px" }}>
                  <h6 className="pFirst">Basic</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">2,999/- + Domain</h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal}>
                      VIEW MORE
                    </button>
                    <Modal open={open} onClose={onCloseModal} center>
                      <h4>
                        <strong>Basic</strong>
                      </h4>
                      <hr />
                      <p>- Free Domain</p>
                      <p>- Free SSL Certificate</p>
                      <p>- Delivery: 24-48 Days</p>
                      <p>- Dynamic Website - CMS</p>
                      <p>
                        - <b>Admin Panel</b>
                      </p>
                      <p>- Home Page</p>
                      <p>- Profile Page</p>
                      <p>
                        - <b>Upto 5 Sub Pages</b>
                      </p>
                      
                      <p>
                        - <b>Services or Product Detail Page</b>
                      </p>
                      <p>- Testimonials or Events Page</p>
                      <p>- Dynamic Gallery/Portfolio</p>
                      <p>- Enquiry or Contact Form</p>
                      <p>
                        - <b>Readymade Themes</b>
                      </p>
                      <p>
                        - <b>SEO Friendly Website</b>
                      </p>
                      <p>- Live Chat or Whatsapp Button</p>
                      <p>- Call to Action Button</p>
                      <p>- Social Media Integration</p>
                      <p>- Facebook, Instagram, Linkedin</p>
                      <p>- Free-Hosting*</p>

                      <hr style={{ background: "#fff" }} />

                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello%20Team%2C%20I%20am%20looking%20for%20 a 5 Page Dynamic Website Development%20Services%20%2CGet%20back%20to%20us%20Asap.">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 5 Page Dynamic Website Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ paddingTop: "32px" }}>
                  <h6 className="pFirst">Standard</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">9,999/- + Domain</h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal1}>
                      VIEW MORE
                    </button>
                    <Modal open={open1} onClose={onCloseModal1} center>
                      <h4>
                        <strong>Standard </strong>
                      </h4>
                      <hr />
                      <p>- Free Domain</p>
                      <p>- Free SSL Certificate</p>
                      <p>- Delivery: 24-48 Days</p>
                      <p>- Dynamic Website - CMS</p>
                      <p>
                        - <b>Admin Panel</b>
                      </p>
                      <p>- Home Page</p>
                      <p>- Profile Page</p>
                      <p>
                        - <b>15 Sub Pages</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Listing</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Detail Page</b>
                      </p>
                      <p>- Testimonials or Events Page</p>
                      <p>- Dynamic Gallery/Portfolio</p>
                      <p>- Enquiry or Contact Form</p>
                      <p>- Video Page</p>
                      <p>- Free Business Email Accounts</p>
                      <p>
                        - <b>Unlimited FREE Hosting</b>
                      </p>
                      <p>
                        - <b>Readymade Themes</b>
                      </p>
                      <p>
                        - <b>SEO Friendly Website</b>
                      </p>
                      <p>- Live Chat or Whatsapp Button</p>
                      <p>- Call to Action Button</p>
                      <p>- Social Media Integration</p>
                      <p>- Facebook, Instagram, Linkedin</p>
                      <p>- Free-Hosting*</p>

                      <hr style={{ background: "#fff" }} />

                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 10 Page Dynamic Website Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 10 Page Dynamic Website Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ paddingTop: "32px" }}>
                  <h6 className="pFirst">Advanced</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">14,999/- + Domain</h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal2}>
                      VIEW MORE
                    </button>
                    <Modal open={open2} onClose={onCloseModal2} center>
                      <h4>
                        <strong>Advanced</strong>
                      </h4>
                      <hr />
                      <p>- Free Domain</p>
                      <p>- Free SSL Certificate</p>
                      <p>- Delivery: 24-48 Days</p>
                      <p>- Dynamic Website - CMS</p>
                      <p>
                        - <b>Admin Panel</b>
                      </p>
                      <p>- Home Page</p>
                      <p>- Profile Page</p>
                      <p>
                        - <b>Unlimited Pages</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Listing</b>
                      </p>
                      <p>
                        - <b>Unlimited Services or Product Detail Page</b>
                      </p>
                      <p>- Testimonials or Events Page</p>
                      <p>- Dynamic Gallery/Portfolio</p>
                      <p>- Enquiry or Contact Form</p>
                      <p>- Video Page</p>
                      <p>- Contact Form</p>
                      <p>- Free Business Email Accounts</p>
                      <p>
                        - <b>Unlimited FREE Hosting</b>
                      </p>
                      <p>
                        - <b>Readymade Themes</b>
                      </p>
                      <p>
                        - <b>SEO Friendly Website</b>
                      </p>
                      <p>- Live Chat or Whatsapp Button</p>
                      <p>- Call to Action Button</p>
                      <p>- Social Media Integration</p>
                      <p>- Facebook, Instagram, Linkedin</p>
                      <p>- Free-Hosting*</p>
                      <p>
                        <b>- HOME PAGE CUSTOM DESIGN</b>
                      </p>
                      <p>
                        <b>- BLOG</b>
                      </p>
                      <p>- Project Manager Assigned</p>

                      <hr style={{ background: "#fff" }} />

                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 20 Page Dynamic Website Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a 20 Page Dynamic Website Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">E-commerce</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">
                    <b>9,999/-</b>
                  </h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal3}>
                      VIEW MORE
                    </button>
                    <Modal open={open3} onClose={onCloseModal3} center>
                      <h4>
                        <strong>E-Commerce Website</strong>
                      </h4>
                      <hr />
                      <p>- Limited Products</p>
                      <p>- Limited Categories</p>
                      <p>- Limited Attributes</p>
                      <p>- Limited Varients</p>
                      <p>
                        - Net Banking, UPI, Mobile Banking, Debit Card, Credit
                        Card, Payment Integration
                      </p>
                      <p>- Tracking of order SMTP Integration</p>
                      <p>
                        - One innovative standard home page development with one
                        about us and one contact us
                      </p>
                      <p>
                        - Mobile Responsive and Retina Clear upto 10 devices
                      </p>
                      <p>- Form Submission</p>
                      <p>- Hero Sliders – 4</p>
                      <p>- Header and Footer.</p>
                      <p>- Whatsapp Integration (WB).</p>
                      <p>
                        - Social Media Integration (Facebook, Instagram,
                        Twitter, LinkedIn).
                      </p>
                      <p>- Call and Email Integration.</p>
                      <p>- Testimonials Integration</p>

                      <hr style={{ background: "#fff" }} />
                      <b>Deployments:</b>
                      <br />
                      <br />
                      <p>- 4N metric hosting – SSD</p>
                      <p>- Unlimited hosting for our 1 website</p>
                      <p>- Unlimited Bandwidth</p>
                      <p>- SSL Certificate + Integration</p>
                      <p>
                        - 1 Sub domain provided, Server side Encryption, Website
                        Weekly backup
                      </p>
                      <p>- 100% Uptime</p>
                      <p>- High Performance</p>
                      <p>- True Stability</p>
                      <p>- EU, US, UK, IND Data Center</p>
                      <hr />
                      <h6>Terms & Conditions Apply</h6>
                      <br />

                      <div className="row mb-5">
                        <div className="col-lg-12 my-2">
                          <div className="cardPrice">
                            <center>
                              <h3 className="modalh3">
                                Single Vendor Ecommerce Web App
                              </h3>
                              <hr style={{ background: "#fff" }} />

                              <Accordion>
                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      Technical Specifications
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <p>- Front End : HTML, CSS, BOOTSTRAP</p>
                                    <p>
                                      - Back End : PHP 7.4, LARAVEL FRAMEWORK
                                    </p>
                                    <p>- Database : MySql</p>
                                  </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      Delivery
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <p>
                                      Source Code, One time Deployment, Server
                                      Setup, Email Setup, Domain Mapping
                                    </p>
                                  </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      Feature List in Detail
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <p>
                                      Dynamic CMS Based Front Store Page –
                                      Completely Mobile Responsive – Advance
                                      Product – Search and Sorting Mechanism –
                                      Multi Theming Option – Multi Color Option
                                      – Multi Shipping Option – Multiple Payment
                                      Gateway integration Available – Dynamic
                                      Wallet System – Social Media Login and
                                      Sharing – Order Tracking System after
                                      Purchase
                                    </p>
                                  </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      Core Ecommerce Features
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <p>
                                      Smart Cart – Easy Checkout – Product
                                      Compare – Product Whishlist – Today Deal
                                      Product – Flash Deal Product – Featured
                                      Product – Dynamic Product – Suggestion
                                      Search – Customer Review – Product Video
                                      Post
                                    </p>
                                  </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      Feature Images to be Displayed In Website
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <p>
                                      Product Posting and Presentation Cart
                                      System Smart Notification International
                                      Std Seller Panel Advance Suggestional
                                      Search Powerful Admin Panel Advance
                                      Business Settings Manage Staff
                                    </p>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            </center>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-commerce Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-commerce Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">E-Commerce Plus</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">
                    <b>14,999/-</b>
                  </h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal4}>
                      VIEW MORE
                    </button>
                    <Modal open={open4} onClose={onCloseModal4} center>
                      <h4>
                        <strong>E-Commerce Plus</strong>
                      </h4>
                      <hr />
                      <p>
                        7 Professional and International Standard 5 Screens /
                        Sections
                      </p>
                      <p>Mobile Responsive and Retina Clear upto 10 devices</p>
                      <p>Unlimited Listing / Booking</p>
                      <p>
                        Professional and International Standard 5 Screens /
                        Sections.
                      </p>
                      <p>Mobile Responsive and Retina Celar upto 10 devices.</p>
                      <p>Form Submission.</p>
                      <p>Hero Sliders – 3.</p>
                      <p>Header and Footer.</p>
                      <p>Whatsapp Integration (WB).</p>
                      <p>
                        Social Media Integration (Facebook, Instagram, Twitter,
                        LinkedIn).
                      </p>
                      <p>Call and Email Integration.</p>
                      <p>Testimonials Integration</p>
                      <hr />
                      <h6>Terms & Conditions Apply</h6>
                      <br />

                      <div className="row mb-5">
                        <div className="col-lg-12 my-2">
                          <div className="cardPrice">
                            <center>
                              <h3 className="modalh3">
                                Multi Vendor Ecommerce Web App
                              </h3>
                            </center>
                            <Accordion>
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Technical Specifications
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>- Front End : HTML, CSS, BOOTSTRAP</p>
                                  <p>- Back End : PHP 7.4, LARAVEL FRAMEWORK</p>
                                  <p>- Database : MySql</p>
                                </AccordionItemPanel>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Delivery
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>
                                    Source Code, One time Deployment, Server
                                    Setup, Email Setup, Domain Mapping
                                  </p>
                                </AccordionItemPanel>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Feature List in Detail
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>
                                    Dynamic CMS Based Front Store Page –
                                    Completely Mobile Responsive – Advance
                                    Product – Search and Sorting Mechanism –
                                    Multi Theming Option – Multi Color Option –
                                    Multi Shipping Option – Multiple Payment
                                    Gateway integration Available – Dynamic
                                    Wallet System – Social Media Login and
                                    Sharing – Order Tracking System after
                                    Purchase
                                  </p>
                                </AccordionItemPanel>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Seller Panel Features
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>
                                    Dashboard – Purchase History – Download –
                                    Wishlist – Product Management – Bulk Product
                                    Upload – Digital Product – Classified
                                    Product – Order – Product Review –
                                    Commission – Payment History – Conversation
                                    – My wallet – Support Ticket – Manage
                                    Profile – Mini Report
                                  </p>
                                </AccordionItemPanel>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Core Ecommerce Features
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>
                                    Smart Cart – Easy Checkout – Product Compare
                                    – Product Whishlist – Today Deal Product –
                                    Flash Deal Product – Featured Product –
                                    Dynamic Product – Suggestion Search –
                                    Customer Review – Product Video Post
                                  </p>
                                </AccordionItemPanel>
                              </AccordionItem>

                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Feature Images to be Displayed In Website
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>
                                    Product Posting and Presentation Cart System
                                    Smart Notification International Std Seller
                                    Panel Advance Suggestional Search Powerful
                                    Admin Panel Advance Business Settings Manage
                                    Staff
                                  </p>
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-Commerce Plus Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-Commerce Plus Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3"
              id="service1"
            >
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">E-Commerce + App</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">
                    <b>19,999/-</b>
                  </h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal5}>
                      VIEW MORE
                    </button>
                    <Modal open={open5} onClose={onCloseModal5} center>
                      <h4>
                        <strong>E-Commerce + App</strong>
                      </h4>
                      <hr />
                      <p>Unlimited products</p>
                      <p>Unlimited categories</p>
                      <p>Unlimited attributes</p>
                      <p>Unlimited variants</p>
                      <p>Unlimited Vendors</p>
                      <p>
                        Net Banking, UPI, Mobile Banking, Debit Card, Credit
                        Card, Payment Integration
                      </p>
                      <p>Tracking of order SMTP Integration</p>
                      <p>
                        One innovative standard home page development with one
                        about us and one contact us
                      </p>
                      <p>Mobile Responsive and Retina Clear upto 10 devices</p>
                      <p>Coupon code creations</p>
                      <p>Dedicated Backend for admin to operate the order</p>
                      <p>Automated mail support</p>
                      <p>24/7 Chat</p>
                      <p>
                        Professional and International Standard 5 Screens /
                        Sections.
                      </p>
                      <p>Mobile Responsive and Retina Celar upto 10 devices.</p>
                      <p>Form Submission.</p>
                      <p>Hero Sliders – 3.</p>
                      <p>Header and Footer.</p>
                      <p>Whatsapp Integration (WB).</p>
                      <p>
                        Social Media Integration (Facebook, Instagram, Twitter,
                        LinkedIn).
                      </p>
                      <p>Call and Email Integration.</p>
                      <p>Testimonials Integration</p>
                      <hr />
                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-Commerce + App Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-Commerce + App Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3" id="service1">
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">LMS</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">
                    <b>19,999/-</b>
                  </h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal6}>
                      VIEW MORE
                    </button>
                    <Modal open={open6} onClose={onCloseModal6} center>
                    <h4>
                        <strong>LMS</strong>
                      </h4>
                      <hr />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a LMS  Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a LMS  Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3" id="service1">
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">Custom Coding</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">
                    <b>999/- hrs</b>
                  </h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal7}>
                      VIEW MORE
                    </button>
                    <Modal open={open7} onClose={onCloseModal7} center>
                      <h4>
                        <strong>Custom Coding</strong>
                      </h4>
                      <hr />
                      <p>Development through Preferred Languages like</p>
                      <p>Html 5</p>
                      <p>JavaScript</p>
                      <p>Angular</p>
                      <p>Ionic</p>
                      <p>Android Studio</p>
                      <p>Advance Tech</p>
                      <p>React Js</p>
                      <p>Next Js</p>
                      <p>React Native</p>
                      <p>Expo</p>
                      <p>Redux</p>
                      {/* <p>
                        7 Professional and International Standard 5 Screens /
                        Sections
                      </p> */}
                      <p>Mobile Responsive and Retina Clear up to 10 devices</p>
                      {/* <p>Advanced Live Chat Integration</p>
                      <p>Gallery integration</p>
                      <p>Premium Selection</p>
                      <p>
                        Professional and International Standard 5 Screens /
                        Sections.
                      </p> */}
                      {/* <p>Mobile Responsive and Retina Celar upto 10 devices.</p> */}
                      <p>Form Submission.</p>
                      {/* <p>Hero Sliders – 3.</p>
                      <p>Header and Footer.</p> */}
                      <p>Whatsapp Integration (WB).</p>
                      <p>
                        Social Media Integration (Facebook, Instagram, Twitter,
                        LinkedIn).
                      </p>
                      <p>Call and Email Integration.</p>
                      <p>Testimonials Integration</p>
                      <hr />
                      <h6>Terms & Conditions Apply</h6>
                      <br />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Custom Coding  Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Custom Coding  Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-3">
              <div className="cardPrice">
                <div className="text-center" style={{ marginTop: "22px" }}>
                  <h6 className="pFirst">College Listing</h6>
                  <br />
                  <h6 className="MFirst">Starting From</h6>
                  <br />
                  <h6 className="RFirst">
                    <b>19,999/-</b>
                  </h6>

                  <div className="wrap-collabsible">
                    <button id="modalBTN" onClick={onOpenModal8}>
                      VIEW MORE
                    </button>
                    <Modal open={open8} onClose={onCloseModal8} center>
                    <h4>
                        <strong>College Listing</strong>
                      </h4>
                      <hr />
                      <div className="d-flex">
                        <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a College Listing  Development Services ,Get back to us Asap..">
                          <div className="btnWAPP">
                            <i className="fa-brands fa-whatsapp"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="mailto:sales@webxinfinity.com">
                          <div className="btnMAIL">
                            <i className="fa-solid fa-envelope"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="tel:+917975207595">
                          <div className="btnCALL">
                            <i className="fa-solid fa-phone" id="fa-phone"></i>
                          </div>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a href="#aa">
                          <button className="btnQuote">Get Quote</button>
                        </a>
                      </div>
                    </Modal>
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a College Listing  Development Services ,Get back to us Asap..">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <center>
            <p className="textCONDI">We cater customized website, mobile application development in both iOS and Android for our customers. Our team invests in quality time on understanding the needs of a customer and accordingly plan a very well executed strategy for the application. Our developers and UI/ UX designers sit together and brainstorm the best plan to roll out for an application to be user-friendly and aesthetically pleasing. We have built many applications across platforms which has given us a very fruitful experience</p>
          </center>
        </div>
      </div>
    </>
  );
};

export default Prize;