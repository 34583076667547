import React from 'react';
import an21 from "../../../img/Projects/an21.webp";
import an2 from "../../../img/Projects/an2.webp";

let AN2 = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={an21} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            AN2 Logistics
                        </div>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>AN2 Logistic</h2>

                        <p className='paraPort'>AN2logistics Delivery is a App based delivery booking App. AN2logistics App Solutions(OPC) Pvt. Ltd. has designed it.</p>

                        <p className='paraPort'>We are at, AN2logistics aspire to become the top notch organisation who will shape the future of Mobile Applications. It comes with a Customer cum Delivery Agent App and an Admin Portal.</p>


                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Service Information</h2>
                        <div className="row">
                            <div className="col-lg-7">
                                <p className="paraPort">AN2logistics Delivery is best App-based delivery service in town.</p>

                                <br />
                                <h2 style={{color:"#fff", fontSize:"22px", fontWeight:"600"}}>Comfortable</h2>
                                <p className="paraPort">Your experience is important to us. We never compromise on our standards. Safety regulations are particularly emphasized to ensure peace of mind.</p>

                            </div>
                            <div className="col-lg-5 mt-4">
                                <img src={an2} alt="" />
                            </div>
                        </div>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"22px", fontWeight:"600"}}>Convenient</h2>
                                <p className="paraPort">View live delivery tracking, price estimations, and even book ahead of time! We have every avenue covered with every latest technology.</p>

                                <br />
                                <h2 style={{color:"#fff", fontSize:"22px", fontWeight:"600"}}>Secure</h2>
                                <p className="paraPort">Aside from cash, credit card, payments are PCI DSS compliant. The website has an SSL certification, and the apps are checked by the Apple Store and Google Play.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AN2;