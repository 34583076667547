import React from 'react';
import blog1 from "../../img/blog1.webp";
import blog2 from "../../img/blog2.webp";
import blog3 from "../../img/blog3.webp";
import hr from "../../img/hr.png";
import sense from "../../img/sense.png";
import tech from "../../img/tech.webp";


let BlogPost = () =>{
    return(
        <>
        <div className="container my-5">
            <div className="row">

                <div className="col-lg-8">
                    <div className="blogCard">
                        <div className="imgBlogCard">
                            <img className='blogPost' src={blog1} alt="" />
                        </div>
                        <span className='post-date'>27 May, 2022</span>
                        <div className="contentBlogCard">
                            <div className="post-author-avatar">
                                <img src={hr} className='avatar' alt="" />
                            </div>
                            <h3>9 Web app Development tips for your mobile project</h3>
                            <h5><i className="fa-solid fa-tag"></i> &nbsp; Subrahmanya Vasisht, Author</h5>

                            <p>Mobile app development is always changing. It can happen very quickly sometimes. Trends can change quickly, as shown in web application development. In our previous blogs, we have paid a lot of attention to native apps and will place web apps in the spotlight. We offer these 9 essentials...</p>
                        </div>
                    </div>

                    <div className="blogCard my-5">
                        <div className="imgBlogCard">
                            <img className='blogPost' src={blog2} alt="" />
                        </div>
                        <span className='post-date'>20 May, 2022</span>
                        <div className="contentBlogCard">
                            <div className="post-author-avatar">
                                <img src={hr} className='avatar' alt="" />
                            </div>
                            <h3>13 Benefits of Mobile Applications for Businesses to be Aware of</h3>
                            <h5><i className="fa-solid fa-tag"></i> &nbsp; Subrahmanya Vasisht, Author</h5>

                            <p>In the last decade, smartphone usage has increased all over the globe. At the close of 2020, there were around 3.5 billion users of smartphones around the world, and an estimate of 1.4 billion mobile phones was sold the year. In the end, there is an increase in demand for mobile applications...</p>
                        </div>
                    </div>

                    <div className="blogCard my-5">
                        <div className="imgBlogCard">
                            <img className='blogPost' src={blog3} alt="" />
                        </div>
                        <span className='post-date'>19 May, 2022</span>
                        <div className="contentBlogCard">
                            <div className="post-author-avatar">
                                <img src={hr} className='avatar' alt="" />
                            </div>
                            <h3>How Digital Marketing Works</h3>
                            <h5><i className="fa-solid fa-tag"></i> &nbsp; Subrahmanya Vasisht, Author</h5>

                            <p>In an era of the internet where the vast majority of transactions are done on the internet, companies all over the globe have made the leap from billboard advertisements to digital advertisements. Digital Marketing isn’t an emerging marketing tool. It’s the most efficient method of...</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="blogCard">
                        <div className="headCol4">About Us</div>

                        <div className="contentCol4">
                            <img src={tech} alt="" />

                            <p>Do you believe that your brand needs help from a creative team? Contact us to start working for your project!</p>

                            <a className='btn btn-style1' href="/about_us">Read More</a>
                        </div>
                    </div>

                    <div className="blogCard my-5">
                        <div className="headCol4">Banner ad</div>

                        <div className="contentCol4">
                            <div className="adCard">
                                <img src={sense} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        </>
    )
}
export default BlogPost;