import React from "react";

let VideoTestimonials = () => {
  return (
    <>
      <div className="container my-5">
        <center>
          <div id="testWork" className="owl-carousel">
            <div className="testWork">
              <div id="VideoTest">
                <div className="view">
                  <iframe
                    className="vidIO"
                    src="https://www.youtube.com/embed/w9YkJhxZqTQ"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="testWork">
              <div id="VideoTest">
                <div className="view">
                  <iframe
                    className="vidIO"
                    src="https://www.youtube.com/embed/mkTL9zJsUD4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="testWork">
              <div id="VideoTest">
                <div className="view">
                  <iframe
                    className="vidIO"
                    src="https://www.youtube.com/embed/C1sIH6X67hQ"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="testWork">
              <div id="VideoTest">
                <div className="view">
                  <iframe
                    className="vidIO"
                    src="https://www.youtube.com/embed/Az7nFaYAEZE"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </>
  );
};

export default VideoTestimonials;
