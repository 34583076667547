import React from "react";
import { Helmet } from "react-helmet";

import refund from "../../../img/refund.webp";

let Refund = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Refund Policy | Webxinfinity IT Solution, Bengaluru</title>
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={refund} alt="Refund Policy" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    The NO REFUND POLICY entails all projects/products/services
                    that are provided by Webxinﬁnity IT Solutions. We follow a
                    strict NO REFUND POLICY thus refunds cannot be claimed, the
                    company ensures to close upon the projects if it meets the
                    requirements as discussed during the initial stage of
                    product development or service being rendered. which you
                    acknowledge prior to purchasing any product with the
                    Company. Please make sure that you’ve carefully read the
                    service description before making a purchase.
                  </p>
                </div>
                <br />
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Contacting us
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      If you would like to contact us concerning any matter
                      relating to this Refund Policy, you may send an email to
                      sales@webxinﬁnity.com This document was last updated on
                      April 14, 2022.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Refund;
