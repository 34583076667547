import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import desk from "../../video/desk.mp4";
import mobile from "../../video/mobile.mp4";


let Video = () => {

  const [formData, setFormData] = useState({});
  const form = useRef();

  function sendEmail(e){
    e.preventDefault();

    if(form.current.reportValidity()){
      emailjs.send('service_eykatub', 'template_ey0g1gu', formData, 'uOTgiTbYT4eT7vb_3')
      .then((result) => {
          console.log(result.text);
          window.location.href= window.location.origin +"/thankyou"
      }, (error) => {
          console.log(error.text);
      });
    }
   
  };

  return (
    <>
      <div className="video-bg">
        <video src={desk} autoPlay muted loop id="videoD" alt="banner"></video>
        <center>
          <a href="#aa"><div className="Trend form-group col-12" id="bannerBtn">
            <button
              className="btn-contact"
            >
              <span data-hover="Talk to Expert Now">Talk to Expert Now</span>
            </button>
          </div></a>
        </center>
        

        <video src={mobile} autoPlay muted loop id="videoM" alt="mobile"></video>

        <div className="mobileBannerForm mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <form ref={form} className="row inner" id="contact" name="contact">
                  <div className="form-group col-12">
                    <label>
                      <span>Email Id</span>
                    </label>
                    <input
                      className="mb-1"
                      type="text"
                      name="email"
                      id="email"
                      required
                      onChange={(event) => {
                        setFormData({ ...formData, email: event.target.value });
                      }}
                    />
                  </div>

                  <div className="form-group col-12">
                    <label>
                      <span>Phone No.</span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="tel"
                      className="mb-1"
                      required
                      onChange={(event) => {
                        setFormData({ ...formData, phone: event.target.value });
                      }} />
                  </div>

                  <div className="Trend form-group col-12">
                    <button
                      onClick={(event) => sendEmail(event)}
                      className="btn-contact"
                      type="submit"
                      name="submit"
                    >
                      <span data-hover="Submit">Submit</span>
                    </button>
                  </div>
                </form>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
