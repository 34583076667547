import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";

let Form = () => {
  const [formData, setFormData] = useState({});
  const form = useRef();

  // function sendEmail(e) {
  //   e.preventDefault();

  //   emailjs
  //     .send(
  //       "service_eykatub",
  //       "template_ey0g1gu",
  //       formData,
  //       "uOTgiTbYT4eT7vb_3"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         window.location.href = window.location.origin + "/thankyou";
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // }

  function sendEmail(e){
    e.preventDefault();

    if(form.current.reportValidity()){
      emailjs.send('service_eykatub', 'template_ey0g1gu', formData, 'uOTgiTbYT4eT7vb_3')
      .then((result) => {
          console.log(result.text);
          window.location.href= window.location.origin +"/thankyou"
      }, (error) => {
          console.log(error.text);
      });
    }
   
  };

  return (
    <>
      <div className="container my-5" id="aa">
        <div className="title">
          <h6 className="glitch">GROW DIGITAL TODAY</h6>
        </div>
        <br />
        <div className="row justify-content-md-center">
          <div className="col-md-8 col-sm-12 col-12 wow fadeIn">
            <form ref={form} className="row inner" id="contact" name="contact">
              <div className="form-group col-12">
                <label>
                  <span>Name</span>
                </label>
                <input
                  className="mb-3"
                  type="text"
                  name="name"
                  id="name"
                  required
                  onChange={(event) => {
                    setFormData({ ...formData, name: event.target.value });
                  }}
                />
              </div>

              <div className="form-group col-sm-6 col-12">
                <label>
                  <span>Email Id</span>
                </label>
                <input
                  className="mb-3"
                  type="text"
                  name="email"
                  id="email"
                  required
                  onChange={(event) => {
                    setFormData({ ...formData, email: event.target.value });
                  }}
                />
              </div>

              <div className="form-group col-sm-6 col-12">
                <label>
                  <span>Phone No.</span>
                </label>
                <input
                  className="mb-3"
                  type="tel"
                  name="phone"
                  id="phone"
                  required
                  onChange={(event) => {
                    setFormData({ ...formData, phone: event.target.value });
                  }}
                />
              </div>

              <div className="form-group col-sm-6 col-12">
                <label htmlFor="checkbox">Requirement</label>
                <select
                  className="mb-3"
                  name="requirement"
                  id="requirement"
                  aria-label="Default select example"
                  required
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      requirement: event.target.value,
                    });
                  }}
                >
                  <option defaultValue>Please select your Requirement</option>
                  <option value="Website Requirement">
                    Website Requirement
                  </option>
                  <option value="Mobile App Developement">
                    Mobile App Developement
                  </option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="DM Services">DM Services</option>
                  <option value="Advertising & Production">
                    Advertising & Production
                  </option>
                </select>
              </div>

              <div className="form-group col-sm-6 col-12">
                <label htmlFor="form-select">Budget</label>
                <select
                  className="mb-3"
                  name="budget"
                  id="budget"
                  aria-label="Default select example"
                  required
                  onChange={(event) => {
                    setFormData({ ...formData, budget: event.target.value });
                  }}
                >
                  <option defaultValue>Please select your Budget</option>
                  <option value="20k - 50k">20k - 50k</option>
                  <option value="50k - 80k">50k - 80k</option>
                  <option value="80k - 100k">80k - 100k</option>
                  <option value="100k - 200k">100k - 200k</option>
                  <option value="200k & Above">200k & Above</option>
                </select>
              </div>

              <div className="Trend form-group col-12">
                <button
                  onClick={(event) => sendEmail(event)}
                  className="btn-contact"
                  type="submit"
                  name="submit"
                >
                  <span data-hover="SUBMIT">SUBMIT</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
