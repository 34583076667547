import React from "react";
import { Helmet } from "react-helmet";

import privacy from "../../../img/privacy.webp";

let Privacy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy & Policy | Webxinfinity IT Solution, Bengaluru</title>
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={privacy} alt="Privacy & Policy" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    This privacy policy (“Policy”) describes how the personally
                    identiﬁable information (“Personal Information”) you may
                    provide on the webxinfinity.com website (“Website” or
                    “Service”) and any of its related products and services
                    (collectively, “Services”) is collected, protected and used.
                    It also describes the choices available to you regarding our
                    use of your Personal Information and how you can access and
                    update this information. This Policy is a legally binding
                    agreement between you (“User”, “you” or “your”) and this
                    Website operator (“Operator”, “we”, “us” or “our”). By
                    accessing and using the Website and Services, you
                    acknowledge that you have read, understood, and agree to be
                    bound by the terms of this Policy. This Policy does not
                    apply to the practices of companies that we do not own or
                    control, or to individuals that we do not employ or manage.
                  </p>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Collection of information
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      Our top priority is customer data security and, as such,
                      we exercise the no logs policy. We may process only
                      minimal user data, only as much as it is necessary to
                      maintain the Website and Services. Information collected
                      automatically is used only to identify potential cases of
                      abuse and establish statistical information regarding the
                      usage and traﬃc of the Website and Services. This
                      statistical information is not otherwise aggregated in
                      such a way that would identify any particular user of the
                      system.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Use and processing of collected information
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      In order to make the Website and Services available to
                      you, or to meet a legal obligation, we may need to collect
                      and use certain Personal Information. If you do not
                      provide the information that we request, we may not be
                      able to provide you with the requested products or
                      services. Any of the information we collect from you may
                      be used for the following purposes:
                    </p>
                    <li>– Send administrative information</li>
                    <li>– Respond to inquiries and offer support</li>
                    <li>– Request user feedback</li>
                    <li>– Run and operate the Website and Services</li>
                    <br />
                    <p>
                      Processing your Personal Information depends on how you
                      interact with the Website and Services, where you are
                      located in the world and if one of the following applies:
                    </p>
                    <p>
                      (i) you have given your consent for one or more speciﬁc
                      purposes; this, however, does not apply, whenever the
                      processing of Personal Information is subject to European
                      data protection law.
                    </p>
                    <p>
                      (ii) provision of information is necessary for the
                      performance of an agreement with you and/or for any
                      pre-contractual obligations thereof;
                    </p>
                    <p>
                      (iii) processing is necessary for compliance with a legal
                      obligation to which you are subject;
                    </p>
                    <p>
                      (iv) processing is related to a task that is carried out
                      in the public interest or in the exercise of oﬃcial
                      authority veste d in us;
                    </p>
                    <p>
                      (v) processing is necessary for the purposes of the
                      legitimate interests pursued by us or by a third party.
                    </p>
                    <p>
                      Note that under some legislations we may be allowed to
                      process information until you object to such processing
                      (by opting out), without having to rely on consent or any
                      other of the following legal bases below. In any case, we
                      will be happy to clarify the speciﬁc legal basis that
                      applies to the processing, and in particular whether the
                      provision of Personal Information is a statutory or
                      contractual requirement, or a requirement necessary to
                      enter into a contract.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Disclosure of information
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      Depending on the requested Services or as necessary to
                      complete any transaction or provide any service you have
                      requested, we may share your information with your consent
                      with our trusted third parties that work with us, any
                      other aﬃliates and subsidiaries we rely upon to assist in
                      the operation of the Website and Services available to
                      you. We do not share Personal Information with unaﬃliated
                      third parties. These service providers are not authorized
                      to use or disclose your information except as necessary to
                      perform services on our behalf or comply with legal
                      requirements. We may share your Personal Information for
                      these purposes only with third parties whose privacy
                      policies are consistent with ours or who agree to abide by
                      our policies with respect to Personal Information. These
                      third parties are given Personal Information they need
                      only in order to perform their designated functions, and
                      we do not authorize them to use or disclose Personal
                      Information for their own marketing or other purposes.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Retention of information
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      We will retain and use your Personal Information for the
                      period necessary to comply with our legal obligations,
                      resolve disputes, and enforce our agreements unless a
                      longer retention period is required or permitted by law.
                      We may use any aggregated data derived from or
                      incorporating your Personal Information after you update
                      or delete it, but not in a manner that would identify you
                      personally. Once the retention period expires, Personal
                      Information shall be deleted. Therefore, the right to
                      access, the right to erasure, the right to rectiﬁcation
                      and the right to data portability cannot be enforced after
                      the expiration of the retention period.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    The Rights of Users
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      You may exercise certain rights regarding your information
                      processed by us. In particular, you have the right to do
                      the following:
                    </p>
                    <p>
                      (i) you have the right to withdraw consent where you have
                      previously given your consent to the processing of your
                      information;
                    </p>
                    <p>
                      (ii) you have the right to object to the processing of
                      your information if the processing is carried out on a
                      legal basis other than consent;
                    </p>
                    <p>
                      (iii) you have the right to learn if information is being
                      processed by us, obtain disclosure regarding certain
                      aspects of the processing and obtain a copy of the
                      information undergoing processing;
                    </p>
                    <p>
                      (iv) you have the right to verify the accuracy of your
                      information and ask for it to be updated or corrected;
                    </p>
                    <p>
                      (v) you have the right, under certain circumstances, to
                      restrict the processing of your information, in which
                      case, we will not process your information for any purpose
                      other than storing it;
                    </p>
                    <p>
                      (vi) you have the right, under certain circumstances, to
                      obtain the erasure of your Personal Information from us;
                    </p>
                    <p>
                      (vii) you have the right to receive your information in a
                      structured, commonly used and machine readable format and,
                      if technically feasible, to have it transmitted to another
                      controller without any hindrance. This provision is
                      applicable provided that your information is processed by
                      automated means and that the processing is based on your
                      consent, on a contract which you are part of or on
                      pre-contractual obligations thereof.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Privacy of children
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      We do not knowingly collect any Personal Information from
                      children under the age of 13. If you are under the age of
                      13, please do not submit any Personal Information through
                      the Website and Services. We encourage parents and legal
                      guardians to monitor their children’s Internet usage and
                      to help enforce this Policy by instructing their children
                      never to provide Personal Information through the Website
                      and Services without their permission. If you have reason
                      to believe that a child under the age of 13 has pr ovided
                      Personal Information to us through the Website and
                      Services, please contact us. You must also be old enough
                      to consent to the processing of your Personal Information
                      in your country (in some countries we may allow your
                      parent or guardian to do so on your behalf).
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Do Not Track signals
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      Some browsers incorporate a Do Not Track feature that
                      signals to websites you visit that you do not want to have
                      your online activity tracked. Tracking is not the same as
                      using or collecting information in connection with a
                      website. For these purposes, tracking refers to collecting
                      personally identiﬁable information from consumers who use
                      or visit a website or online service as they move across
                      different websites over time. How browsers communicate the
                      Do Not Track signal is not yet uniform. As a result, the
                      Website and Services are not yet set up to interpret or
                      respond to Do Not Track signals communicated by your
                      browser. Even so, as described in more detail throughout
                      this Policy, we limit our use and collection of your
                      personal information.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Links to other resources
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      The Website and Services contain links to other resources
                      that are not owned or controlled by us. Please be aware
                      that we are not responsible for the privacy practices of
                      such other resources or third parties. We encourage you to
                      be aware when you leave the Website and Services and to
                      read the privacy statements of each and every resource
                      that may collect Personal Information.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Information security
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      Webxinﬁnity IT Solutions strives to keep our customers
                      happy; physical safeguards in an effort to protect against
                      unauthorized access, use, modiﬁcation, and disclosure of
                      Personal Information in its control and custody. However,
                      no data transmission over the Internet or wireless network
                      can be guarant eed. Therefore, while we strive to protect
                      your Personal Information, you acknowledg e that (i) there
                      are security and privacy limitations of the Internet which
                      are beyon d our control; (ii) the security, integrity, and
                      privacy of any and all information a nd data exchanged
                      between you and the Website and Services cannot be
                      guaranteed; and (iii) any such information and data may be
                      viewed or tampered with in transit by a third party,
                      despite best efforts.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Data breach
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      In the event we become aware that the security of the
                      Website and Services has been compromised or users
                      Personal Information has been disclosed to unrelated third
                      parties as a result of external activity, including, but
                      not limited to, security attacks or fraud, we reserve the
                      right to take reasonably appropriate measures, including,
                      but not limited to, investigation and reporting, as well
                      as notiﬁcation to and cooperation with law enforcement
                      authorities. In the event of a data breach, we will make
                      reasonable efforts to notify affected individuals if we
                      believe that there is a reasonable risk of harm to the
                      user as a result of the breach or if notice is otherwise
                      required by law. When we do, we will post a notice on the
                      Website, send you an email.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Changes and amendments
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      We reserve the right to modify this Policy or its terms
                      relating to the Website and Services from time to time in
                      our discretion and will notify you of any material changes
                      to the way in which we treat Personal Information. When we
                      do, we will revise the updated date at the bottom of this
                      page. We may also provide notice to you in other ways at
                      our discretion, such as through contact information you
                      have provided. Any updated version of this Policy will be
                      effective immediately upon the posting of the revised
                      Policy unless otherwise speciﬁed. Your continued use of
                      the Website and Services after the effective date of the
                      revised Policy (or such other act speciﬁed at that time)
                      will constitute your consent to those changes. However, we
                      will not, without your consent, use your P Information in
                      a manner materially different from what was stated at the
                      time your Personal Information was collected.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Acceptance of this policy
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      You acknowledge that you have read this Policy and agree
                      to all its terms and conditions. By accessing and using
                      the Website and Services you agree to be bound by this
                      Policy. If you do not agree to abide by the terms of this
                      Policy, you are not authorized to access or use the
                      Website and Services.
                    </p>
                  </div>
                </div>
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Contacting us
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      If you would like to contact us to understand more about
                      this Policy or wish to contact us concerning any matter
                      relating to individual rights and your Personal
                      Information, you may send an email to
                      sales@webxinﬁnity.com This document was last updated on
                      April 14, 2022.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
