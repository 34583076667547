import React from 'react';
import wallet from "../../../img/Projects/wallet.webp";

let Walletstore = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={wallet} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            life wallet store
                        </div>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Life Wallet Store</h2>

                        <p className='paraPort'>We are at Life Wallet Store, Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique doloribus illo maiores ipsa eaque alias sint sunt libero, debitis assumenda veniam explicabo, architecto rerum eos. Quas iusto praesentium vel, beatae libero porro necessitatibus non tempore commodi sed aspernatur, iste aliquam quis incidunt debitis enim asperiores quia minus eos voluptatum repellendus? Debitis labore, temporibus voluptates eveniet ipsa magni est totam distinctio quaerat quod magnam cupiditate explicabo porro atque similique earum fuga fugiat necessitatibus corporis minus minima! Maiores dolorum, fugit facere cumque numquam id eius esse. Ipsum blanditiis fugiat ipsa consequatur illum, earum qui, soluta quis iste facere aliquid. Eaque, ad quo!</p>

                      
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Walletstore;