import React from 'react';
import meritl from "../../../img/Projects/meritl.webp";
import meritline from "../../../img/Projects/meritline.webp";
import merit from "../../../img/Projects/merit.webp";


let Meritline = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={meritl} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            meritline
                        </div>
                        <h5>Education is not only a ladder of opportunity but it is also an investment in our future.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>About Cilent </h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>We are a team of leading education counsellors, extending excellent support and service to students in charting their bright future. With our wide knowledge and expertise of the Indian education system, MERITLINE is perfect place to provide you with the right service and assistance. We also offer a carefully developed assistance and free counselling session, which is open to all our student community.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={merit} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={meritline} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <br />
                        <h2 className='textHead2'>Benefits of Career Counselling:</h2>
                        <p className="paraPort">Counseling is the role of practitioners, who combine giving advice on their topic of expertise with counseling techniques that support clients in making complex decisions and facing difficult situations. Career counseling is focused on how the individuals manage their journey through life, learning and work (Career). This includes career exploration, making career choices, managing career changes, lifelong Career Development and dealing with other career related issues.</p>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Meritline;