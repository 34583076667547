import React from 'react';
import old1 from "../../../img/Projects/old1.webp";
import old from "../../../img/Projects/old.webp";
import old2 from "../../../img/Projects/old2.webp";
import ol3 from "../../../img/Projects/ol3.webp";
import old4 from "../../../img/Projects/old4.webp";
import old5 from "../../../img/Projects/old5.webp";

let OldSchool = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={old} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            old school
                        </div>
                        <h5>We work in an integral manner and address projects in the following dimensions.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>About Cilent </h2>

                        <hr style={{background:"#fff"}}/>

                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>We are looking for individuals who are ready to develop daily and not afraid of difficult tasks.</h2>
                        <p className='paraPort'>So, the height and width is not fixed, and it is calculated dynamically according to the diminutions of the window. Under that div, there is another one that has the ID header “navigation bar”, the header is initially positioned relatively under div.fullscreen – however, I am trying to position it as Fixed when the top of the #header reaches the top of the browser window. So here is what I am playing around, it toggles the Fixed style, but it does not toggle it at the right place…</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={old1} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={old2} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <br />
                        <h2 className='textHead2'>Concept</h2>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>We are looking for individuals who are ready to develop daily and not afraid of difficult tasks</h2>
                        <p className="paraPort">So, the height and width is not fixed, and it is calculated dynamically according to the diminutions of the window. Under that div, there is another one that has the ID header “navigation bar”, the header is initially positioned relatively under div.fullscreen – however, I am trying to position it as Fixed when the top of the #header reaches the top of the browser window. So here is what I am playing around, it toggles the Fixed style, but it does not toggle it at the right place…</p>
                        <div className="row">
                            <div className="col-lg-6 mt-4">
                                <img src={old4} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 mt-4">
                                <img src={old5} style={{width:"100%"}} alt="" />
                            </div>
                        </div>
                        <br />
                        <h2 className='textHead2'>Services</h2>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>We are looking for individuals who are ready to develop daily and not afraid of difficult tasks</h2>
                        <p className="paraPort">So, the height and width is not fixed, and it is calculated dynamically according to the diminutions of the window. Under that div, there is another one that has the ID header “navigation bar”, the header is initially positioned relatively under div.fullscreen – however, I am trying to position it as Fixed when the top of the #header reaches the top of the browser window. So here is what I am playing around, it toggles the Fixed style, but it does not toggle it at the right place…</p>


                        <div className="row">
                            <div className="col-12 mt-4">
                                <img src={ol3} style={{width:"100%"}} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default OldSchool;