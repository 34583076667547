import React from "react";
import a1 from "../../img/award/award1.webp";
import a2 from "../../img/award/award2.webp";
import a3 from "../../img/award/award3.webp";
import a4 from "../../img/award/award4.webp";
import a5 from "../../img/award/award5.webp";
import a6 from "../../img/award/award6.webp";

let Awards = () => {
  return (
    <>
      <section className="Awards">
        <div className="container" style={{ marginTop: "60px" }}>
          <div className="contenty text-center">
            <h5 style={{ color: "#00fffc" }}>OUR AWARDS</h5>
            <br />
            <h1 className="awardHead">We Are Proud Of Our Digital Kids</h1>
          </div>
          <div className="row" id="servicesHome">
          <div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-2 my-3"
              id="service2"
            >
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <div className="cardAward">
                  <center>
                    <img id="cardAward" src={a1} alt="" />
                  </center>
                </div>
              </figure>
            </div><div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-2 my-3"
              id="service2"
            >
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <div className="cardAward">
                  <center>
                    <img id="cardAward" src={a2} alt="" />
                  </center>
                </div>
              </figure>
            </div><div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-2 my-3"
              id="service2"
            >
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <div className="cardAward">
                  <center>
                    <img id="cardAward" src={a3} alt="" />
                  </center>
                </div>
              </figure>
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-2 my-3"
              id="service2"
            >
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <div className="cardAward">
                  <center>
                    <img id="cardAward" src={a4} alt="" />
                  </center>
                </div>
              </figure>
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-2 my-3"
              id="service2"
            >
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <div className="cardAward">
                  <center>
                    <img id="cardAward" src={a5} alt="" />
                  </center>
                </div>
              </figure>
            </div>
            <div
              className="col-lg-2 col-md-2 col-sm-2 col-xs-2 my-3"
              id="service2"
            >
              <figure
                className="reveal-effect se2-white wow main-image no-margin"
                data-aos="slide-effect"
              >
                <div className="cardAward">
                  <center>
                    <img id="cardAward" src={a6} alt="" />
                  </center>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Awards;
