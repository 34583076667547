import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import website from "../../img/webdev.gif";
import mobile from "../../img/mobapp.gif";
import custom from "../../img/custom.gif";
import digital from "../../img/digital.gif";

let Offer = () => {
  return (
    <>
      <Router></Router>
      <section className="servicesWebx my-5">
        <div className="container">
          <div className="title">
            <h6 className="glitch">OFFERS AT WEBXINFINITY</h6>
          </div>

          <div className="row">
            <div
              className="col-lg-3 my-2"
              id="service1"
            >
              <Link to="/web_dev">
                <div className="offerServices">
                  <img className="imgServices" src={website} alt="website" />
                  <div className="text-center">
                    <span className="primary-text">Website Developement</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 my-2"
              id="service1"
            >
              <Link to="/mobile_dev">
                <div className="offerServices">
                  <img className="imgServices" src={mobile} alt="Mobile" />
                  <div className="text-center">
                    <span className="primary-text">Mobile App Development</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 my-2"
              id="service1"
            >
              <Link to="/custom_dev">
                <div className="offerServices">
                  <img className="imgServices" src={custom} alt="branding" />
                  <div className="text-center">
                    <span className="primary-text">Custom Development</span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-lg-3 my-2"
              id="service1"
            >
              <Link to="/digital_marketing">
                <div className="offerServices">
                  <img className="imgServices" src={digital} alt="digital" />
                  <div className="text-center">
                    <span className="primary-text">Digital Marketing</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offer;
