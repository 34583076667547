import React from "react";
import offer from "../../img/offer.webp";
import Offer from "../Content/Offer";


let Offers = () => {
  return (
    <>
    <section>
      <figure>
        <img src={offer} style={{width:"100%"}} alt="advertisement" />
      </figure>
    </section>
    <div className="mt-5">
      <Offer/>
    </div>
    </>
  );
};

export default Offers;