import React from "react";
import { Helmet } from "react-helmet";

import terms from "../../../img/terms.webp";

let Terms = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions - Webxinfinity IT Solution, Bengaluru</title>
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={terms} alt="Terms & Conditions" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    1. Webxinfinity will ensure that projects are completed in
                    time in coordination with the clients and content received.
                  </p>
                  <p>
                    2. We hereby confirm that Projects will commence on
                    foreclosure of ideation, content and correct transfer of KT
                    and other deliverables.
                  </p>
                  <p>
                    3. Work will start once we have received the disclosed
                    amount which has been communicated over the estimate.
                  </p>
                  <p>
                    4. Clients hereby acknowledge that there will be only one
                    point of contact between the company and client’s team.
                  </p>
                  <p>
                    5. Company will assign a dedicated Project Manager to
                    communicate with and share all updates to the client. The
                    project manager will see to it that there will be timely
                    updates shared to the clients in view of the all round scope
                    of work.
                  </p>
                  <p>
                    6. The client hereby acknowledges that he/she will not
                    discuss additional scope of work or requirements that has
                    not been communicated/agreed upon during initial
                    discussions. Keeping in mind that, project requirements are
                    final which have been intimated within the project estimate.
                  </p>
                  <p>
                    7. Any additional work that does not come under the initial
                    stages of discussion/scope of work will not be touched upon
                    until approved by the management.
                  </p>
                  <p>
                    8. The client hereby acknowledges that, the sales executive
                    will only be a source of information and business help ie.
                    the requirements discussed between the sales executive will
                    be finally scrutinised and evaluated by the Webxinfinity
                    Technical and Management team and from there on the
                    SME(Subject Matter Expert) will create the exact scope of
                    work that will meet the client requirements and will share
                    the final outline and estimate/proposal.
                  </p>
                  <p>
                    9. The final scope of work will be communicated and
                    finalised only on mail and no other network channels.
                  </p>
                </div>
                <br />
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Points to be Noted upon by clients.
                  </h1>
                  <div
                    style={{
                      textAlign: "justify",
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <br />
                    <li>
                      Any additional work/requirements requested by the client
                      shall only be considered after review by the project
                      manager, SME and management. These new requirements will
                      be then evaluated by their respective resource weight age
                      and time scale to determine the additional price, which
                      will be considered on work as an hourly basis. Per Hour
                      cost starting from Rs. 2000/- . The said new requirements
                      will then be foreclosed via email communication only and
                      no other network channels.
                    </li>
                    <br />
                    <li>
                      The client hereby agrees to ensure that the entire scope
                      of work which was finalised on the estimate will be
                      executed upon exact workflow and idealisation, which will
                      be examined by the SME and Project Manager. The
                      commencement of work will be on review of work flow that
                      has been closely monitored within the project proposal.
                    </li>
                    <br />
                    <li>
                      The client hereby acknowledges that all work will be
                      clearly communicated by the manner of a flow chart and a
                      visual interpretation of the expectation or scope of work
                      that will be presented to and be examined by the SME and
                      Project Manager. The commencement of work will be on
                      review of work flow that has been closely monitored within
                      the project proposal.
                    </li>
                    <br />
                    <li>
                      All customisation which do not comply with the scope of
                      work as mentioned in the proposal or estimate will be
                      considered on evaluation and by final review by the
                      project manager, SME and management. These new
                      requirements will be then evaluated by their respective
                      resource weight age and time scale to determine the
                      additional price, which will be considered on work as an
                      hourly basis. Per Hour cost starting from Rs. 500/- . The
                      said new requirements will then be foreclosed via email
                      communication only and no other network channels.
                    </li>
                    <br />
                    <li>
                      Webxinfinity always works to ensure the quality service
                      and maintenance of the website. The company sees to that
                      all products/websites will be live 24*7, but we do advise
                      our clients to always go ahead with their private hosting
                      for the best performance and up time service. The company
                      is not bound or limited to the technical infrastructure
                      such as Server and Hosting.
                    </li>
                    <br />
                    <li>
                      Webxinfinity will strive to provide great customer service
                      and has introduced the Premium Support Plans which are
                      very advisable for the clients to consider for better
                      service.
                    </li>
                    <br />
                    <li>
                      Webxinfinity hereby states that projects that come under
                      customisation will be worked upon, after the customisation
                      requirements have been duly assessed by the management.
                      The client/customers are expected to clear all project
                      dues to start working on the project customisation.
                    </li>
                    <br />
                    <li>
                      Webxinfinity hereby states that projects that come under
                      customisation will be worked upon, after the customisation
                      requirements have been duly assessed by the management.
                      The client/customers are expected to clear all project
                      dues to start working on the project customisation. The
                      said Project Customisation will then be foreclosed via
                      email communication only and no other network channels.
                    </li>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
