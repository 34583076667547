import React from "react";
import { Helmet } from "react-helmet";
import digital from "../../img/digital.webp";
import DigitalServ from "./DigitalServ";

import seo from "../../img/SEO.webp";
import sem from "../../img/SEM.webp";
import smm from "../../img/SMM.webp";
import lead from "../../img/lead.webp";
import mm from "../../img/mm.webp";
import orm from "../../img/orm.webp";
import sales from "../../img/sales.webp";
import brand from "../../img/brand.webp";
import DigitalForm from "../Content/DigitalForm";

let Digital = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Marketing | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={digital} alt="fiver" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">DIGITAL MARKETING</div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    Digital Marketing seems to appear as the taste maker of
                    every business on an online platform these days. Our team of
                    expert chefs is all you need to have your digital ads added
                    to every social platform. We maintain and analyze the best
                    digital solution and market it the most meaningful way
                    possible to reach the targeted customers. we hold a good
                    track record of successful digital marketing for our
                    customers. We aim any business to reach maximum audience
                    through SEO, Facebook ads, Instagram Ads, Twitter and
                    LinkedIn social Platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <center>
                  <div id="digital" className="owl-carousel">
                    <div className="digitalWork">
                      <img
                        src={seo}
                        style={{ width: "100%" }}
                        alt=""
                      />

                      <h4 className="text-center text-light my-3">
                        <b>SEO</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={sem}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>SEM</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={smm}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>SMM</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={mm}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>Mobile Marketing</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={orm}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>ORM</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={brand}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>Brand Awareness</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={sales}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>Sales Campaigns</b>
                      </h4>
                    </div>

                    <div className="digitalWork">
                      <img
                        src={lead}
                        style={{ width: "100%" }}
                        alt=""
                      />
                      <h4 className="text-center text-light my-3">
                        <b>Lead Generations (B2B & B2C)</b>
                      </h4>
                    </div>
                  </div>
                </center>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <DigitalServ />
            <DigitalForm />
          </div>
        </div>
      </section>
    </>
  );
};

export default Digital;
