import React from "react";
import { Helmet } from "react-helmet";

import fiver from "../../img/fiver.webp";
import FormServ from "./FormServ";

let Branding = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Branding | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={fiver} alt="fiver" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    Our branding team makes sure that your business and its
                    brand value is making its presence in the most intrinsic way
                    possible across all social media platforms. We strategize to
                    build a brand for your business which builds trust and
                    reputation from customers. We provide consistent
                    communication throughout the process and constantly upgrade
                    with real time results.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="row" id="servicesHome">
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card cardTeam-1">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets1.lottiefiles.com/packages/lf20_xgvucffc.json"
                            background="transparent"
                            speed="1"
                            id="lotties"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center textSER text-light my-3">
                            Brand Name
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card cardTeam-1">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets4.lottiefiles.com/packages/lf20_awuvrkez.json"
                            background="transparent"
                            speed="1"
                            id="lotties"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center textSER text-light my-3">
                            Brand Slogan
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card cardTeam-1">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets10.lottiefiles.com/packages/lf20_l6xjtdmg.json"
                            background="transparent"
                            speed="1"
                            id="lotties"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center textSER text-light my-3">
                            Brand Logo
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card cardTeam-1">
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets1.lottiefiles.com/packages/lf20_uhcfeaa9.json"
                            background="transparent"
                            speed="1"
                            id="lotties"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center textSER text-light">
                            Brand Guideline
                          </h4>
                        </center>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card cardTeam-1">
                      <center>
                        <lottie-player
                          src="https://assets9.lottiefiles.com/packages/lf20_mjr11zfo.json"
                          background="transparent"
                          speed="1"
                          id="lotties"
                          loop
                          autoplay
                        ></lottie-player>
                        <h4 className="text-center textSER text-light">
                          Brand Design Guidelines
                        </h4>
                      </center>
                    </div>
                  </div>
                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card cardTeam-1">
                      <center>
                        <lottie-player
                          src="https://assets7.lottiefiles.com/packages/lf20_xwgclkyh.json"
                          background="transparent"
                          speed="1"
                          id="lotties"
                          loop
                          autoplay
                        ></lottie-player>
                        <h4 className="text-center textSER text-light">
                          Brand Book
                        </h4>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <FormServ />
          </div>
        </div>
      </section>
    </>
  );
};

export default Branding;
