import React from "react";

import { Helmet } from "react-helmet";
import custom from "../../img/custom.webp";
import CustomPort from "./CustomPort";
import CustPrize from "./CustPrize";
import Customform from "../Content/Customform";

let CustomD = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Mobile App Development | Webxinfinity IT Solution, Bengaluru
        </title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={custom} alt="custom development" />
        </figure>
      </section>
      <CustPrize />

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">CUSTOM DEVELOPMENT</div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    App Development We cater customised mobile application
                    development in both iOS and Android for our customers. Our
                    team invests in quality time on understanding the needs of a
                    customer and accordingly plan a very well executed strategy
                    for the application. Our developers and UI/ UX designers sit
                    together and brainstorm the best plan to roll out for an
                    application to be user-friendly and aesthetically pleasing.
                    We have built many applications across platforms which has
                    given us a very fruitful experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div className="row" id="servicesHome">
                  <div className="col-lg-4 my-2">
                    <div className="flip-card" style={{ padding: "22px 0px" }}>
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets5.lottiefiles.com/packages/lf20_M4t9nS.json"
                            background="transparent"
                            speed="1"
                            style={{ width: "254px", height: "140px" }}
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center text-light my-3">
                            <b>Coding</b>
                          </h4>
                          <hr style={{ background: "#fff" }} />

                          <h6 className="text-light">HTML 5</h6>
                          <h6 className="text-light">javaScript</h6>
                          <h6 className="text-light">Angular</h6>
                          <h6 className="text-light">Ionic</h6>
                          <h6 className="text-light">Android Studio</h6>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card" style={{ padding: "22px 0px" }}>
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets5.lottiefiles.com/datafiles/fab7172a9302d416bcdb8ac7e1c71123/data.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center webH4 text-light my-3">
                            <b>Advance Tech</b>
                          </h4>
                          <hr style={{ background: "#fff" }} />
                          <h6 className="text-light webH6">React Js</h6>
                          <h6 className="text-light webH6">Next Js</h6>
                          <h6 className="text-light webH6">React Native</h6>
                          <h6 className="text-light webH6">Expo</h6>
                          <h6 className="text-light webH6">Redux</h6>
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-2" id="service1">
                    <div className="flip-card" style={{ padding: "22px 0px" }}>
                      <div className="contWebx">
                        <center>
                          <lottie-player
                            src="https://assets7.lottiefiles.com/packages/lf20_hi95bvmx/WebdesignBg.json"
                            background="transparent"
                            speed="1"
                            id="lotties1"
                            loop
                            autoplay
                          ></lottie-player>
                          <h4 className="text-center webH4 text-light my-3">
                            <b>Builders</b>
                          </h4>
                          <hr style={{ background: "#fff" }} />
                          <h6 className="text-light webH6">Wordpress</h6>
                          <h6 className="text-light webH6">Shopify</h6>
                          <h6 className="text-light webH6">Megneto</h6>
                          <h6 className="text-light webH6">Opencart</h6>
                          <h6 className="text-light webH6">Strapi</h6>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <CustomPort />

            {/* Form Below */}
            <Customform />
            {/* Form End */}
          </div>
        </div>
      </section>
    </>
  );
};
export default CustomD;
