import React from "react";
import { useHistory } from "react-router-dom";

let Thank = () => {

  let history = useHistory();

  setTimeout(function() {
    history.goBack();
  }, 5000);

  return (
    <>
      <div className="thankYou">
        <center>
          <h2>Thank you !</h2>
          <hr />
          <p>We'll reach you soon.</p>
          <br />
          <br />
          <a href="/">
            <button className="btnHome">Home</button>
          </a>
        </center>
      </div>
    </>
  );
};

export default Thank;
