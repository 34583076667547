import React from "react";
import news from "../../img/offerPage/news.webp";
import job from "../../img/offerPage/Jobs.webp";
import bangla from "../../img/offerPage/bangla.webp";
import ferbo from "../../img/offerPage/ferbofurnish.webp";
import crm from "../../img/offerPage/CRM.webp";

let CustomPort = () => {
  return (
    <>
      <div className="container my-5">
        <div className="title">
          <div className="glitch">Recent Projects</div>
        </div>

        <div className="row">
          <div className="col-lg-3 my-3" id="service1">
            <div className="cardPrice">
              <a
                href="https://ferbofurnish.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ferbo} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">Ferbo Furnish</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-3 my-3" id="service1">
            <div className="cardPrice">
              <a href="http://an2news.com/" target="_blank" rel="noreferrer">
                <img src={news} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">AN2 News</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-3 my-3" id="service1">
            <div className="cardPrice">
              <a href="http://an2bangla.com/" target="_blank" rel="noreferrer">
                <img src={bangla} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">AN2 Bangla</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-3 my-3" id="service1">
            <div className="cardPrice">
              <a href="http://an2jobs.com/" target="_blank" rel="noreferrer">
                <img src={job} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">AN2 Jobs</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3"></div>
          <div className="col-lg-4 my-3">
            <div className="cardPrice">
              <a
                href="http://team.webxinfinity.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={crm} alt="" />
              </a>
              <center>
                <h6 className="pFirst mt-3">CRM</h6>
              </center>
            </div>
          </div>
          <div className="col-lg-4 my-3"></div>
        </div>
      </div>
    </>
  );
};

export default CustomPort;
