import React from "react";

let PriceWeb = () => {
  return (
    <>
      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Static</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Static Website Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Dynamic</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Dynamic Website Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">E-Commerce</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-Commerce Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">E-commerce Plus</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a E-commerce Plus Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Listing/Booking</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Listing/Booking Software Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
                id="service1"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Multi Vendor/LMS</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Multi Vendor/LMS  Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-3 col-sm-3 col-xs-3 my-2"
              >
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Custom Coding</h6>
                    <br />
                    <br />
                    <br />
                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=917975456795&text=Hello Team, I am looking for a Custom Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote1">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceWeb;
