import React from 'react';
import rapid from "../../../img/Projects/rapid.webp";
import rapido from "../../../img/Projects/rapido.webp";
import rapidos from "../../../img/Projects/rapidos.webp";
import rapidd from "../../../img/Projects/rapidd.webp";


let Rapido = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={rapido} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>

        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            rapido
                        </div>
                        <h5>Rapido has expanded from a highly successful two wheeler taxi service to offer new person-to-person and small-business-to-person delivery services and enter new international markets.</h5>
                    </div>

                    <br />
                    <div className="oui">
                        <h2 className='textHead2'>Streamlining UX efforts by filling in gaps in skill sets and setting up established design processes</h2>

                        <hr style={{background:"#fff"}}/>

                        <p className='paraPort'>Rapido is India’s first and fastest-growing Bike taxi app. It has two mobile apps – Consumer-facing, which allows users to book bike taxis near them & Captain app, which enables bike-riders (captains) to serve the users who book the ride. As a tech first company, their development team was strong. However, the design team was struggling to keep up with the growth. As a young company growing at a rapid pace, Rapido needed to quickly scale its design team, define a visual style guide and set processes that enabled all stakeholders to take strategic product design decisions.</p>

                        <div className="row">
                            <div className="col-lg-6 my-3">
                                <img src={rapid} style={{width:"100%"}} alt="" />
                            </div>
                            <div className="col-lg-6 my-3">
                                <img src={rapidos} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                        <h2 className='textHead2'>Challenges:</h2>

                        <p className='paraPort'>The challenge at hand was to clear the design backlog and work towards bringing the design efforts ahead of engineering efforts. The partnership also involved filling in the missing design pieces and building a consistent user experience across the different platforms.</p>

                        <br />
                        <h2 className='textHead2'>Solution:</h2>
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="paraPort">After an in-depth analysis of the current status of the product, we handpicked a suitable team with the right skill sets for the job. Our team would collaborate with the developers, be in meetings with the customer service teams and also brainstorm with the existing designers on an everyday basis. We worked towards delivering designs 3 weeks ahead of development. At the same time we ensured building a product that is intuitive and has world-class functionality.</p>

                                <p className="paraPort">The challenge at hand was to clear the design backlog and work towards bringing the design efforts ahead of engineering efforts. The partnership also involved filling in the missing design pieces and building a consistent user experience across the different platforms.</p>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <img src={rapidd} style={{width:"100%"}} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Rapido;