import React from "react";
import rating from "../../img/rating.png";


let Reviews = () => {
  return (
    <>
      <section className="Reviews">
        <div className="container" style={{ marginTop: "66px" }}>
          <div className="title">
            <img src={rating} className="imgRATING" alt="" />
            <h6 className="glitch">TESTIMONIALS</h6>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div id="testimonial-slider" className="owl-carousel">
                <div className="testimonial">
                  <p className="description">
                    When it comes to fueling your business or brand with
                    comprehensive marketing activities that deliver real-time
                    results, Webxinfinity is the name to rely on. We understand
                    your requirements from the root of the issue to the goals.
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Dipika Sathyan</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    It is the best place to get started with digital marketing
                    and website development for your business. These guys are
                    well trained about the modern methods of advertising. In
                    this segment, they deserve a good mention. Highly
                    recommended. Keep going Webxinfinity!!!!!
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Shivam S</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    Lakshmikanth, Kaisher, Pieyush, Monika, Srinivas you guys
                    are really good and fantastic we have 30 leads constant
                    every day and we are doing very good for fssai license in
                    this pandemic some times it might be less leads or not a
                    good quality.
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Anurag - Fascos India</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    I was in search of hosting .I always had issues with hosting
                    service provider then I got "Webxinfinity" this website just
                    works fine and I never had one day issue with server and
                    also .. THE TEAM IS REALLY GOOD 👍
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Nisarga Poojar</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    I hired them for the development of my online store and they
                    have satisfied me to the full by delivering the exceptional
                    solution. They have a great expertise, are dedicated,
                    attentive, talented and care much about the client needs.
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Mohammed Noorulla</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    I wish Lakshmi Kanth and Monica all the best😊 After leaving
                    my job.. I wanted to do some freelancing so ...I had given
                    website to one freelancer whom I found online.. They just
                    wasted my time as well as my money.
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Chaitra Balu</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    Brilliant team and very expertised in web development. They
                    are very quick to create a professional website and also
                    gave us a nice logo. We would highly recommend this company
                    for their services and fast response time.
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Ranjitha R</h3>
                  </div>
                </div>

                <div className="testimonial">
                  <p className="description">
                    I got my website designed and development by webxinfinity in
                    Feb 2021 I have been associated with them since then and
                    always go back to them for every minor to major changes on
                    my website .They have got a team of hard-working people❤️
                  </p>
                  <div className="testimonial-content">
                    <h3 className="title">Uday R</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reviews;
