import React from 'react';
import printalytix from "../../../img/Projects/printalytix.webp";


let Print = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={printalytix} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>


        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            printalytix
                        </div>
                        
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Your search ends here</h2>

                        <li className="paraPort">All made-to-order mechanical product manufacturing capabilities.</li>
                        <li className="paraPort"> In-house fashion designer at your doorstep.</li>
                        <li className="paraPort">Get-Set-Go start to our engagement with you.</li>
                        <li className="paraPort">Quick & optimized delivery schedules.</li>
                        <li className="paraPort">World-class manufacturing quality and compliances.</li>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Flexible supply chain</h2>
                        <li className="paraPort">Take the Journey of Prototyping –to- Production with us.</li>
                        <li className="paraPort">10 to 2,50,000+ production units capacity available on-demand.</li>
                        <li className="paraPort">Choose from our lego blocks of 13+ manufacturing technologies.</li>
                        <li className="paraPort">Manufacturing capabilities for up to 50+ materials – in metals , plastics and composites.</li> 

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>You are at full throttle</h2>
                        <li className="paraPort">Your journey to Go-to-market is accelerated.</li>
                        <li className="paraPort">Our offerings plug the gaps in the manufacturing value chain.</li>
                        <li className="paraPort">Processes friendly & order execution traceability.</li>
                        <li className="paraPort">Diligent & on-time project handling support.</li>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Print;