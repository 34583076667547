import React from "react";

import Content from './Components/Content/Content';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Branding from "./Components/Services/Branding";
import Services from "./Components/Services/Services";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import DevWeb from "./Components/Services/DevWeb";
import MobileApp from "./Components/Services/MobileApp";
import Advertise from "./Components/Services/Advertise";
import Production from "./Components/Services/Production";
import About from "./Components/About.jsx/About";
import Digital from "./Components/Services/Digital";
import SayHello from "./Components/SayHello/SayHello";
import Career from "./Components/Careers.jsx/Career";
import Work from "./Components/Portfolio/Work";
import Oui from "./Components/Portfolio/Projects/Oui";
import Malnad from "./Components/Portfolio/Projects/Malnad";
import Tailorswala from "./Components/Portfolio/Projects/Tailorswala";
import Print from "./Components/Portfolio/Projects/Print";
import Unilet from "./Components/Portfolio/Projects/Unilet";
import Crossweave from "./Components/Portfolio/Projects/Crossweave";
import Walletstore from "./Components/Portfolio/Projects/Walletstore";
import AN2 from "./Components/Portfolio/Projects/AN2";
import Dream11 from "./Components/Portfolio/Projects/Dream11";
import Rapido from "./Components/Portfolio/Projects/Rapido";
import Mamaearth from "./Components/Portfolio/Projects/Mamaearth";
import Aone from "./Components/Portfolio/Projects/Aone";
import Fennec from "./Components/Portfolio/Projects/Fennec";
import OldSchool from "./Components/Portfolio/Projects/OldSchool";
import Meritline from "./Components/Portfolio/Projects/Meritline";
import JLS from "./Components/Portfolio/Projects/JLS";
import Iksha from "./Components/Portfolio/Projects/Iksha";
import Privacy from "./Components/Footer/QuickLink/Privacy";
import Terms from "./Components/Footer/QuickLink/Terms";
import Refund from "./Components/Footer/QuickLink/Refund";
import Cancel from "./Components/Footer/QuickLink/Cancellation";
import Custom from "./Components/Services/Custom";
import CustomD from "./Components/Offer/CustomD";
import WebDevelop from "./Components/Offer/WebDevlop";
import MobApp from "./Components/Offer/MobApp";
import Thank from "./Components/Content/Thank";
import Offers from "./Components/Offer/Offers";
import Blog from "./Components/Blog/Blog";
import BusinessWebsite from "./Components/Packages/BusinessWebsite";
import EcomPackage from "./Components/Packages/EcomPackage";
import MultiVendorPackage from "./Components/Packages/MultiVendorPackage";




function App() {
  return (
    <>
      <Router>
        <ScrollToTop/>
        <Header/>
          <Switch>
            <Route exact path="/">
              <Content/>
            </Route>
            <Route exact path="/services">
              <Services/>
            </Route>
            <Route exact path="/branding">
              <Branding/>
            </Route>
            <Route exact path="/website_development">
              <DevWeb/>
            </Route>
            <Route exact path="/mobile_app">
              <MobileApp/>
            </Route>
            <Route exact path="/custom">
              <Custom/>
            </Route>
            <Route exact path="/custom_dev">
              <CustomD/>
            </Route>
            <Route exact path="/web_dev">
              <WebDevelop/>
            </Route>
            <Route exact path="/mobile_dev">
              <MobApp/>
            </Route>
            <Route exact path="/advertise">
              <Advertise/>
            </Route>
            <Route exact path="/production">
              <Production/>
            </Route>
            <Route exact path="/about_us">
              <About/>
            </Route>
            <Route exact path="/digital_marketing">
              <Digital/>
            </Route>
            <Route exact path="/say_hello">
              <SayHello/>
            </Route>
            {/* <Route exact path="/career">
              <Career/>
            </Route> */}
            <Route exact path="/portfolio">
              <Work/>
            </Route>
            <Route exact path="/ouioui">
              <Oui/>
            </Route>
            <Route exact path="/malnad_controls">
              <Malnad/>
            </Route>
            <Route exact path="/tailorswala">
              <Tailorswala/>
            </Route>
            <Route exact path="/printalytix">
              <Print/>
            </Route>
            <Route exact path="/unilet_store">
              <Unilet/>
            </Route>
            <Route exact path="/the_crossweave">
              <Crossweave/>
            </Route>
            <Route exact path="/wallet_store">
              <Walletstore/>
            </Route>
            <Route exact path="/an2_logistic">
              <AN2/>
            </Route>
            <Route exact path="/dream11">
              <Dream11/>
            </Route>
            <Route exact path="/rapido">
              <Rapido/>
            </Route>
            <Route exact path="/mamaearth">
              <Mamaearth/>
            </Route>
            <Route exact path="/aone_steel">
              <Aone/>
            </Route>
            <Route exact path="/fennec_collective">
              <Fennec/>
            </Route>
            <Route exact path="/old_school">
              <OldSchool/>
            </Route>
            <Route exact path="/meritline">
              <Meritline/>
            </Route>
            <Route exact path="/jls_construction">
              <JLS/>
            </Route>
            <Route exact path="/iksha_silks">
              <Iksha/>
            </Route>
            <Route exact path="/privacy-policy">
              <Privacy/>
            </Route>
            <Route exact path="/terms-conditions">
              <Terms/>
            </Route>
            <Route exact path="/refund-policy">
              <Refund/>
            </Route>
            <Route exact path="/cancellation-policy">
              <Cancel/>
            </Route>
            <Route exact path="/thankyou">
              <Thank/>
            </Route>
            <Route exact path="/offer">
              <Offers/>
            </Route>
            <Route exact path="/blog">
              <Blog/>
            </Route>
            <Route exact path="/business-website-package">
              <BusinessWebsite/>
            </Route>
            <Route exact path="/ecommerce-website-package">
              <EcomPackage/>
            </Route>
            <Route exact path="/multi-vendor-package">
              <MultiVendorPackage/>
            </Route>
          </Switch>
        <Footer/>
      </Router>   
    </>
  );
}

export default App;
