import React from 'react';
import tailor from "../../../img/Projects/tailor.webp";

let Tailorswala = () =>{
    return(
        <>
        <section>
            <figure>
                <img src={tailor} style={{ width: "100%" }} alt="fiver" />
            </figure>
        </section>


        <section>
            <div className="project-details">
                <div className="container">
                    <div className="title">
                        <div className="glitch">
                            tailorswala
                        </div>
                        <h5>Every garment one picks, needs to be stitched to perfection; because every stitch has a story to tell. At Tailorswala.com, we understand the importance of these stories you wish to convey with your outfit.</h5>
                    </div>


                    <div className="oui">
                        <h2 className='textHead2'>About Client</h2>

                        <hr style={{background:"#fff"}}/>
                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Tailorswala</h2>

                        <p className='paraPort'>Every garment one picks, needs to be stitched to perfection; because every stitch has a story to tell. At Tailorswala.com, we understand the importance of these stories you wish to convey with your outfit.</p>

                        <p className='paraPort'>A first-of-its-kind, customised tailoring service, Tailorswala.com is your one-stop-shop for all your tailoring needs. From fabric selection and personalised designs to doorstep delivery and exclusive consultations, we believe in crafting the perfect look for you with the utmost care. YOU get to pick the designs, the cuts, the colours and the fabric you wish to go ahead, while consulting with our in-house designers on the outfit you wish to create. At Tailorswala.com, we wish to bridge the gap between your needs and the latest on the runways at affordable prices.</p>

                        <br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Services provided include:</h2>
                        <li className="paraPort">Convenient pick-up and delivery</li>
                        <li className="paraPort"> In-house fashion designer at your doorstep</li>
                        <li className="paraPort">Range of designs and patterns to choose from</li>
                        <li className="paraPort">Pliable measurement and alteration provisions</li>
                        <li className="paraPort">Professional tailors</li>
                        <li className="paraPort">Diverse payment options and hassle-free assistance</li>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>TailorsWala Buy and Stitch Service</h2>
                        <p className='paraPort'>While you’ve the luxury of bringing your fabrics to the work table, we at Tailorswala.com have taken a step further to provide you the best of services with our extensive range of fabrics to choose from. Our procurement team sources varies fabrics from across the globe and from prime fabric hubs of India, for manufacturers and wholesale retailers. Each fabric is sourced with a lot of care and keeping the design invention in mind, making them some of the best sourced in quality. How do you go about it? It’s simple! – Select your style – Choose your fabric or combination of fabrics with professional advice – Book an appointment for measurements – Choose the delivery time – Make your payment. What are you waiting for? Click here to get started!</p>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Add on Accessories</h2>
                        <p className='paraPort'>No outfit is complete without a few add-on accessories. Here, at Tailorswala.com, we have a range of accessories for you to choose from, that will add a touch of sparkle, a hint of tassel or even a statement as loud as you wish to your overall outfit. Be it trendy and cool buttons or beautiful tassels, choose from our range accessories to jazz up that outfit! Each accessory is handpicked by our in-house fashion experts, keeping in mind the latest on the runways.</p>

                        <br /><br />
                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Our flexible payment</h2>
                        <p className='paraPort'>For a hassle-free and secure payment experience, we’ve the following options:</p>
                        <li className="paraPort">Cash</li>
                        <li className="paraPort">UPI Payments methods</li>
                        <li className="paraPort">Debit/Credit Cards</li>
                        <li className="paraPort">Cheques</li>

                        <br /><br />

                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Paramount Clientship</h2>
                        <p className='paraPort'>Be a part of our exclusive membership program and receive additional benefits with prime services at each step of your journey with us. <b>The benefits include: – Free pickup and delivery – Flexible measurement services – Exclusive designer consultation – Access to design and trend forecast – Choice of dressmaker – Trial and pay – Free alteration services – Flexible cancellation and return And so much more!</b>  Join the Tailorswala.com exclusive membership program and get the best of our service and product experience.</p>

                        <br /><br />

                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Shipping</h2>
                        <p className='paraPort'>At Tailorswala.com, we take utmost care in choosing our pick-up and delivery partners. Keeping the COVID safety protocols in mind, we treat each fabric and material as our own and take complete responsibility in shipping the same to you in the safest and fastest manner.</p>

                        <br /><br />

                        <h2 style={{color:"#fff", fontSize:"26px", fontWeight:"600"}}>Sustainable canister</h2>
                        <p className='paraPort'>Be a part of our exclusive membership program and receive additional benefits with prime services at each step of your journey with us. <b>The benefits include: – Free pickup and delivery – Flexible measurement services – Exclusive designer consultation – Access to design and trend forecast – Choice of dressmaker – Trial and pay – Free alteration services – Flexible cancellation and return And so much more!</b>  Join the Tailorswala.com exclusive membership program and get the best of our service and product experience.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Tailorswala;