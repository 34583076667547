import React from "react";
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";
import {Helmet} from "react-helmet";
import work from "../../img/OurWorks.webp";
import fino from "../../img/portfolio/finolence.webp";
import oui from "../../img/portfolio/oui.webp";
import malnad from "../../img/portfolio/malnad.webp";
import tailor from "../../img/portfolio/tailorwala.webp";
import printalyx from "../../img/portfolio/printalyx.webp";
import crossweave from "../../img/portfolio/crossweave.webp";
import lifewallet from "../../img/portfolio/lifewallet.webp";
import an2 from "../../img/portfolio/an2.webp";
// import dream11 from "../../img/portfolio/dream11.webp";
// import rapido from "../../img/portfolio/rapido.webp";
// import mamaearth from "../../img/portfolio/mamaearth.webp";
// import aone from "../../img/portfolio/aone.webp";
import fennac from "../../img/portfolio/fennec.webp";
// import oldschool from "../../img/portfolio/oldschool.webp";
import meritline from "../../img/portfolio/meritline.webp";
import jls from "../../img/portfolio/jls.webp";
import ikshasilks from "../../img/portfolio/ikshasilks.webp";
import unilet from "../../img/portfolio/unilet.webp";

let Work = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio | Webxinfinity IT Solution, Bengaluru</title>
        <meta name="description" content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."/>
          <meta name="format-detection" content="telephone=no" />
          <meta name="author" content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media." />
          <meta name="keywords" content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
          />
        <link rel="canonical" href="http://webxinfinity.com/" />
    </Helmet>
    <Router></Router>
      <section>
        <figure>
          <img src={work} style={{ width: "100%" }} alt="fiver" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">
                Showcases
              </div>
              <p style={{ fontSize: "16px" }}>
                We work in an integral manner and address projects in the
                following dimensions.
              </p>
            </div>

            <div className="row mt-5" id="servicesHome">
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={fino}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Finoelence</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={oui}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Oui Oui</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/ouioui">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={malnad}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Malnad Controls</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/malnad_controls" >Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={tailor}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Tailorswala</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/tailorswala">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={printalyx}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Printalytix</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/printalytix">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={unilet}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Unilet Stores</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/unilet_store">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={crossweave}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>The Crossweave</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/the_crossweave">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={lifewallet}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Life Wallet Store</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/wallet_store">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={an2}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>AN2 Logistics</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/an2_logistic">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              {/* <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={dream11}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Dream 11</h2>
                        <span style={{fontSize:"8px"}}>Fantasy,Marketing,Promotions</span><br />
                        <Link to="/dream11">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={rapido}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Rapido</h2>
                        <span style={{fontSize:"8px"}}>Development,HTML,UI-UX Design</span><br />
                        <Link to="/rapido">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={mamaearth}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Mama Earth</h2>
                        <span style={{fontSize:"8px"}}>Development,Figma Design,HTML</span><br />
                        <Link to="/mamaearth">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div> */}
              {/* <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={aone}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Aone Steel</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design</span><br />
                        <Link to="/aone_steel">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div> */}
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={fennac}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Fennec Collective</h2>
                        <span style={{fontSize:"8px"}}>Ecommerce Web development</span><br />
                        <Link to="/fennec_collective">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              {/* <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={oldschool}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Old School</h2>
                        <span style={{fontSize:"8px"}}>Application,UI-UX Design,WordPress</span><br />
                        <Link to="/old_school">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div> */}
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={meritline}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Meritline</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design,WordPress</span><br />
                        <Link to="/meritline">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={jls}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>JLS Constructions</h2>
                        <span style={{fontSize:"8px"}}>Marketing,UI-UX Design,WordPress</span><br />
                        <Link to="/jls_construction">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
              <div className="col-lg-3 my-3" id="service1">
                <div className="card">
                    <div className="imgBx">
                    <img
                        src={ikshasilks}
                        alt="portfolio"
                    />
                    </div>
                    <div className="details">
                        <h2>Iksha Silks</h2>
                        <span style={{fontSize:"8px"}}>UI-UX Design,Web Design,WordPress</span><br />
                        <Link to="/iksha_silks">Case Details <i className="fa-solid fa-right-long"></i></Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Work;
