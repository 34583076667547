import React from "react";
import { Helmet } from "react-helmet";
import custom from "../../img/custom.webp";
import Customform from "../Content/Customform";

let Custom = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom Development | Webxinfinity IT Solution, Bengaluru</title>
        <meta
          name="description"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <meta
          name="keywords"
          content="Webxinfinity - Best Web and App Development company in Bengaluru & digital marketing service provider-SEO, PPC & Social media."
        />
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={custom} alt="custom development" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="title">
              <div className="glitch">CUSTOM DEVELOPMENT</div>
            </div>

            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    Leveraging cutting edge technology to deliver Custom
                    Software Development Services. We offer a gamut of bespoke
                    software development services .Our software engineers come
                    with decades of experience in delivering custom software
                    development services for clients. Our team has demonstrated
                    commitment, agility, and efficiency while developing custom
                    software solutions for companies ranging from large
                    enterprises to start-ups, across various industry verticals.
                    We are collectively working towards a mission of
                    aggressively pursuing advanced and highest standards of
                    software development.
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <br />

            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Custom Website Development</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=7975207595&text=Hello Team, I am looking for a Custom Website Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Custom Mobile App Development</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=7975207595&text=Hello Team, I am looking for a Custom Mobile App Development Services ,Get back to us Asap.!">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 my-2">
                <div className="cardPrice">
                  <div className="text-center" style={{ marginTop: "22px" }}>
                    <h6 className="pFirst">Enterprise Software Development</h6>

                    <br />

                    <div className="d-flex">
                      <a href="https://api.whatsapp.com/send?phone=7975207595&text=Hello Team, I am looking for a Enterprise Software Development Services ,Get back to us Asap.">
                        <div className="btnWAPP">
                          <i className="fa-brands fa-whatsapp"></i>
                        </div>
                      </a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="#aa">
                        <button className="btnQuote">Get Quote</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Below */}
            <Customform />
            {/* Form End */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Custom;
