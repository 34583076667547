import React from "react";
import { Helmet } from "react-helmet";

import cancel from "../../../img/cancel.webp";

let Cancel = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cancellation Policy | Webxinfinity IT Solution, Bengaluru</title>
        <link rel="canonical" href="http://webxinfinity.com/" />
      </Helmet>

      <section>
        <figure>
          <img src={cancel} alt="Cancellation Policy" />
        </figure>
      </section>

      <section>
        <div className="project-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <div
                  style={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "400",
                    letterSpacing: "0.4px",
                  }}
                >
                  <p>
                    The NO CANCELLATION POLICY entails all
                    projects/products/services that are provided by Webxinﬁnity
                    IT Solutions. The Company follows a strict NO CANCELLATION
                    POLICY and refunds will not be provided under any
                    circumstance or discussions there on.
                  </p>
                </div>
                <br />
                <br />
                <div className="heading_aboutUs">
                  <h1
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                    }}
                    className="headIng"
                  >
                    Contacting us
                  </h1>
                  <div
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "400",
                      letterSpacing: "0.4px",
                    }}
                  >
                    <p>
                      If you would like to contact us concerning any matter
                      relating to this Cancellation Policy, you may send an
                      email to sales@webxinﬁnity.com This document was last
                      updated on April 14, 2022.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cancel;
