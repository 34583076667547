import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import logo from "../../img/logot.png";

let Footer = () => {
  return (
    <>
      <Router></Router>
      <div className="footer-spacing"></div>

      <footer className="footer my-5">
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-3">
              <Link id="qLInks" to="/terms-conditions">
                Terms & Conditions
              </Link>
            </div>
            <div className="col-lg-3">
              <Link id="qLInks" to="/privacy-policy">
                Privacy & Policy
              </Link>
            </div>
            <div className="col-lg-3">
              <Link id="qLInks" to="/refund-policy">
                Refund Policy
              </Link>
            </div>
            <div className="col-lg-3">
              <Link id="qLInks" to="/cancellation-policy">
                Cancellation Policy
              </Link>
            </div>
          </div>
        </div>

        <img src={logo} alt="logo" />
        <ul className="social-media">
          <li>
            <a
              href="https://www.facebook.com/Webxinfinityofficial/"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-facebook-f fa-2x"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.google.com/search?q=Webxinfinity"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-google fa-2x"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UC4B1LbPsduzBwm-OpkmmUkQ"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-youtube fa-2x"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/webxinfinityofficial/"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-instagram fa-2x"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/webxinfinity/"
              rel="noreferrer"
              target="_blank"
            >
              <i className="fab fa-linkedin-in fa-2x"></i>
            </a>
          </li>
        </ul>
        <div className="phone">
          Say{" "}
          <a href="mailto:laxmi@webxinfinity.com" style={{ color: "#fff" }}>
            laxmi@webxinfinity.com
          </a>{" "}
          &nbsp;&nbsp;&nbsp;<i className="fas fa-phone-volume"></i>{" "}
          <a href="tel:+917975207595" style={{ color: "#fff" }}>
            +91 79752 07595
          </a>
        </div>
        <br />
        <h1>Don't Hesitate, Contact Us!</h1>
        <Link to="/say_hello" className="btn-contact">
          <span data-hover="LET'S GET IN TOUCH">LET'S GET IN TOUCH</span>
        </Link>
        <div className="footer-bar">
          {" "}
          <span className="pull-left">
            © 2023 Webxinfinity IT Solutions | Creative Website Development |
            Digital Marketing Services | Growth Hacking Services. All Rights Reserved By <b>BLOWPIX SOLUTIONS PRIVATE LIMITED</b>
          </span>{" "}
          {" "}
          {/* <span className="pull-left" style={{marginLeft:'100px', textAlign:'right'}}>
            All Rights Reserved By <b>BLOWPIX SOLUTIONS PRIVATE LIMITED</b>
          </span>{" "} */}
          <span className="pull-right">
            Made with <i className="fas fa-heart text-danger"></i>{" "}
            <a href="https://www.webxinfinity.com">WEBXINFINITY</a>
          </span>
        </div>
       
      </footer>
    </>
  );
};

export default Footer;
